import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/core/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

constructor( private http: HttpClient) { }

sendSupportRequest(model: any) {
  return this.http.post(environment.apiEndpoint + '/support/jiraissue', model);
  }
}
