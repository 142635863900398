import { Injectable} from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProjectDecision } from 'core';

@Injectable({
  providedIn: 'root'
})
export class ExternalService {

  baseUrl = environment.apiEndpoint + '/external/';

  constructor(private http: HttpClient) {  }

  GetVendorPricing(externalId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + externalId + '/vendorpricing');
  }

  GetDecisions(externalId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + externalId + '/decisions');
  }

  UpdatePO(externalId: string, po:string): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'updatepo?externalId='+externalId+'&poNumber='+po);
  }

  addDecision(decision: ProjectDecision) {
    return this.http.post<ProjectDecision>(this.baseUrl + decision.externalId + '/decisions/add', decision);
  }

  GetVendorIDs(externalId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + externalId + '/vendorids');
  }
  GetVendorQCs(externalId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + externalId + '/vendorqcs');
  }
}
