import { Social } from './social';
import { ClientContact } from './client-contact';
import { GroupNote } from './group-note';
import { Address } from './address';

export interface Client {
    id: string;
    name: string;
    instanceId: string;
    website?: string;
    clientPreference?: string;
    img?: string;
    social?: Social;
    address?: Address;
    clientContacts?: ClientContact[];
    notes?: GroupNote[];
    allNotes?: GroupNote[];
    externalId: string;
    salesTerm: string;
    salesTermValue: number;
    internalAccountOwnerId: string;
}
