import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DropdownService } from '../../services/dropdown.service';
import { UtilsService } from '../../services/utils.service';
import { AlertifyService } from '../../services/alertify.service';
import { UnsavedchangesGuardService } from 'projects/main/src/app/_guards/unsavedchanges.guard.service';

@Component({
  selector: 'or-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent implements OnInit {

  @Input() contact: any;
  @Input() mode = 'view';
  @Input() showBilling = false;
  @Output() deleted = new EventEmitter();
  @Output() saved = new EventEmitter();
  @Output() new = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  editContact: any;
  submitted = false;
  hasUnsavedChanges = false;

  constructor(private dd: DropdownService, private alertify: AlertifyService, public utils: UtilsService, private unsavedChangesService: UnsavedchangesGuardService) { }

  ngOnInit() {
    if (this.mode === 'new') {
      this.editContact = this.contact;
    }
    else if(this.contact.lastLogin != null && this.contact.lastLogin != '0001-01-01T00:00:00')
    {
      const utcDate = new Date(this.contact.lastLogin);
      const timezoneOffsetMs = utcDate.getTimezoneOffset() * 60000;
      this.contact.lastLogin = new Date(utcDate.getTime() - timezoneOffsetMs);
    }
  }

  onFormChange() {
    this.hasUnsavedChanges = true;
  }

  enableEdit() {
    this.editContact = this.contact;
    this.mode = 'edit';
    this.unsavedChangesService.register(
      this.contact,
      () => this.hasUnsavedChanges
    );
  }

  submit(contactForm: FormGroup) {

    this.submitted = true;

    if (!contactForm.valid) {
      return;
    }

    this.hasUnsavedChanges = false;
    this.unsavedChangesService.deregister([this.contact]);
    this.contact = this.editContact;

    if (this.mode === 'new') {
      this.new.emit(this.contact);
    } else {
      this.saved.emit(this.contact);
    }
    
    this.mode = 'view';
  }

  cancel() {
    this.hasUnsavedChanges = false;
    this.unsavedChangesService.deregister([this.contact]);
    this.cancelled.emit();
    if (this.mode === 'new') {
      this.deleted.emit(this.contact);
    }
    this.mode = 'view';
  }

  delete() {
    this.hasUnsavedChanges = false;
    this.unsavedChangesService.deregister([this.contact]);
    this.deleted.emit(this.contact);
  }


}
