import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Client } from 'core';

@Injectable({
  providedIn: 'root'
})
export class AudienceService {

baseUrl = environment.apiEndpoint + '/testaudience';

constructor(private http: HttpClient) {}

  GetCategories(): Observable<any> {
    return this.http.get<any>(this.baseUrl  + '/categories');
  }
  GetQuestions(): Observable<any> {
    return this.http.get<any>(this.baseUrl  + '/questions', {
      headers : {cache : '1' }
    });
  }
  GetCountries(): Observable<any> {
    return this.http.get<any>(this.baseUrl  + '/countries', {
      headers : {cache : '1' }
    });

  }

}
