import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService, UtilsService } from 'core';
import { UnsavedchangesGuardService } from '../../../../main/src/app/_guards/unsavedchanges.guard.service';

@Component({
  selector: 'or-vendor-edit',
  templateUrl: './vendor-edit.component.html',
  styleUrls: ['./vendor-edit.component.scss'],
  providers: []
})
export class VendorEditComponent {
  @Input() partnerForm: FormGroup;
  @Input() model: any = {};
  @Input() openModalRef: any;

  @Output() saveVendor = new EventEmitter<any>();

  _values:any = [];
  submitted = false;
  allPartners: any[] = [];
  tagsLabel = {};
  partners: any[];
  filterText = '';
  showLoadMore = false;
  numToLoad = 50;
  newVendor = true;

  constructor(
    public auth: AuthService,
    public utils: UtilsService,
    private unsavedChangesService: UnsavedchangesGuardService) { }

    ngOnInit() {
      if (this.model?.id) this.newVendor = false;
    }

  uploadCallback(event) {
    this.model.img = event.file;
    this.partnerForm.controls['img'].setValue(event.file);
  }

  addAudienceType(partner, event) {
    var t = [];
    event.forEach(item => {
      t.push(item.id);
    });
    partner.audienceType = t
  }

  addRegions(partner, event) {
    var t = [];
    event.forEach(item => {
      t.push(item.id);
    });
    partner.regions = t
  }

  addTags(partner, event) {
    var t = [];
    event.forEach(item => {
      t.push(item.id);
    });
    partner.tags = t
  }

  closeModal() {
    this.unsavedChangesService.deregister([this.partnerForm]);
    this.openModalRef.hide()
  }

  savePartner(goToRedirects=false) {
    this.submitted = true;

    if (!this.partnerForm.valid) {
      return;
    }
   

    this.partnerForm.controls['audienceType'].setValue(this.model.audienceType);
    this.partnerForm.controls['regions'].setValue(this.model.regions);
    this.partnerForm.controls['tags'].setValue(this.model.tags);

    this.saveVendor.emit({form: this.partnerForm, goToRedirects: goToRedirects});
  }
}
