import { Component, Input, Output, EventEmitter, ViewChild, forwardRef, TemplateRef, OnChanges, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { ProjectDataService } from 'core';
import { ProjectService } from '../../services/project.service';
import { AlertifyService } from '../../services/alertify.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'or-dropdown-status',
  templateUrl: './dropdown-status.component.html',
  styleUrls: ['./dropdown-status.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownStatusComponent),
      multi: true
    }
  ]
})
export class DropdownStatusComponent implements ControlValueAccessor, OnChanges {

  @Input() values = ['Test', 'Soft Launch', 'Full Launch', 'Paused', 'Closed', 'Invoiced'];
  @Input() projectId: string;
  @Input() partnerId: string;
  @Input() status: string;
  @Input() autosave = true;
  @Input() skipPartnerUpdate = false;
  @Input() skipProjectUpdate = false;
  @Input() disabled: boolean = false;
  @Input() isPositionRelative: boolean = false;
  @Input() container: string = "body";
  @Input() isClient: boolean = false;
  @Input() surveyLink: string = '';

  @Output() changed = new EventEmitter();
  @Output() updatePartners = new EventEmitter<string>();
  @Output() updateProject = new EventEmitter<string>();

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;

  @ViewChild('modalNoLiveUrl')
  modalNoLiveUrl: TemplateRef<any>;
  openModalRef: any;

  _values:any = [];
  updatePartnerStatus = false;
  updateProjectStatus = false;
  savingStatus = false;
  closed = false;
  timer;
  newStatusValue;
  statusClass = 'nav-status-test';

  statusDefault = [
    { status: 'Test', class: 'nav-status-test'},
    { status: 'Soft Launch', class: 'nav-status-soft-launch'},
    { status: 'Full Launch', class: 'nav-status-full-launch'},
    { status: 'Paused', class: 'nav-status-paused'},
    { status: 'Closed', class: 'nav-status-closed'},
    { status: 'Invoiced', class: 'nav-status-invoiced'}
  ];

  @ViewChild('dropdown', {static: false}) dropdown;
  listenerFn: () => void;
  propagateChange: (_: any) => {};

  constructor(
    private modalService: BsModalService,
    private projectService: ProjectService,
    private projectDataService: ProjectDataService,
    private authService: AuthService,
    private alertify: AlertifyService) { }

  ngOnChanges() {
    this._values = [];
    if (this.values) {
      this.values.map(item => {
        const found = this.statusDefault.find(x => x.status === item);
        this._values.push({status: found?.status, class: found?.class});
      });

    } else {
      this._values = this.statusDefault;
    }
    this.toggleStatusClass();
  }

  toggleStatusClass() {
    this.statusClass = this.statusDefault.find(x => x.status === this.status)?.class;
  }

  toggleStatus(event) {
    this.dropdown.toggle(true);
    this.closed = false;

    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  applyStatus(event, newValue) {
    this.newStatusValue = newValue;
    event.stopPropagation();
    if (newValue === 'Invoiced') this.openModalRef = this.modalService.show(this.modalRefConfirm);
    else this.updateStatusApiCall();
  }

  updateStatusApiCall() {
    const rollback = this.status;
    this.status = this.newStatusValue;
    this.toggleStatusClass();

    if (this.partnerId == null) {
      if(this.autosave) {
        this.savingStatus = true;
        this.projectService.UpdateProjectStatus(this.projectId, this.status).subscribe(data => {
          this.alertify.success('Project Status updated');
          this.projectDataService.setProjectStatus(this.status);
          this.savingStatus = false;
          this.changed.emit(this.status);
          if(this.updatePartnerStatus) {
            this.updatePartnerStatusDetails();
          }

          //If project status was saved and pop up hasn't been opened already
          if (!this.surveyLink) {
            this.openPopUp()
          }
        }, error => {
          this.status = rollback;
          this.toggleStatusClass();
          if (error === 'Project is already Invoiced! Unable to update status!') this.alertify.error(error);
          if (error === 'Missing Live URL for Survey Entry Links, add to change field status') {
            this.openModalRef = this.modalService.show(this.modalNoLiveUrl, { class: 'nav-modal-style' });
          }
          else this.alertify.error('There was an issue updating this status');
        });
      } else {

        if (!this.surveyLink && ['Soft Launch', 'Full Launch'].includes(this.status)) {
          this.status = rollback;
          this.toggleStatusClass();
          this.openModalRef = this.modalService.show(this.modalNoLiveUrl, { class: 'nav-modal-style' });
          return;
        }

        this.changed.emit(this.status);
      }

      if (this.surveyLink) {
        this.openPopUp()
      }
    } else {

      if(this.autosave) {

        if (!this.surveyLink && ['Soft Launch', 'Full Launch'].includes(this.status)) {
          this.status = rollback;
          this.toggleStatusClass();
          this.openModalRef = this.modalService.show(this.modalNoLiveUrl, { class: 'nav-modal-style' });
          return;
        }

        this.projectService.UpdateProjectPartnerStatus(this.projectId, this.partnerId, this.status).subscribe(data => {
          this.alertify.success('Vendor Status updated');
          this.changed.emit(this.status);
        }, error => {
          this.status = rollback;
          this.toggleStatusClass();
          if (error === 'Project is already Invoiced! Unable to update status!') this.alertify.error(error);
          if (error === 'Missing Live URL for Survey Entry Links, add to change field status') {
            this.openModalRef = this.modalService.show(this.modalNoLiveUrl, { class: 'nav-modal-style' });
          }
          else this.alertify.error('There was an issue updating this status');
        });
      } else {

        if (!this.surveyLink && ['Soft Launch', 'Full Launch'].includes(this.status)) {
          this.status = rollback;
          this.toggleStatusClass();
          this.openModalRef = this.modalService.show(this.modalNoLiveUrl, { class: 'nav-modal-style' });
          return;
        }

        this.changed.emit(this.status);
      }

      this.openPopUp()
    }
  }

  openPopUp() {
    if (this.skipPartnerUpdate === false && this.status !== 'Invoiced' && this.status !== 'Paused' && this.status !== 'Closed') {
      this.closed = true;
      this.timer = setTimeout(() => {
        this.closed = false;
        this.dropdown.hide();
      }, 7000);
    } else {
      this.dropdown.toggle(true);
    }
  }

  applyStatusProject(event) {
    this.dropdown.hide();
    if(this.autosave) {
      if(this.savingStatus == false) {
        this.updateProjectStatusDetails();
      } else {
        this.updateProjectStatus = true;
      }
    } else {
      this.updateProject.emit(this.status);
    }
    event.stopPropagation();
  }

  updateProjectStatusDetails(){
    this.updateProjectStatus = false;
    this.projectService.UpdateProjectStatus(this.projectId, this.status).subscribe(data => {
      this.alertify.success('Project status updated');
      this.updateProject.emit(this.status);
    }, error => {
      this.alertify.error('There was an issue updating the project status');
    });
  }

  applyStatusPartners(event) {
    this.dropdown.hide();
    if(this.autosave) {
      if(this.savingStatus == false) {
        this.updatePartnerStatusDetails();
      } else {
        this.updatePartnerStatus = true;
      }
    } else {
      this.updatePartners.emit(this.status);
    }
    event.stopPropagation();
  }

  updatePartnerStatusDetails(){
    this.updatePartnerStatus = false;
    this.projectService.UpdateAllProjectPartnerStatus(this.projectId, this.status).subscribe(data => {
      this.alertify.success('All Vendors status updated');
      this.updatePartners.emit(this.status);
    }, error => {
      this.alertify.error('There was an issue updating the vendors');
    });
  }

  writeValue(value: string): void {
    this.status = value ? value : '';
  }
  registerOnChange(fn: any): void {
    this.listenerFn = fn;
  }
  registerOnTouched(fn: any): void {

  }

  confirmStatusChange() {
    this.updateStatusApiCall();
    this.openModalRef.hide();
  }

  isAClient() {
    return this.authService.isClient()
  }
}
