export interface SurveyTesterComments {
    id?: string;
    surveyTesterProjectId?: string;
    commentText?: string;
    questionId?: string;
    answerId?: string;
    status?: string;
    questionText?: string;
    answerText?: string;
    commentType?: string;
    commentImpact?: string;
}
export interface SurveyTesterSessionActivity {
    sessionId: string;
    surveyId: string;
    questionID: string;
    questionType: string;
    questionText: string;
    respondAnswerId: string;
    answers: string;
    selectedAnswerText:string
}

export interface SurveyTesterProject {
    id:string;
    platform: string;
    platformProjectId: string;
    url: string;
}

export interface SurveyTesterUser {
  id?:string;
  userId?:string;
  fullName?: string;
  role?: string;
  email?: string;
  expiresAt?: Date;
  surveyTesterProjectId?: string;
  projectId?: string;
}
