export interface Webhook {
  id: string,
  instanceId: string,
  vendorId: string,
  hookEvent: string,
  method: string,
  endpoint: string,
  urlParams: string,
  headers: string,
  body: string
}