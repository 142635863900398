import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReportConfig } from '../models/report-config';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  baseUrl = environment.apiEndpoint + '/reports/';
  awsUrl = environment.apiEndpoint + '/aws/';

  constructor(private http: HttpClient) {}

  AuditReportData(projectId: string, userId: string, filter: string, startDate: Date, endDate: Date): Observable<any> {
    let params: any = {};
    params.projectId = projectId;

    if (projectId) {
      params.projectId = projectId;
    } else {
      params.projectId = '';
    }

    if (userId) {
      params.userId = userId;
    } else {
      params.userId = '';
    }

    if (filter) {
      params.filter = filter;
    } else {
      params.filter = '';
    }
    if (startDate) {
      params.startDate = startDate.toISOString();
    } else {
      params.startDate = '';
    }
    if (endDate) {
      params.endDate = endDate.toISOString();
    } else {
      params.endDate = '';
    }
    return this.http.get<any>(this.baseUrl + 'audit', {params} );
  }

  GetLiveMonitoringData(typeReport: string, projectid: string, partnerid: string) {
    const params: any = {};

    if (typeReport != null) {
      params.typeReport = typeReport;
    } else {
      params.typeReport = 'hour';
    }
    if (projectid != null) {
      params.projectid = projectid;
    } else {
      params.projectid = '';
    }
    if (partnerid != null) {
      params.partnerid = partnerid;
    } else {
      params.partnerid = '';
    }
    return this.http.get<any>(this.baseUrl + 'live', {params} );
  }

  GetProjectEmailLog(projectId: string) {
    const params = { projectId: projectId };
    return this.http.get<any>(this.baseUrl + 'email', {params});
  }
  GenerateMetabaseEmbedToken(Id: string, instanceId: string) {
    return this.http.get(this.baseUrl + 'metabaseUrl/' + Id +'?instanceId='+ instanceId, {responseType: 'text'});
  }
  GenerateMetabaseEmbedTokenProject(Id: string, projectId: string) {
    let tzoffset = new Date().getTimezoneOffset() * -1;
    return this.http.get(this.baseUrl + 'metabaseUrl/project/'+projectId+'/'+ Id+'?tzoffset='+ tzoffset, {responseType: 'text'});
  }



  GetQuickSightUrl(): Observable<any> {
    return this.http.get(this.awsUrl + 'url', {responseType: 'text'});
  }

  GetReportConfig(reportId: string): Observable<any> {
    let params: any = {};

    if (reportId) {
      params.reportId = reportId;
    } else {
      params.reportId = '';
    }
    return this.http.get<any>(this.baseUrl + 'reportConfig', {params} );
  }
  GetSavedReportConfigs(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'reportConfigs', {} );
  }
  SaveReportConfigs(reportConfig: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'reportConfig', reportConfig);
  }
  GetReportData(reportConfig: any, limit:number = 0): Observable<any> {
    const count = 5;
    // We need to stringify these to send to server
    let data = JSON.parse(JSON.stringify(reportConfig));
    data.columns = JSON.stringify(reportConfig.columns);
    data.filters = JSON.stringify(reportConfig.filters);

    return this.http.post<any>(this.baseUrl + 'getReportData/' + limit, data);
  }
  ExportReportData(reportConfig: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'reportConfig/export', reportConfig);
  }
}
