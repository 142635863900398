import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'or-bid-value-select',
  templateUrl: './bid-value-select.component.html',
  styleUrls: ['./bid-value-select.component.scss']
})
export class BidValueSelectComponent implements OnInit {
  @Input() options: any;
  @Input() selected: any;
  @Input() exclude: boolean;
  @Input() allowExclude = true;
  @Input() placeholder = '';
  
  @Output() changed = new EventEmitter();
  @Output() open = new EventEmitter();

  selectedValues: any = [];
  currentValues: any = [];
  toggleOpen = false;
  filterText = '';
  _exclude: boolean;

  constructor() { }

  ngOnInit() {
    this.setValues();
  }
  setValues() {
    const selectedValues = {};
    const currentValues = [];
    this.selected.forEach(e => {
      var val = this.options.find(o => o.value === e);
      if(val != null) {
        selectedValues[e] = true;
        currentValues.push(val);
      }
    });

    this._exclude = this.exclude;
    this.selectedValues = selectedValues;
    this.currentValues = currentValues;
  }

  removeValue(item) {
    this.selectedValues[item.value] = false;
    this.valuesUpdated();
  }
  togglePicker() {
    this.toggleOpen = !this.toggleOpen;
    if(this.toggleOpen){
      this.open.emit();
    }
  }
  matchFilter(item) {
    return (this.filterText === '' || item.toLowerCase().indexOf(this.filterText.toLowerCase()) >= 0 ) ? true : false;
  }
  cancelChanges() {
    this.setValues();
    this.togglePicker();
  }
  saveChanges() {
    this.valuesUpdated();
    this.togglePicker();
  }
  valuesUpdated() {
    const selected = [];
    for (const s in this.selectedValues) {
     if (this.selectedValues[s] === true) {
      selected.push(s);
     }
    }
    this.selected = selected;
    this.exclude = this._exclude;

    const details = {
      exclude: this._exclude,
      values: this.selected
    };
    this.changed.emit(details);
    this.setValues();
  }
}
