import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, forwardRef, OnInit, AfterViewInit } from '@angular/core';
import { Observable, of, Observer } from 'rxjs';
import { TypeaheadMatch, TypeaheadDirective } from 'ngx-bootstrap/typeahead';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilterPipe } from '../../helpers/filter.pipe';

@Component({
  selector: 'or-type-ahead',
  templateUrl: './type-ahead.component.html',
  styleUrls: ['./type-ahead.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TypeAheadComponent),
      multi: true
    },
    FilterPipe
  ]
})
export class TypeAheadComponent  implements ControlValueAccessor, OnInit, AfterViewInit  {

  @Input() public set labelField(data: string) {
    if (data !== null && data !== undefined && data !== '') {
      this._labelField = data;
    }
  }

  @Input() public set valueField(data: string) {
    if (data !== null && data !== undefined && data !== '') {
      this._valueField = data;
    }
  }

  @Input() public set dropdownMode(data: boolean) {
    this._dropdownMode = data;
    this.typeaheadScrollable = this._dropdownMode;
    this.typeaheadOptionsLimit = this._dropdownMode ? 100 : 10;
    this.typeaheadMinLength = this._dropdownMode ? 0 : 1;
    this.isAnimated = !this._dropdownMode;
    this.showLoading = !this._dropdownMode;
  }

  @ViewChild('typeaheadDirective', { static: false }) _typeahead: TypeaheadDirective;
  public get typeahead() {
    return this._typeahead;
  }

  @Input() formControlName = '';
  @ViewChild('tagsAdd') tagsAdd: ElementRef;
  @Input() selectedValues: any[] = [];
  @Input() dataCallback;
  @Input() data;
  @Input() multi = false;
  @Input() autoFocus = false;
  @Input() cleanAfterSelect = false;
  @Input() showLoading = true;
  @Input() type = 'default'; // default | countryCode
  @Input() placeholder = 'Type to search';
  @Input() containerBody = false;
  @Input() width:string;
  @Input() class:string = '';
  @Output() valueChange = new EventEmitter();
  @Output() onBlur = new EventEmitter();

  listenerFn: () => void;
  asyncSearch: string;
  dataSource: Observable<any>;

  typeaheadLoading: boolean;
  typeaheadScrollable = false;
  typeaheadOptionsLimit = 10;
  typeaheadMinLength = 1;
  isAnimated = true;

  _dropdownMode = false;
  _labelField = '';
  _valueField = '';

  constructor(private filterPipe: FilterPipe) {   }

  ngAfterViewInit(): void {
    this.executeAfterViewInitLogic();
  }

  ngOnInit() {
    this.dataSource = new Observable((observer: Observer<any>) => {

      if (this.data) {
        this.dataCallback = () => {
          return of(this.data);
        }
      }

      return this.dataCallback(this.asyncSearch).subscribe((items: any) => {
        this.applySearchFilter(this.asyncSearch, items).subscribe((filteredItems: any[]) => {
          observer.next(filteredItems);
        });
      });
    });
  }

  executeAfterViewInitLogic() {
    setTimeout(() => {
      if (this.typeahead !== undefined) {
        if(this.autoFocus && this.tagsAdd?.nativeElement?.childNodes)
          this.tagsAdd.nativeElement.childNodes[0].focus();
      }
    });
  }

  applySearchFilter(asyncSearch: string, items: any[]): Observable<any[]> {
    return new Observable((observer: Observer<any>) => {

      if (this.type === 'countryCode' && asyncSearch.trim() === '1') {
        asyncSearch = '+' + asyncSearch;
      }

      let filtered = items;

      if (asyncSearch !== undefined) {
        if (this._labelField !== '') {
          filtered = this.filterPipe.transform(items, asyncSearch.toLowerCase(), ['name', 'fullName']);
        } else {
          filtered = items.filter(x => x !== null && x.toLowerCase().includes(asyncSearch.toLowerCase()));
        }
      }

      observer.next(filtered);
    });
  }

  callbackBlur(evt) {
    this.onBlur.emit(this.selectedValues);
  }

  onSelect(match: TypeaheadMatch): void {
    this.asyncSearch = '';
    const item = match.item;
    let alreadyAdded: any[] = [];

    if (this._valueField !== '') {
      alreadyAdded = this.selectedValues?.filter(e => e[this._valueField] === item[this._valueField]);
    } else {
      alreadyAdded = this.selectedValues?.filter(e => e === item);
    }

    if(alreadyAdded.length === 0){

      if(!this.multi) {
        this.selectedValues = [];
      }
      this.selectedValues.push(item);
    }
    else {
       // Tag exists already
      const divTags = this.tagsAdd.nativeElement.parentNode;
      const input = (divTags as HTMLElement).querySelector<HTMLInputElement>('.form-control');
      input.style.border = 'dashed 1px red';

      setTimeout(() => {
        input.style.border = 'none';
      }, 1000 * 2);
    }

    this.valueChange.emit(this.selectedValues);

    if (this.cleanAfterSelect) {
      this.selectedValues = [];
    }
  }

  removeTag(tag){
    if (this._valueField !== '') {
      this.selectedValues = this.selectedValues.filter((delItem) => delItem[this._valueField] !== tag[this._valueField]);
    } else {
      this.selectedValues = this.selectedValues.filter((delItem) => delItem !== tag);
    }

    this.valueChange.emit(this.selectedValues);

    if (this._dropdownMode) {
      setTimeout(() => {
        if (this.tagsAdd.nativeElement.childNodes) {
          this.tagsAdd.nativeElement.childNodes[0].focus();
        }
      }, 500);
    }
  }

  onTypeaheadLoading(event: boolean): void {
    this.typeaheadLoading = event;
  }

  writeValue(value: any): void {
    this.selectedValues = value ? value : [];
  }

  registerOnChange(fn: any): void {
    this.listenerFn = fn;
  }

  registerOnTouched(fn: any): void {
  }

}

