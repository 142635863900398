import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {AlertifyService, Partner, DropdownService, PartnerService, AuthService, UtilsService} from 'core';

@Component({
  selector: 'app-partner-add',
  templateUrl: './partner-add.component.html',
  styleUrls: ['./partner-add.component.scss']
})
export class PartnerAddComponent implements OnInit {
  model: Partner;
  partnerForm: FormGroup;
  states: any[];
  roles: any[];
  submitted = false;
  connections: any = [];
  partnersGlobal: any[];
  selectedPartner: Partner;
  isGlobal = false;
  isPrimaryGlobalVendor = false;

  constructor(
    private alertify: AlertifyService,
    private router: Router,
    private dd: DropdownService,
    private partner: PartnerService,
    public auth: AuthService,
    public utils: UtilsService) { }

  ngOnInit() {
    this.isGlobal = history?.state?.isGlobal ?? false;

    this.dd.getStates().subscribe( data => {
      this.states = data;
    }, error => {
       this.alertify.error('Unable to get states');
    }, () => {
    });
    this.getPartners();

    this.partnerForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      website: new FormControl(''),
      vendorType: new FormControl(''),
      img: new FormControl(''),
      instanceId: new FormControl(this.auth.getInstance()),
      connectionId: new FormControl(null),
      userDomain: new FormControl(null),
      address1: new FormControl(''),
      address2: new FormControl(''),
      city: new FormControl(''),
      country: new FormControl(''),
      state: new FormControl(''),
      zip: new FormControl(''),
      facebook: new FormControl(''),
      linkedIn: new FormControl(''),
      twitter: new FormControl(''),
      skype: new FormControl(''),
      completeRedirectURL: new FormControl(''),
      terminateRedirectURL: new FormControl(''),
      overQuotaRedirectURL: new FormControl(''),
      qcRedirectURL: new FormControl(''),
      globalPartner: new FormControl(null),
      isGlobal: new FormControl(this.isGlobal),
      isPrimaryGlobalVendor: new FormControl(this.isPrimaryGlobalVendor)
    });
  }

  partnerAdd() {
    this.submitted = true;

    if (!this.partnerForm.valid) return;

    this.partner.AddPartner(this.partnerForm.value).subscribe( data => {
      this.model = data;
    }, error => {
       this.alertify.error('Unable to add vendor');
    }, () => {
      this.alertify.success('Vendor added successfully');
      this.router.navigate(['/vendors']);
    });
  }
  getPartners() {
    this.dd.getGlobalPartners().subscribe(data => {
      this.partnersGlobal = data;
    }, error => {
       this.alertify.error('Unable to get global vendors');
    });
  }

  clonePartner(event) {
    let partnerId = event.target.value;
    this.partner.GetPartner(partnerId, true).subscribe(data => {
      this.selectedPartner = data;

      this.partnerForm = new FormGroup({
        name: new FormControl(this.selectedPartner.name + ' [clone]', [Validators.required]),
        description: new FormControl(this.selectedPartner),
        vendorType: new FormControl(this.selectedPartner.vendorType),
        website: new FormControl(this.selectedPartner.website),
        img: new FormControl(this.selectedPartner.img),
        instanceId: new FormControl(this.auth.getInstance()),
        connectionId: new FormControl(this.selectedPartner.connectionId),
        userDomain: new FormControl(this.selectedPartner.userDomain),
        address1: new FormControl(this.selectedPartner.address.address1),
        address2: new FormControl(this.selectedPartner.address.address2),
        city: new FormControl(this.selectedPartner.address.city),
        country: new FormControl(this.selectedPartner.address.country),
        state: new FormControl(this.selectedPartner.address.state),
        zip: new FormControl(this.selectedPartner.address.zip),
        facebook: new FormControl(this.selectedPartner.social.facebook),
        linkedIn: new FormControl(this.selectedPartner.social.linkedIn),
        twitter: new FormControl(this.selectedPartner.social.twitter),
        skype: new FormControl(this.selectedPartner.social.skype),
        completeRedirectURL: new FormControl(this.selectedPartner.completeRedirectURL),
        terminateRedirectURL: new FormControl(this.selectedPartner.terminateRedirectURL),
        overQuotaRedirectURL: new FormControl(this.selectedPartner.overQuotaRedirectURL),
        qcRedirectURL: new FormControl(this.selectedPartner.qcRedirectURL),
        globalPartner: new FormControl(partnerId),
        isGlobal: new FormControl(false),
        isPrimaryGlobalVendor: new FormControl(this.selectedPartner.isPrimaryGlobalVendor)
      });
    }, error => {
       this.alertify.error('Unable to get global vendor');
    });
  }
}
