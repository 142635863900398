import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MilestoneService {

  static STATUS = {
    COMPLETE: 'Complete',
    PENDING: 'Pending'
  }

  baseUrl = environment.apiEndpoint + '/milestone/';

  constructor(private http: HttpClient) { }

  GetProjectPhases(id): Observable<any> { return this.http.get<any>(this.baseUrl + id); }
  GetProjectMilestones(id: string): Observable<any[]> { return this.http.post<any>(this.baseUrl + id + '/milestonenames', null); }
  GetAllTasks(filter: any = {}): Observable<any> { return this.http.post<any>(this.baseUrl + 'task', filter); }
  UpdateMilestonesAndTasksStatus(milestoneIDs: string[], taskIDs: string[], status: string) { return this.http.put<any>(`${this.baseUrl}updatestatus/${status}`, { fieldID: '', milestoneIDs, taskIDs }); }
  UpdateMilestone(id, name: string, description: string,startDate:Date,endDate:Date) { return this.http.put<any>(`${this.baseUrl}${id}/name`, { sVal: name, sVal2: description,dVal:startDate,dVal2:endDate }); }
  UpdateMilestoneDescription(id, description: string) { return this.http.put<any>(`${this.baseUrl}${id}/description`, { sVal: description }); }
  UpdateMilestoneStatus(id, status: string) { return this.http.put<any>(`${this.baseUrl}${id}/status`, { fieldID: '', sVal: status }); }
  UpdateMilestoneStartDate(id, date, adjustOtherMilestones: boolean) { return this.http.put<any>(`${this.baseUrl}${id}/startdate`, { dVal: date, bVal: adjustOtherMilestones }); }
  UpdateMilestoneDueDate(id, date, adjustOtherMilestones: boolean) { return this.http.put<any>(`${this.baseUrl}${id}/duedate`, { dVal: date, bVal: adjustOtherMilestones }); }
  SaveMilestone(id, dueDate, name: string, description: string, startDate) { return this.http.put<any>(`${this.baseUrl}${id}`, { dVal: dueDate, sVal: name, sVal2: description, dVal2: startDate }); }
  DeleteMilestone(id) { return this.http.delete<any>(`${this.baseUrl}${id}`, {}); }
  UpdateTaskStatus(id, status: string) { return this.http.put<any>(`${this.baseUrl}task/${id}/status`, { fieldID: '', sVal: status }); }
  UpdateTaskUser(id, userId) { return this.http.put<any>(`${this.baseUrl}task/${id}/assign`, { sVal: userId }); }
  DeleteProjectTask(id) { return this.http.delete<any>(`${this.baseUrl}task/${id}`, {}); }
  UpdateTask(id, name: string, description: string) { return this.http.put<any>(`${this.baseUrl}task/${id}/name`, { sVal: name, sVal2: description }); }
  CreateProjectTask(id, assignedTo, name: string, description: string,startDate,endDate) { return this.http.post<any>(`${this.baseUrl}${id}/task`, { sVal: name, FieldID: assignedTo, sVal2: description,dVal:startDate,dVal2:endDate }); }
  importTasks(projectId: string, awsfileName: string, actualFileName: string, url: string): Observable<any> { return this.http.post(this.baseUrl + projectId + '/task/importTask/' + awsfileName + '/' + actualFileName + '/' + encodeURIComponent(url), null); }
  AssignMileStoneTemplateTask(id, projectId) { return this.http.put<any>(`${this.baseUrl}${id}/milestoneTemplateId`, { sVal: projectId }); }
  BulkReAssignUser(id: string, taskIDs) { return this.http.put<any>(`${this.baseUrl}task/${id}/reassign`, { sVal2: taskIDs }); }
  BulkReAssignDate(id: string, milestoneIDs,date:Date,type,shiftdays:string) { return this.http.put<any>(`${this.baseUrl}milestone/${id}/bulkassign`, { sVal2: milestoneIDs,dVal:date,nval:type,sval:shiftdays }); }
  MarkAsCompleteMilestone(id: string, milestoneIds,status:string) { return this.http.put<any>(`${this.baseUrl}milestone/${id}/completeDate`, { sVal2: milestoneIds,sVal: status}); }
  MarkAsCompleteTask(id: string,taskId,status:string) { return this.http.put<any>(`${this.baseUrl}task/${id}/completeDate`, { sVal2: taskId,sVal: status}); }
  UpdateTaskStartDate(id, date) { return this.http.put<any>(`${this.baseUrl}${id}/taskstartdate`, { dVal: date}); }
  UpdateTaskDueDate(id, date) { return this.http.put<any>(`${this.baseUrl}${id}/taskduedate`, { dVal: date}); }
}
