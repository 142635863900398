import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CalendarOptions, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

@Component({
  selector: 'or-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [DatePipe]
})
export class CalendarComponent implements OnInit {

  calendarOptions: CalendarOptions;
  showWeekends = true;
  isHidden = false;
  private _data: any;

  @Input() dateField: string;
  @Input() startDateField: string;
  @Input() endDateField: string;
  @Input() titleField: string;
  @Input() subtitleField: string;
  @Input() profileField: string;
  @Input() footerField: string;
  @Input() idField: string;
  @Input() eventColorField: string;

  @Input() public set data(value: any) {
    this._data = value;
    if (this._data)
      this.handleData(this._data);
  }
  get data(): any{
    return this._data;
  }
  // @ViewChild('calendar') calendar: FullCalendarComponent;
  @Output() selected = new EventEmitter();
  @Output() selectedDoubleClick = new EventEmitter();
  @Output() selectedReoccurrence = new EventEmitter();

  listenerFn: () => void;

  calendarPlugins = [dayGridPlugin];
  calendarEvents: EventInput[];

  constructor(
    public datepipe: DatePipe) { }

  ngOnInit(): void {
  }

  modifyTitle(eventIndex, newTitle) {
    this.calendarEvents[eventIndex].title = newTitle;
  }

  handleData(data) {
    this.calendarEvents = [{ title: '', start: '', end: '', url: '', profile: '', footer: '', backgroundColor: '', isOneDay: false}];
    if (data.length > 0) {
      data.forEach(element => {
        if (element.taskName != null && (element.startDate != null && element.dueDate != null)) {

          const eventStartDate = this.datepipe.transform(element.startDate , 'yyyy-MM-dd');

          let dueDate: Date | string = new Date(element.dueDate);

          dueDate.setDate(dueDate.getDate() + 1);
          dueDate = dueDate.toISOString().slice(0, 19);
          const eventEndDate = this.datepipe.transform(dueDate, 'yyyy-MM-dd');

          let assigneeLabel = '';
          let assigneeTooltip = '';
          let assignees = element.assignedToUsers.map(x => x.assignedToName);

          if (element.assignedToUsers?.length === 1) {
            assigneeLabel = this.getInitials(assignees[0]);
            assigneeTooltip = assignees[0];
          } else {
            assigneeLabel = assignees?.length > 1 ? `${assignees.length}` : '';
            assigneeTooltip = assignees?.length > 1 ? `${assignees.slice(0, -1).join('<br>')}<br>${assignees[assignees.length - 1]}` : '';
          }

          let styles = this.getStyles(element);

          this.calendarEvents.push({
            start: eventStartDate,
            end: eventEndDate,
            title:  (element["projectCode"] == null) ? '': `${element["projectCode"]} - ${element["projectName"]}`,
            subtitle: `${element.taskName}`,
            profile: `${assigneeLabel}`,
            profileTooltip: `${assigneeTooltip}`,
            reoccurrenceTooltip: `${element?.reoccurrenceSettings?.summary ?? ''}`,
            eventid: element["projectId"],
            taskid: element?.id,
            stageColor: element[this.eventColorField],
            url: '',
            color: styles.card,
            textColor: styles.text,
            isOneDay: this.datepipe.transform(new Date(element.startDate) , 'yyyy-MM-dd') === this.datepipe.transform(new Date(element.dueDate) , 'yyyy-MM-dd')
            });

          this.calendarOptions = {
            plugins: this.calendarPlugins,
            timeZone: 'UTC',
            initialView: 'dayGridMonth',
            headerToolbar: {
              left: '',
              center: 'prev title next',
              right: 'dayGridMonth,dayGridWeek,dayGridDay'
            },
            editable: true,
            events: this.calendarEvents,
            eventInteractive: false,
            buttonText: {
              dayGridMonth: 'Month',
              dayGridWeek: "Week",
              dayGridDay: "Day",
            },
            eventDidMount: (info) => {
              info.el.addEventListener('dblclick', () => { this.eventDoubleClick(info.event) });
              this.generateTooltip(info);
            },
            eventClick: (evt) => { this.eventClick(evt.event) },
            eventContent: (info) => { return this.eventRender(info) },
          }
        }
      });
    }

    if( this.calendarEvents.length == 1 && this.calendarEvents[0].title == ''){
      //Draw empty calendar
      this.calendarOptions = {
        plugins: this.calendarPlugins,
        timeZone: 'UTC',
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: '',
          center: 'prev title next',
          right: 'dayGridMonth,dayGridWeek,dayGridDay'
        },
        editable: true,
        events: this.calendarEvents,
        buttonText: {
          dayGridMonth: 'Month',
          dayGridWeek: "Week",
          dayGridDay: "Day",
        },
        eventDidMount: (info) => {
          info.el.addEventListener('dblclick', () => { this.eventDoubleClick(info.event) });
          this.generateTooltip(info);
        },
        eventClick: (evt) => { this.eventClick(evt.event) },
        eventContent: (info) => { return this.eventRender(info) },
      }
    }
  }

  eventRender(info) {
    const template = `
    <div class="calendar-event ${info.event.extendedProps.isOneDay ? 'one-day' : ''} d-flex">
      <div class="info pr-3 ${info.event.extendedProps.isOneDay ? 'd-block' : 'd-flex'}">
        <span class="title d-block mb-0" style="${info.event.extendedProps.profile !== '' || info.event.extendedProps.reoccurrenceTooltip !== "" ? 'width: calc(100% - ' + (info.event.extendedProps.reoccurrenceTooltip !== "" && info.event.extendedProps.profile !== "" ? '35px)' : '13px)') : ''} ">${info.event.title}
          <span class="subtitle ${info.event.extendedProps.isOneDay ? 'd-block' : 'd-inline ml-2'} mb-0">${info.event.extendedProps.subtitle}</span>
        </span>
      </div>
      ${info.event.extendedProps.reoccurrenceTooltip !== "" ? `
        <div class="reoccurrence ${info.event.extendedProps.profile !== '' ? 'profileExists' : ''}">
          <i class="fak fa-repeat-rounded nav-font18"></i>
        </div>` : ""}
      ${info.event.extendedProps.profile !== "" ? `
      <div class="image-container circle medium">
        <div class="initials">
          <span>${info.event.extendedProps.profile}</span>
        </div>
      </div>` : ""}
    </div>`;

    let eventHtml = document.createElement('div')
    eventHtml.innerHTML = template;
    const reoccurrenceBtn = eventHtml.querySelector('.fa-repeat-rounded');
    if (reoccurrenceBtn) {
      reoccurrenceBtn.addEventListener('click', (e) => {
        this.eventReoccurrenceClick(info.event);
      });
    }

    let arrayOfDomNodes = [eventHtml]
    return { domNodes: arrayOfDomNodes }
  }

  addOpacityToHex(color, opacity) {
    var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  getInitials(text) {
    if (text == null || text == undefined || text == "") return ""
    return text.split(' ').map(x => x.charAt(0)).join('').substr(0, 2).toUpperCase();
  }

  eventClick(event) {
    this.selected.emit(event.extendedProps);
  }

  eventDoubleClick(event) {
    this.selectedDoubleClick.emit(event.extendedProps);
  }

  eventReoccurrenceClick(event) {
    this.selectedReoccurrence.emit(event.extendedProps);
  }

  getStyles(stageItem) {
    let cardColor = stageItem.stageColor;
    let textColor = "#FFF";
    if(cardColor == "#ED7892" || cardColor == "#F9D277" || cardColor == "#54B390"){
      textColor = "#333"
    }
    return {card: cardColor, text: textColor}
  }

  generateTooltip(info) {
    const assigneeDiv = info.el.querySelector('.image-container');
    var reoccurrenceDiv = info.el.querySelector('.reoccurrence');
    
    if (assigneeDiv) {
      const tooltip = document.createElement('div');
      var text = info.event.extendedProps.profileTooltip;
      tooltip.className = 'in show tooltip bs-tooltip-top tooltip-top top';
      tooltip.innerHTML = `
        <div class="tooltip-arrow arrow" style="left:30px"></div>
        <div class="tooltip-inner">${text}</div>`;
      const length = text.length;

      assigneeDiv.addEventListener('mouseenter', () => {
        document.body.appendChild(tooltip);
        const rect = assigneeDiv.getBoundingClientRect();
        tooltip.style.left = `${rect.left + window.pageXOffset -  45}px`;
        tooltip.style.top = `${rect.bottom + window.pageYOffset - (length<25 ? 70 : (length<35 ? 100 : 150))}px`;
      });
      assigneeDiv.addEventListener('mouseleave', () => {
        if (tooltip.parentNode) {
          tooltip.parentNode.removeChild(tooltip);
        }
      });
    }
    if (reoccurrenceDiv) {
      const tooltip = document.createElement('div');
      var text = info.event.extendedProps.reoccurrenceTooltip;
      tooltip.className = 'in show tooltip bs-tooltip-bottom tooltip-bottom bottom reoccurrence-tooltip';
      tooltip.innerHTML = `
        <div class="tooltip-arrow arrow" style="left:30px"></div>
        <div class="tooltip-inner">${text}</div>`;

      reoccurrenceDiv.addEventListener('mouseenter', () => {
        document.body.appendChild(tooltip);
        const rect = reoccurrenceDiv.getBoundingClientRect();
        const tooltipRect = tooltip.getBoundingClientRect();
        tooltip.style.left = `${rect.left + window.pageXOffset - (tooltipRect.width - 15)}px`;
        tooltip.style.top = `${rect.bottom + window.pageYOffset}px`;
      });
      reoccurrenceDiv.addEventListener('mouseleave', () => {
        if (tooltip.parentNode) {
          tooltip.parentNode.removeChild(tooltip);
        }
      });
    }
  }
}
