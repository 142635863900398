import { Component, OnInit, forwardRef, ViewChild, ElementRef, Input, Output, EventEmitter, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'or-input-multiple',
  templateUrl: './input-multiple.component.html',
  styleUrls: ['./input-multiple.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputMultipleComponent),
      multi: true
    }
  ]
})
export class InputMultipleComponent implements AfterViewInit, OnInit, OnDestroy, ControlValueAccessor  {

  @ViewChild('tagsAdd') tagsAdd: ElementRef;

  @Input() selectedValuesSep = ',';
  @Input() placeholder = '';
  @Input() isDisabled = false;
  @Input() class:string = '';
  @Input() public set selectedValuesString(data) {

    if(data == this._selectedValuesString) return;

    this._selectedValuesString = data;
    
    if(this.tagsAdd)
    {
      this.selectedValues = [];
      const elements = this.tagsAdd.nativeElement.parentNode.querySelectorAll('.tag');

      elements.forEach(element => {
        element.remove();
      });
      this.loadTags();
    }
      
  }

  _selectedValuesString = '';
  @Output() selectedValuesStringChange = new EventEmitter<any>();
  @Output() changed = new EventEmitter<any>();

  selectedValues: any[] = [];

  listenerFn: () => void;
  propagateChange: (_: any) => {};

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.loadTags();
  }

  loadTags() {
    if (this._selectedValuesString !== undefined && this._selectedValuesString.length > 0) {
      
      const tags = this._selectedValuesString.split(this.selectedValuesSep);
      for (const tag of tags) {
        this.addTag(tag, true);
      }
    }
  }

  onEnter(event: KeyboardEvent): void {
    if (!this.isDisabled) {
      event.preventDefault();
      const input = (event.target as HTMLInputElement);
      this.addTag(input.value);
      input.value = '';
      this.changed.emit(this.selectedValues);
    }
  }
  onKeyup(event: KeyboardEvent): void {
    if (!this.isDisabled) {
      const input = (event.target as HTMLInputElement);
      this._selectedValuesString = this.selectedValues.join(this.selectedValuesSep);

      if(input.value !== '')
      {
        this._selectedValuesString = (this._selectedValuesString === '')? input.value : this._selectedValuesString +','  +input.value;
      }
      this.selectedValuesStringChange.emit(this._selectedValuesString);
      if (this.propagateChange) {
        this.propagateChange(this._selectedValuesString);
      }

    }
  }


  addTag(item, initialValue= false) {

    item = (typeof (item) === 'string' ? item.trim() : '');

    const divTags = this.tagsAdd.nativeElement.parentNode;

    // Tag exists already
    if (item === '' || this.selectedValues.indexOf(item) > -1) {

      const input = (divTags as HTMLElement).querySelector<HTMLInputElement>('.form-control');
      input.style.border = 'dashed 1px red';

      setTimeout(() => {
        input.style.border = 'none';
      }, 1000 * 2);

      return;
    }

    const btnRemove: HTMLSpanElement = this.renderer.createElement('button');
    btnRemove.className = 'tag-remove';

    // add event listener for remove button (x) inside the new tag added
    this.listenerFn = this.renderer.listen(btnRemove, 'click', (evt: MouseEvent) => {

      const tagToDelete = (evt.target as HTMLElement).parentNode;
      const parent = (evt.target as HTMLElement).parentNode.parentNode;
      const tagValue = (tagToDelete as HTMLElement).getAttribute('data-value');
      this.renderer.removeChild(parent, tagToDelete);

      // removes the deleted item from Array
      this.selectedValues = this.selectedValues.filter((value) => value !== tagValue);
      this._selectedValuesString = this.selectedValues.join(this.selectedValuesSep);
      this.selectedValuesStringChange.emit(this._selectedValuesString);

      this.changed.emit(this.selectedValues);
      
      if (this.propagateChange) {
        this.propagateChange(this._selectedValuesString);
      }

    });

    const tagText: HTMLSpanElement = this.renderer.createElement('span');
    tagText.className = 'tag-text mr-2';
    tagText.innerHTML = item;

    const tag: HTMLSpanElement = this.renderer.createElement('span');
    tag.className = 'tag';
    tag.setAttribute('data-value', item);
    tag.appendChild(tagText);
    tag.appendChild(btnRemove);

    this.selectedValues.push(item);
    //this._selectedValuesString = this.selectedValues.join(this.selectedValuesSep);

    const val = this.selectedValues.join(this.selectedValuesSep);

    //We don't emit if this was a value that was passed in on intialization
    if(!initialValue)
      this.selectedValuesStringChange.emit(val);

    if (this.propagateChange) {
      this.propagateChange(val);
    }
    this.renderer.insertBefore(divTags, tag, this.tagsAdd.nativeElement );
  }

  ngOnDestroy() {
    if (this.listenerFn) {
      this.listenerFn();
    }
  }

  writeValue(value: string): void {
    if (value !== undefined && value !== null) {
      this._selectedValuesString = value;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(disabled: boolean): void {
    this.isDisabled = disabled;
  }

}
