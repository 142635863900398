@if (this.auth.getUser()) {
  <nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top shadow">
    <img alt="OpinionRoute" [routerLink]="['/']" class="img-logo nav-link" src="/assets/imgs/logo.png" />
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="vl"></div>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
        <li class="nav-item ml-auto d-flex align-items-center">
          @if (this.auth.getUser().superAdmin) {
            <select class="form-control form-control-sm inline mr-3" [(ngModel)]="instanceId" (change)="changeInstance($event)" id="instanceId">
              <option></option>
              @for (instance of instances; track instance) {
                <option [value]="instance.value">{{instance.name}}</option>
              }
            </select>
          }
        </li>
        <li class="nav-item dropdown mr-2" dropdown>
          <a class="nav-link" href="#" (click)="false" aria-controls="basic-link-dropdown" dropdownToggle id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <or-profile-image [name]="this.auth.getUser().name" [url]="this.auth.getUser().image" size="medium"></or-profile-image>
          </a>
          <div class="dropdown-menu dropdown-menu-right mt-2" *dropdownMenu aria-labelledby="navbarDropdown">
            <a class="dropdown-item" [routerLink]="['/changepassword']" (click)="false"><i class="fas fa-key or-secondary"></i> Change Password</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="logout()"><i class="fas fa-sign-out-alt or-secondary"></i> Log Out</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
}





