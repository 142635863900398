import { Injectable } from '@angular/core';
import * as alertify from 'alertifyjs';

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

  constructor() { }

  confirm(title: string, message: string, okCallbacky: () => any) {
    alertify.alert(title, message, (e: any) => {
      if (e) {
        okCallbacky();
      } else {}
    });
  }

  confirmYesNo(title: string, message: string, okCallback: () => any, cancelCallback: () => any) {
    alertify.confirm(
      title,
      message,
      okCallback,
      cancelCallback
    ).set({
      labels: {ok: 'Yes', cancel: 'No'},
      transition: 'fade' // THIS IS OVERRIDDEN BY CSS, see core module's styles.scss
    });
  }

  success(message: string, position = 'bottom-center') {
    alertify.dismissAll();
    alertify.set('notifier', 'position', position);
    //alertify.set('notifier','delay', 3600); (only for testing purpose)
    alertify.success().setContent(`<i class='fak fa-message-success fa-xl'></i><span class='ml-3'>${message}</span>`);
  }

  error(message: string, position = 'bottom-center') {
    alertify.dismissAll();
    alertify.set('notifier', 'position', position);
    alertify.error().setContent(`<i class='fak fa-message-error fa-xl'></i><span class='ml-3'>${message}</span>`);
  }

  warning(message: string, position = 'bottom-center') {
    alertify.dismissAll();
    alertify.set('notifier', 'position', position);
    alertify.warning().setContent(`<i class='fak fa-message-warning fa-xl'></i><span class='ml-3'>${message}</span>`);
  }

  message(message: string, position = 'bottom-center') {
    alertify.dismissAll();
    alertify.set('notifier', 'position', position);
    alertify.message().setContent(`<i class='fak fa-message-info fa-xl'></i><span class='ml-3'>${message}</span>`);
  }

  alert( dialogName: string, myMessage: string, position = 'bottom-center') {
    alertify.set('notifier', 'position', position);
    alertify.alert(dialogName, myMessage).set({transitionOff: true});
  }

}
