import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'or-sortby',
  templateUrl: './sortby.component.html',
  styleUrls: ['./sortby.component.scss']
})
export class SortByComponent implements OnInit {

  @Input() values: any;
  @Output() opened = new EventEmitter();
  @Output() sorting = new EventEmitter();

  sortingText = '';
  isOpen = false;

  constructor() { }

  ngOnInit() {
  }

  toggleSorting(event, dropdown) {
    dropdown.toggle(true);
    this.isOpen = !this.isOpen;
    if(this.isOpen){
      this.opened.emit();
    }
    event.stopPropagation();
  }

  applySorting(value){
    this.sorting.emit(value);

  }

}
