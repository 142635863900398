<div [routerLink]="[page.url]" class="page-title-container">
  <!-- <i class="{{page.icon}} grey4 fa-lg"></i> -->
  @if (page.siteLabel !== page.label) {
    <span class="nav-font14 grey5 cursor-pointer font-weight-normal cursor-pointer">{{page.siteLabel}}
      <span class="marginLeft2 mr-2">/</span>
    </span>
  }
  <span class="nav-font14 cursor-pointer" [ngClass]="subPageOpen && (page.siteLabel == page.label) ? 'font-weight-normal grey5' : 'font-weight-bold nav-secondary'">{{page.label}}
    @if (subPageOpen) {
      <span class="marginLeft2 mr-2">/</span>
    }
  </span>
</div>
