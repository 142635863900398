export interface ProjectQuota {
  id: string;
  limitType?: string;
  limitMode: string;
  limitCheck: string;
  distributeBy: string;
  distributionFlexibility: number;
  enableVendorLimits: boolean;
  quotaGroups: Array<QuotaGroup>;
}

export interface QuotaGroup {
  id: string;
  isDeleted: boolean;
  name: string;
  order: number;
  limitN?: number;
  questionId: string;
  questionIdArray?: Array<string>;
  nameArray?: Array<string>;
  quotas: Array<Quota>;
  completes: number;
  starts: number;
  nonValueStarts?: number;
  nonValueCompletes?: number;
  deletedOptions: Array<DropdownQuota>;
  formMode?: string;
  preferNotToAnswerAdded?: boolean;
  hasZeroQuota?: boolean;
  loadingUndoNest?: boolean;
}

export interface Quota {
  order: number;
  groupId?: string;
  id: string;
  isDeleted: boolean;
  limit: number;
  starts: number;
  completes: number;
  limitN: number;
  limitPercent: number;
  targetGroup: QuotaTargetGroup;
  vendorLimits: Array<QuotaVendorLimit>;
  edit?: boolean;
  valueEdit?: boolean;
  newQuota?: boolean;
  hideQuota?: boolean;
  isPNTA?: boolean;
}

export interface DropdownQuota {
  name: string,
  type: string,
  id: string
}

export interface QuotaTargetGroup {
  id: string;
  minAge: number;
  maxAge: number;
  minHHI: number;
  maxHHI: number;
  gender: string;
  hispanic: string;
  ethnicity: string;
  region: string;
}

export interface QuotaVendorLimit {
  id: string;
  vendorId: string;
  vendorName: string;
  limit: number;
  limitN: number;
  limitPercent: number;
  completes: number;
  starts: number;
  edit?: boolean;
}
