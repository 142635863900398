<div (click)="toggleFilter($event, ddFilters)">
  <i class="fak fa-filter fa-xs grey4 cursor-pointer" [class.filter-mode]="isOpen"></i>
</div>

<div dropdown #ddFilters="bs-dropdown" container="body" class="dropdown">
  <div class="dropdown-menu ml-2" *dropdownMenu role="menu"
    [ngClass]="{'dropdown-menu-left': position === 'left', 'dropdown-menu-right': position === 'right'}"
    [style]="position +': -'+ positionDistance + 'px !important'"
    aria-labelledby="button-triggers-manual" (mouseleave)="toggleFilter($event, ddFilters)">
    <div class="col mb-2 grey5 nav-font14 d-flex align-items-center">
      <span class="font-weight-bold nav-font14 grey5">Filter by</span>
      <i class="fas fa-close fa-md grey4 ml-auto" (click)="toggleFilter($event, ddFilters)"></i>
    </div>
    @if (type != 'simple') {
      <div class="row mt-1 mb-3 px-3">
        <div class="col nav-font12 nav-accent-blue">
          <span class="cursor-pointer" (click)="clearAllFilters(); applyFilter()">Reset Filters</span>
        </div>
      </div>
    }
    <hr class="mx-1 my-0 nav-border-primary">
    <div class="p-2">
      @if (values && values.length > 0) {
        @for (value of values; track value; let i = $index) {
          @if (i === 0 || values[i].group !== values[i - 1].group) {
            @if (values[i]?.group) {
              <span class="nav-font14 grey4 ml-2 pl-1 my-2 d-block">{{ value.group }}</span>
            }
          }
          <div class="checkbox px-3">
            <input type="checkbox" [name]="'valCheckbox_' + value.value" [id]="'valCheckbox_' + value.value" [(ngModel)]="value.selected" (change)="applyFilter()">
            <label class="font-weight-normal nav-font14 grey5" [for]="'valCheckbox_' + value.value">
              {{ value.label }}
            </label>
          </div>
        }
      }
    </div>

  </div>
</div>
