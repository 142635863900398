<div>
  @if (_headers?.length > 0) {
    <div>
      <!-- Header Row -->
      @if (!_showCount) {
        <div>
          <div class="row">
            @for (header of _headers; track header) {
              <div [ngClass]="_headers?.length === 2 ? 'col-6' : 'col-4'">
                <span>{{header}}</span>
              </div>
            }
          </div>
        </div>
      }
      @if (_showCount) {
        <div>
          @if (_headers?.length === 2) {
            <div class="row">
              <div class="col-6">
                <span>{{_headers[0]}} ({{_unselectedFields.length}})</span>
              </div>
              <div class="col-6">
                <span>{{_headers[1]}} ({{_selectedFields.length}})</span>
              </div>
            </div>
          }
          @if (_headers?.length === 3) {
            <div class="row">
              <div class="col-4">
                <span>{{_headers[0]}} ({{_distinctParents.length}})</span>
              </div>
              <div class="col-4">
                <span>{{_headers[1]}} ({{_unselectedFields.length}})</span>
              </div>
              <div class="col-4">
                <span>{{_headers[2]}} ({{_selectedFields.length}})</span>
              </div>
            </div>
          }
        </div>
      }
      <hr/>
    </div>
  }
  <!-- Listbox Row -->
  <div class="row">
    @if (_headers?.length > 2) {
      <div class="col-4">
        @for (parent of _distinctParents; track parent) {
          <div (click)="handleParent(parent)">
            <i [attr.class]="_selectedParents.includes(parent) ? 'far fa-check-square' : 'far fa-square'"></i><span> {{parent}}</span>
          </div>
        }
      </div>
    }
    <div [ngClass]="_headers?.length === 2 ? 'col-6' : 'col-4'">
      @for (field of _unselectedFields; track field) {
        <div (click)="addRemoveField(field.id, 'add')">
          <i class="fa fa-plus"></i><span> {{field.label}}</span>
        </div>
      }
    </div>
    <div [ngClass]="_headers?.length === 2 ? 'col-6' : 'col-4'">
      @for (field of _selectedFields; track field) {
        <div (click)="addRemoveField(field.id, 'remove')">
          <i class="fa fa-minus"></i><span> {{field.label}}</span>
        </div>
      }
    </div>
  </div>
</div>
