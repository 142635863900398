
export interface RuleHeader {
    id: string;
    name: string;
    ruleType: string;
    createdBy: string;
    createdByName: string;
    projectId: string;
    multi: string;
    scopeType: string;
    emailTemplate?: string;
    scopeId?: string;
    sortKey: number;
    isExpired: boolean;
    isEdited: boolean;
    rules?: Rules[];
    ruleActions?: RuleAction[];
}

export interface Rules {
  id?: string;
  ruleHeaderId?: string;
  metricType?: string;
  value?: number;
  operator?: string;
  sortKey?: number;
}

export interface RuleAction {
  id?: string;
  ruleHeaderId?: string;
  action?: string;
  alertMethod?: string;
  alertRecipientType?: string;
  scheduledTime?: Date;
  processedTime?: Date;
  triggered?: boolean;
  sortKey?: number;
}

