import { Component, ViewChild, Input, Output, EventEmitter, forwardRef, OnInit } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { TypeaheadMatch, TypeaheadDirective } from 'ngx-bootstrap/typeahead';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'or-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GlobalSearchComponent),
      multi: true
    }
  ]
})
export class GlobalSearchComponent implements ControlValueAccessor, OnInit {
  @Input() formControlName = '';
  @ViewChild('typeaheadDirective') typeaheadDirective: TypeaheadDirective;
  @Input() dataCallback;
  @Input() labelField = 'label';
  @Output() valueChange = new EventEmitter();

  listenerFn: () => void;
  asyncSearch: string;
  dataSource: Observable<any>;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;

  constructor() { }

  ngOnInit() {
    this.dataSource = new Observable((observer: Observer<any>) => {
      return this.dataCallback(this.asyncSearch).subscribe((items: any) => {
        observer.next(items);
      });
    });
  }

  public onFocus(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  onSelect(match: TypeaheadMatch): void {
    this.asyncSearch = '';
    this.valueChange.emit(match.item);
  }

  onTypeaheadNoResults(event: boolean): void {
    this.typeaheadNoResults = event;
  }

  onTypeaheadLoading(event: boolean): void {
    this.typeaheadLoading = event;
  }

  writeValue(value: any): void {
  }

  registerOnChange(fn: any): void {
    this.listenerFn = fn;
  }

  registerOnTouched(fn: any): void {
  }

}
