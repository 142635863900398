import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'or-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input() values: any;
  @Input() position: any = 'right';
  @Input() positionDistance: number = 163;
  @Input() type: any = 'simple';
  @Output() opened = new EventEmitter();
  @Output() filter = new EventEmitter();

  filterText = '';
  isOpen = false;

  constructor() { }

  ngOnInit() {
  }

  toggleFilter(event, dropdown) {
    dropdown.toggle(true);
    this.isOpen = !this.isOpen;
    if(this.isOpen){
      this.opened.emit();
    }
    event.stopPropagation();
  }

  applyFilter(){
    this.filter.emit(this.values.filter(e => e.selected));
  }

  clearAllFilters() {
    this.values.forEach(e => {
      e.selected = false;
    });
  }

}
