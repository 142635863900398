import { Component, Input, OnInit } from '@angular/core';
import { AlertifyService, AuthService, ProjectService, UtilsService } from 'core';
import { DndDropEvent } from 'ngx-drag-drop';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-project-types-homepage',
  templateUrl: './project-types-homepage.component.html',
  styleUrls: ['./project-types-homepage.component.scss']
})
export class ProjectTypesHomepageComponent implements OnInit {

  @Input('stages') stages = null;

  constructor(
    public auth: AuthService,
    public utils: UtilsService,
    public projectService: ProjectService,
    public alertify: AlertifyService) { }

  ngOnInit(): void {
    this.stages = this.stages.sort((x, y) => x.sortOrder - y.sortOrder);
  }

  addCard(stage) {
    if (stage.homepageCards === undefined || stage.homepageCards == null) stage.homepageCards = [];
    stage.homepageCards.push({
      id: uuidv4(),
      name: '',
      type: '',
      sortOrder: stage.homepageCards.length + 1,
    });
  }

  removeCard(stage, index) {
    stage.homepageCards.splice(index, 1);
  }

  onDrop(event: DndDropEvent, list?: any[]) {

    if (list && event.dropEffect === 'move') {
      let index = event.index;

      if (typeof index === 'undefined') {
        index = list.length;
      }

      let oldIndex = -1;

      if (event?.type === 'card')
        oldIndex = list.findIndex(item => item.id?.toLowerCase() === event.data.id?.toLowerCase());

      if (oldIndex > -1) {
        list.splice(oldIndex, 1);
        list.splice(index, 0, event.data);

        //change sortOrder
        if (event?.type === 'card') {
          list = list.map((item, index) => {
            item.sortOrder = index + 1;
            return item;
          });
        }
      }
    }
  }

}

