
@if (stage) {
  <div class="stage" [style]="'border: 1px solid '+ color + '; border-radius:0.65rem; background-color: ' + color + '20'">
    <div class="px-2 ">
      <div class="circle px-2 mr-2">
        <div class="dot" [ngStyle]="{'background-color': _percentage > 0 ? color : 'var(--nav-grey2)'}"></div>
        <div class="dot" [ngStyle]="{'background-color': _percentage >= 20 ? color : 'var(--nav-grey2)'}"></div>
        <div class="dot" [ngStyle]="{'background-color': _percentage >= 30 ? color : 'var(--nav-grey2)'}"></div>
        <div class="dot" [ngStyle]="{'background-color': _percentage >= 40 ? color : 'var(--nav-grey2)'}"></div>
        <div class="dot" [ngStyle]="{'background-color': _percentage >= 50 ? color : 'var(--nav-grey2)'}"></div>
        <div class="dot" [ngStyle]="{'background-color': _percentage >= 60 ? color : 'var(--nav-grey2)'}"></div>
        <div class="dot" [ngStyle]="{'background-color': _percentage >= 70 ? color : 'var(--nav-grey2)'}"></div>
        <div class="dot" [ngStyle]="{'background-color': _percentage >= 80 ? color : 'var(--nav-grey2)'}"></div>
        <div class="dot" [ngStyle]="{'background-color': _percentage >= 90 ? color : 'var(--nav-grey2)'}"></div>
        <div class="dot" [ngStyle]="{'background-color': _percentage >= 100 ? color : 'var(--nav-grey2)'}"></div>
      </div>
      <span class="name">
        {{ stage }}
      </span>
    </div>
  </div>
}
