
@if (auth.isSuperAdmin()) {
  <div class="d-flex mb-3 mt-1 border-bottom">
    <div class="text-truncate or-secondary h4 m-2"> Survey Settings </div>
    <div class="vl ml-2"></div>
    <div class="sectionbtn nav-item mt-1">
      <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'urls'" (click)="tab = 'urls'">Urls </a>
    </div>
    <div class="sectionbtn nav-item mt-1">
      <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'defaults'" (click)="tab = 'defaults'">Default Settings </a>
    </div>
    <div class="sectionbtn nav-item mt-1 mr-auto">
      <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'health'" (click)="tab = 'health'">Health Config </a>
    </div>
  </div>
  @if (tab == 'urls') {
    <div class="card">
      <div class="card-header or-primary-bg text-white">
        <p class="h4 inline float-left"><em class="fa fa-chart-line fa-sm"></em> Urls</p>
      </div>
      <div class="card-body">
        <div class="mt-0">
          <div class="row">
            <div class="col-lg-12 col-xl-6">
              <app-redirect-urls [instanceId]="instanceId"></app-redirect-urls>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  @if (tab == 'defaults') {
    <div class="card">
      <div class="card-header or-primary-bg text-white">
        <p class="h4 inline float-left"><em class="fa fa-chart-line fa-sm"></em> Default Settings for alerts</p>
      </div>
      <div class="card-body">
        <div class="mt-0">
          <div class="row">
            <div class="col-lg-12 col-xl-6">
              <app-alerts [instanceId]="instanceId"></app-alerts>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  @if (tab == 'health') {
    <app-health-config></app-health-config>
  }
}
