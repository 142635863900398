import { Component, Output, EventEmitter, Input, forwardRef, ViewChild } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'or-groupnotes-wysiwyg',
  templateUrl: './groupnotes-wysiwyg.component.html',
  styleUrls: ['./groupnotes-wysiwyg.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GroupNotesWysiwygComponent),
      multi: true
    }
  ]
})
export class GroupNotesWysiwygComponent implements ControlValueAccessor {

  public Editor = ClassicEditor;

  onChangeValue: (url) => void;

  @Input() data = '';
  @Input() isDisabled = false;
  @Input() formControlName = '';
  @Output() messageEvent = new EventEmitter<string>();
  @Output() messageCount = new EventEmitter<number>();

  @Input() config = {
    placeholder: 'Type body and click save',
    toolbar: {
      toolbarLocation: 'bottom',
      items: [
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
      ]
    },
    language: 'en',
    toolbarLocation: 'bottom',

  };

  public onChange( { editor }: ChangeEvent ) {
    const data = editor.data.get();

    this.data = data;
    if (this.formControlName !== '') {
      this.onChangeValue(this.data);
    }

    this.sendMessage();
    this.sendCount();
  }

  constructor() {  }

  writeValue(value: string): void {
    this.data = value ? value : '';
  }
  registerOnChange(fn: any): void {
    this.onChangeValue = fn;
  }
  registerOnTouched(fn: any): void {

  }

  onReady(editor) {
    editor.focus();
  }

  setDisabledState?(isDisabled: boolean): void {

  }

  sendMessage() {
    this.messageEvent.emit(this.data);
  }

  sendCount() {
    const count = this.data.replace(/<[^>]*>|\s/g, '').length;
    this.messageCount.emit(count);
  }
}
