@if (model == null) {
  <div class="loader"> Loading Vendor</div>
}

@if (model != null) {
  <form [formGroup]="partnerForm" (ngSubmit)="submitForm()" novalidate>
    <div class="card m-3">
      <div class="card-header text-white or-primary-bg">
        <p class="h4 inline"><em class="fa fa-project-diagram fa-sm"></em> Vendor Details</p>
      </div>
      <div class="card-body">
        <input type="hidden" class="form-control" formControlName="id" id="id" placeholder="" required="true">
        <div class="d-flex mb-3 mt-1 border-bottom">
          <div class="sectionbtn nav-item mt-1">
            <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'main-details'" (click)="tab = 'main-details'">Main Details </a>
          </div>
          <div class="sectionbtn nav-item mt-1">
            <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'redirects'" (click)="tab = 'redirects'">Redirects </a>
          </div>
          <!-- TODO: Add back at later date -->
          <!-- <div class="sectionbtn nav-item mt-1">
          <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'webhooks'" (click)="tab = 'webhooks'">Webhooks </a>
        </div> -->
      </div>
      @if (tab == 'main-details') {
        <app-partner-edit-main-details [parentGroup]="partnerForm"></app-partner-edit-main-details>
      }
      @if (tab == 'redirects') {
        <app-partner-edit-redirects
          [vendorId]="vendorId"
          [parentGroup]="partnerForm"
          [globalPartnerId]="partnerForm.value.globalPartner">
        </app-partner-edit-redirects>
      }
      @if (tab == 'webhooks') {
        <app-partner-edit-webhooks
          [vendorid]="vendorId"
          [hooks]="hooks"
          [globalPartnerId]="partnerForm.value.globalPartner">
        </app-partner-edit-webhooks>
      }
    </div>
    <div class="card-footer text-right mt-3">
      <div class="btn btn-light mr-2" [routerLink]="['/vendors']"  type="button">Cancel</div>
      <button class="btn or-primary-bg text-white" type="submit">Save</button>
    </div>
  </div>
</form>
}
