import { Component, OnInit, Input, OnChanges } from '@angular/core';
declare var google: any;


@Component({
  selector: 'or-cleanid-timeline-chart',
  templateUrl: './cleanid-timeline-chart.component.html',
  styleUrls: ['./cleanid-timeline-chart.component.css']
})
export class CleanidTimelineChartComponent  implements OnInit, OnChanges {

  @Input() public set data(data) {
    this.markers = data;

    this.showTimelineChart();
  }
  @Input() backgroundColor = '#f0f0f0';

  markers;
  summaryData;
  dataMode = 'summary';
  ticks = [];
  options = {
    tooltip: {isHtml: true},
    backgroundColor: this.backgroundColor,
    legend: {textStyle: {fontSize: 12}, position: 'bottom'}
  };
  markerSections = {
    device : {TotalUnknownUserAgent: true, TotalUnknownBrowser: true, TotalObsoleteBrowser: true, TotalUnknownOs: true, TotalObsoleteOs: true, TotalUnknownDeviceType: true,
      TotalBot: true, TotalInvalidUserAgent: true, TotalInvalidProperties: true, TotalUnknownConfig: true, TotalInvalidRequestInputs: true, TotalInvalidResponseToken: true, TotalVelocity: true, TotalOscillating: true},
    privacy: {TotalBlacklisted: true, TotalDatacenter: true, TotalVpn: true, TotalTor: true, TotalProxy: true, TotalPrivacyMode: true, TotalPrivateBrowser: true, TotalEmulator: true, TotalMasked: true},
    language: {TotalInvalidLang: true, TotalInvalidGeoLang: true, TotalInvalidGeoOsLang: true},
    location: {TotalInvalidGeoTimezone: true, TotalInvalidGeoCountry: true, TotalUnknownCountryCode: true, TotalCountryCodeNoMatch: true, TotalUnknownPostalCode: true,
      TotalPostalCodeProximityLimit: true, TotalGeoOffHrs: true},
    requests: {TotalRequests: true}
 };
  constructor() { }

  ngOnInit() {}
  ngOnChanges() {
    this.showTimelineChart();
}

  showTimelineChart() {
    this.dataMode = 'summary';

    const dates = this.getDateRange();

    const chartData = [];
    dates.forEach( day => {
      let deviceCount = 0;
      let privacyCount = 0;
      let languageCount = 0;
      let locationCount = 0;
      let requestCount = 0;

      this.markers.forEach( marker => {

        const count = marker.data.filter( e =>  e.t === day)[0].v;

        if (count === 0) { return true; }

        this.ticks.push(count);

        if (this.markerSections.device[marker.marker]) {
          deviceCount += count;
        } else if (this.markerSections.privacy[marker.marker]) {
          privacyCount += count;
        } else if (this.markerSections.language[marker.marker]) {
          languageCount += count;
        } else if (this.markerSections.location[marker.marker]) {
          locationCount += count;
        } else if (this.markerSections.requests[marker.marker]) {
          requestCount += count;
        }
      });
      chartData.push([new Date(day), deviceCount, privacyCount, languageCount, locationCount, requestCount]);
    });

    this.summaryData = [['', 'Device', 'Privacy', 'Language', 'Location', 'Requests']].concat(chartData);
    this.drawChart(this.summaryData);
  }

  showBreakdownChart(selectedMarker) {
    this.dataMode = selectedMarker;

    let chosenMarkers: any;
    if (this.dataMode === 'Device') { chosenMarkers = this.markerSections.device; } else if (this.dataMode === 'Privacy') { chosenMarkers = this.markerSections.privacy; } else if (this.dataMode === 'Language') { chosenMarkers = this.markerSections.language; } else if (this.dataMode === 'Requests') { chosenMarkers = this.markerSections.requests; } else { chosenMarkers = this.markerSections.location; }

    const markerData = this.markers.filter(e => chosenMarkers[e.marker]);
    const dates = this.getDateRange();
    const chartData = [];
    dates.forEach( day => {
      const dayData = [new Date(day)];
      markerData.forEach( marker => {
        const count = marker.data.filter( e =>  e.t === day)[0].v;
        dayData.push(count);
      });
      chartData.push(dayData);
    });
    const headers = [''];
    markerData.forEach( e => {
      headers.push(e.marker);
    });
    this.drawChart([headers].concat(chartData));

  }
  drawChart(data) {

    const options = {
      backgroundColor: this.backgroundColor,
      legend: {textStyle: {fontSize: 12, fontName: 'Arial'}},
      tooltip: {isHtml: true, textStyle: {fontSize: 12, fontName: 'Arial'}},
      curveType: 'function',
      theme: 'material',
      chartArea: {
        top: '10%',
        right: '20%',
        bottom: '10%',
        left: '10%',
        width: '100%',
        height: '100%',
        backgroundColor: this.backgroundColor
      },
      hAxis: {
        format: 'MMM (yyyy)',
        textStyle: {fontName: 'Arial'}
      },
      vAxis: {
        textStyle: {fontName: 'Arial'},
        gridlines: {
          count: Math.ceil(Math.max(...this.ticks) * 1.1 / 5) // Setting Up the total of gridlines (ticks)
        }
      }
    };

    google.charts.load('current', {packages: ['corechart']});
    google.charts.setOnLoadCallback(() => {

      const chartData = google.visualization.arrayToDataTable(data);
      setTimeout(() => {
        const chartDiv = document.getElementById('chart_cleanid_timeline');
        const chart = data.length > 1 ? new google.visualization.LineChart(chartDiv) : new google.visualization.PieChart(chartDiv);
        if (this.dataMode === 'summary') {
          google.visualization.events.addListener(chart, 'select', () => {
            const selectedMarker = this.summaryData[0][chart.getSelection()[0].column];
            this.showBreakdownChart(selectedMarker);
          });
        }
        chart.draw(chartData, options);
      }, 500);
    });
  }

  getDateRange() {
    return this.markers[0].data.map(e => e.t).sort((a, b) => a - b);
  }
}
