import { PublicKey } from 'core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiKeyService {
  baseUrl = environment.apiEndpoint + '/apikey/';
  file: File;

  requestId = '';

  constructor(private http: HttpClient) { }

  getPublicKeys(instanceId: string): Observable<PublicKey[]> {
    return this.http.get<PublicKey[]>(this.baseUrl + 'public?instanceId=' + instanceId);
  }

  getPublicKey(id: any): Observable<PublicKey> {
    return this.http.get<PublicKey>(this.baseUrl + 'public/' + id);
  }

  addPublicKey(model: any): Observable<PublicKey> {
    return this.http.post<PublicKey>(this.baseUrl + 'public', model);
  }

  editPublicKey(model: any): Observable<PublicKey> {
    return this.http.put<PublicKey>(this.baseUrl + 'public/' + model.id, model);
  }

  deletePublicKey(id: any): Observable<PublicKey> {
    return this.http.delete<PublicKey>(this.baseUrl + 'public/' + id);
  }

}
