import { AfterContentInit, Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appAutoFocusDirective]'
})
export class AutoFocusDirective implements OnInit, AfterContentInit  {
  constructor(private el: ElementRef) { }

  ngOnInit() {
  }


  ngAfterContentInit() {
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 200);
  }
  // ngOnChanges() {
  //   (this.tab as any).__tabOrder = +this.tabOrder;
  //   this.tab.tabset.tabs.sort((a: any, b: any) => a.__tabOrder - b.__tabOrder);
  // }

}
