@if (this.auth.getUser()) {
  <div class="bg-light border-right sidebar-sticky" id="sidebar-wrapper">
    <div class="list-group list-group-flush">
      <!--<a [routerLink]="['/home']" *ngIf="this.auth.getUser().superAdmin" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
      <i class="fas fa-tachometer-alt fa-lg or-secondary"></i> Home
    </a>-->
    <a [routerLink]="['/usermanager']" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
      <i class="fas fa-users fa-lg or-secondary"></i> Users & Roles
    </a>
    @if (this.auth.getUser().superAdmin) {
      <a [routerLink]="['/instancemanager']" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
        <i class="fas fa-server fa-lg or-secondary"></i> Instances
      </a>
    }
    @if (this.auth.getUser().superAdmin) {
      <a [routerLink]="['/site-setup']" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
        <i class="fas fa-sitemap fa-lg or-secondary"></i> Site Setup
      </a>
    }
    @if (this.auth.getUser().superAdmin || this.auth.getUser().access.partnerEnable) {
      <a [routerLink]="['/vendors']" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
        <i class="fas fa-handshake fa-lg or-secondary"></i> Vendors
      </a>
    }
    @if (this.auth.getUser().superAdmin || this.auth.getUser().access.clientEnable) {
      <a [routerLink]="['/clients']" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
        <i class="fas fa-user-tie fa-lg or-secondary"></i> Clients
      </a>
    }
    @if (this.auth.getUser().superAdmin || this.auth.getUser().access?.projectEnable) {
      <a [routerLink]="['/project-manager']"  routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
        <i class="fas fa-chart-line fa-lg or-secondary"></i> Project Manager
      </a>
    }
    @if (this.auth.getUser().superAdmin) {
      <a  [routerLink]="['/survey-settings']" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
        <i class="fas fa-cog fa-lg or-secondary"></i> Survey Settings
      </a>
    }
    @if (this.auth.getUser().superAdmin || this.auth.getUser().access.cleanIDEnable) {
      <a [routerLink]="['/cleanid']" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
        <i class="fas fa-user-check fa-lg or-secondary"></i> CleanID
      </a>
    }
    @if (this.auth.getUser().superAdmin || this.auth.getUser().access.responseIDEnable) {
      <a [routerLink]="['/responseid']" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
        <i class="fas fa-check-double fa-lg or-secondary"></i> ResponseID
      </a>
    }
    <a [routerLink]="['/keys']" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
      <i class="fas fa-key fa-lg or-secondary"></i> Keys
    </a>
    <a [routerLink]="['/quota-questions']" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
      <i class="fas fa-question fa-lg or-secondary"></i> Quota Questions
    </a>
    <!--<a [routerLink]="['/dashboard-manager']" *ngIf="this.auth.getUser().superAdmin" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
    <i class="fas fa-grip-horizontal fa-lg or-secondary"></i> Dashboard Manager
  </a>
  <a [routerLink]="['/app-integration']" *ngIf="this.auth.getUser().superAdmin" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
    <i class="fas fa-chart-bar fa-lg or-secondary"></i> Apps
  </a>
  <a [routerLink]="['/live-monitoring']" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
    <i class="fas fa-chart-bar fa-lg or-secondary"></i> Live Monitoring
  </a>-->
</div>
</div>
}

