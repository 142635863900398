import { Component, OnInit, Input, OnChanges } from '@angular/core';
declare var google: any;

@Component({
  selector: 'or-cleanid-summary-chart',
  templateUrl: './cleanid-summary-chart.component.html',
  styleUrls: ['./cleanid-summary-chart.component.css']
})
export class CleanidSummaryChartComponent implements OnInit, OnChanges {

  @Input() backgroundColor = '#f0f0f0';

  @Input() public set data(data) {
    let deviceCount = 0;
    let privacyCount = 0;
    let languageCount = 0;
    let locationCount = 0;
    data.forEach( e => {
        const count = e.data.reduce(( sum , cur ) =>  sum + cur.v, 0);
        if (count === 0) {
          return true;
        }
        if (this.markerSections.device[e.marker]) {
          deviceCount += count;
        } else if (this.markerSections.privacy[e.marker]) {
          privacyCount += count;
        } else if (this.markerSections.language[e.marker]) {
          languageCount += count;
        } else if (this.markerSections.location[e.marker]) {
          locationCount += count;
        }
    });
    //  TO DO: MAYBE ORDER HIGH TO LOW
    this.markers = data;
    this.summaryData = [['Device', deviceCount], ['Privacy', privacyCount], ['Language', languageCount], ['Location', locationCount]].filter(e => e[1] !== 0);
    this.drawChart('summary');
  }
  markers;
  summaryData;
  dataMode = 'summary';
  options = {
    legend: {textStyle: {fontSize: 12}},
    backgroundColor: this.backgroundColor,
    tooltip: {isHtml: true},
    pieHole: 0.4,
    pieSliceTextStyle: {
      color: 'black',
    },
    chartArea: {
      top: '10%',
      right: '5%',
      bottom: '5%',
      left: '5%',
      width: '100%',
      height: '100%',
      backgroundColor: this.backgroundColor
    }
  };
  markerSections = {
    device : {TotalUnknownUserAgent: true, TotalUnknownBrowser: true, TotalObsoleteBrowser: true, TotalUnknownOs: true, TotalObsoleteOs: true, TotalUnknownDeviceType: true, TotalBot: true, TotalInvalidUserAgent: true, InvalidProperties: true, TotalUnknownConfig: true, TotalInvalidRequestInputs: true, TotalInvalidResponseToken: true, TotalVelocity: true, TotalOscillating: true},
    privacy: {TotalBlacklisted: true, TotalDatacenter: true, Vpn: true, TotalTor: true, TotalProxy: true, TotalPrivacyMode: true, TotalPrivateBrowser: true, TotalEmulator: true, TotalMasked: true},
    language: {TotalInvalidLang: true, TotalInvalidGeoLang: true, TotalInvalidGeoOsLang: true},
    location: {TotalInvalidGeoTimezone: true, TotalInvalidGeoCountry: true, TotalUnknownCountryCode: true, TotalTotalCountryCodeNoMatch: true, TotalTotalUnknownPostalCode: true, TotalPostalCodeProximityLimit: true, TotalGeoOffHrs: true}
 };
  constructor() { }

  ngOnInit() {  }
  ngOnChanges() {}

  drawChart(dataMode) {
    this.dataMode = dataMode;
    let data = [];
    if (this.dataMode === 'summary') {
      data =  this.summaryData;
    } else {
      let chosenMarkers: any;
      if (this.dataMode === 'Device') { chosenMarkers = this.markerSections.device; } else if (this.dataMode === 'Privacy') { chosenMarkers = this.markerSections.privacy; } else if (this.dataMode === 'Language') { chosenMarkers = this.markerSections.language; } else { chosenMarkers = this.markerSections.location; }

      this.markers.filter(e => chosenMarkers[e.marker]).forEach( e => {
        const count = e.data.reduce(( sum , cur ) =>  sum + cur.v, 0);
        if (count > 0) { data.push([e.marker, count]); }
      });
    }

    google.charts.load('current', {packages: ['corechart']});
    google.charts.setOnLoadCallback(() => {
      const chartData = google.visualization.arrayToDataTable([['Marker', 'Count']].concat(data));
      setTimeout(() => {
        const chartDiv = document.getElementById('chart_cleanid_summary');
        const chart = new google.visualization.PieChart(chartDiv);
        if (this.dataMode === 'summary') {
          google.visualization.events.addListener(chart, 'select', (e) => {
            const selectedMarker = this.summaryData[chart.getSelection()[0].row][0];
            this.drawChart(selectedMarker);
          });
        }
        chart.draw(chartData, this.options);
      }, 500);
    });
  }
}
