export interface Integration {
    id: string;
    vendorId: string;
    vendorName: string;
    integrationType: string;
    status:boolean;
    baseUrl:string;
    imageUrl: boolean;
}

