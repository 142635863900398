import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'or-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  @Input() address: any;

  constructor() { }

  ngOnInit() { }

}
