<div class="d-flex mb-3 mt-1 border-bottom">
  <div class="text-truncate or-secondary h4 m-2"> Live Monitoring </div>
  <div class="vl ml-2 mr-auto"></div>
  <div class="float-right mr-2 form-inline form">

  </div>
</div>

<div class="card">
  <div class="card-header or-primary-bg text-white">
    <div class="row">
      <div class="col">
        <p class="h4 inline float-left"><em class="fas fa-chart-bar fa-sm"></em> Reports</p>
      </div>
      <div class="col-2">
        <select class="form-control inline mr-3" (change)="changeTypeReport($event)" id="timeRange" >
          <option value="hour">Last Hour</option>
          <option value="day">Last Day</option>
          <option value="month">Last Month</option>
        </select>
      </div>
      <div class="col-3 d-inline-flex">
        @if (!enableProjectId) {
          <or-type-ahead [labelField]="'name'" [multi]="false" [valueField]="'value'" [showLoading]="false" class="mr-2 w-100"
            [dataCallback]="getProjectList"
            placeholder="Project Name"
            (valueChange)="updateProject($event)" >
          </or-type-ahead>
        }
        @if (enableProjectId) {
          <input [(ngModel)]="projectId" type="search" class="form-control inline mr-3" placeholder="Project Id">
        }

        <div class="or-checkbox d-block">
          <input [checked]="enableProjectId" type="checkbox" name="projectId" id="projectId" (change)="enableProjectId = !enableProjectId" >
          <label for="projectId"></label>
        </div>
      </div>

      <div class="col-3 d-inline-flex mx-3">
        @if (!enablePartnerId) {
          <or-type-ahead [labelField]="'name'" [multi]="false" [valueField]="'value'" [showLoading]="false" class="mr-2 w-100"
            [dataCallback]="getPartnerList"
            placeholder="Vendor Name"
            (valueChange)="updatePartner($event)" >
          </or-type-ahead>
        }
        @if (enablePartnerId) {
          <input [(ngModel)]="partnerId" type="search" class="form-control inline mr-3" placeholder="Partner Id">
        }
        <div class="or-checkbox d-block">
          <input [checked]="enablePartnerId" type="checkbox" name="partnerId" id="partnerId" (change)="enablePartnerId = !enablePartnerId" >
          <label for="partnerId"></label>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col">
        @if (markerData.entries?.length > 0) {
          <or-log-chart [data]="markerData.entries" title="Entries"></or-log-chart>
        }
        @if (markerData.entries?.length < 1) {
          <div style="height: 100px;" class="text-center mt-5"><span class="lead" >No Data</span></div>
        }
      </div>
      <div class="col">
        @if (markerData.starts?.length > 0) {
          <or-log-chart [data]="markerData.starts" title="Starts"></or-log-chart>
        }
        @if (markerData.starts?.length < 1) {
          <div style="height: 100px;" class="text-center mt-5"><span class="lead" >No Data</span></div>
        }
      </div>
      <div class="col">
        @if (markerData.completes?.length > 0) {
          <or-log-chart [data]="markerData.completes" title="Completes"></or-log-chart>
        }
        @if (markerData.completes?.length < 1) {
          <div style="height: 100px;" class="text-center mt-5"><span class="lead" >No Data</span></div>
        }
      </div>
      <div class="col">
        @if (markerData.dropoffs?.length > 0) {
          <or-log-chart [data]="markerData.dropoffs" title="Dropoffs"></or-log-chart>
        }
        @if (markerData.dropoffs?.length < 1) {
          <div style="height: 100px;" class="text-center mt-5"><span class="lead" >No Data</span></div>
        }
      </div>
    </div>
    <hr>
      <div class="row">
        <div class="col">
          @if (markerData.fraud?.length > 0) {
            <or-log-chart [data]="markerData.fraud" title="Fraud"></or-log-chart>
          }
          @if (markerData.fraud?.length < 1) {
            <div style="height: 100px;" class="text-center mt-5"><span class="lead" >No Data</span></div>
          }
        </div>
        <div class="col">
          @if (markerData.invalid?.length > 0) {
            <or-log-chart [data]="markerData.invalid" title="Invalid"></or-log-chart>
          }
          @if (markerData.invalid?.length < 1) {
            <div style="height: 100px;" class="text-center mt-5"><span class="lead" >No Data</span></div>
          }
        </div>
        <div class="col">
          @if (markerData.errors?.length > 0) {
            <or-log-chart [data]="markerData.errors" title="Errors"></or-log-chart>
          }
          @if (markerData.errors?.length < 1) {
            <div style="height: 100px;" class="text-center mt-5"><span class="lead" >No Data</span></div>
          }
        </div>
        <div class="col">
          @if (markerData.dupes?.length > 0) {
            <or-log-chart [data]="markerData.dupes" title="Dupes"></or-log-chart>
          }
          @if (markerData.dupes?.length < 1) {
            <div style="height: 100px;" class="text-center mt-5"><span class="lead" >No Data</span></div>
          }
        </div>
      </div>
    </div>
  </div>

