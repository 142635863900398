@if (icon == '' || icon == null) {
  <div class="toggle">
    @if (class != 'iosText') {
      <input class="tgl tgl-{{ class }}" [id]="id" type="checkbox" (change)="setChecking($event)" [attr.disabled]="isDisabled ? '' : null" [attr.checked]="_isChecked ? '' : null" />
      <label class="tgl-btn {{ size }}" [for]="id" [attr.msgON]="msgON" [attr.msgOFF]="msgOFF"></label>
    }
    @if (class == 'iosText') {
      <input class="tgl tgl-iosText" [id]="id" type="checkbox" (change)="setChecking($event)" [attr.disabled]="isDisabled ? '' : null" [attr.checked]="_isChecked ? '' : null" />
      <label class="tgl-btn {{ size }}" [for]="id" [attr.msgON]="msgON" [attr.msgOFF]="msgOFF" [attr.currentmsg]="currentmsg" [attr.optionmsg]="optionMsg"></label>
    }
  </div>
}
<!-- {{ _isChecked }} -->
@if (icon != '' && icon != null) {
  <div class="btn-group-toggle" data-toggle="buttons" tooltip="{{ iconTooltip != '' ? iconTooltip : null }}">
    <label class="btn btn-lg active p-0">
      <input type="checkbox" checked autocomplete="off" [attr.checked]="_isChecked ? '' : null" (change)="setChecking($event)">
      <i [class]="(_isChecked ? 'fas ' : 'far ') + icon" [style.color]="iconColor"></i>
    </label>
  </div>
}
