import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { AlertifyService } from '../../services/alertify.service';

@Component({
  selector: 'or-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleButtonComponent),
      multi: true
    }
  ]
})
export class ToggleButtonComponent implements ControlValueAccessor, OnInit {
  static count = 0;

  @Input() formControlName = '';
  @Input() msgON = 'YES';
  @Input() msgOFF = 'NO';
  @Input() class = 'skewed';
  @Input() size = 'medium';
  @Input() public set isChecked(val) {
    this._isChecked = val;
  };
  @Input() isDisabled = false;
  @Input() icon = '';
  @Input() iconColor = '#6c757d';
  @Input() iconTooltip = '';

  @Output() changed = new EventEmitter();
  propagateChange: (_: any) => {};
  id;
  _isChecked = false;
  currentmsg = '';
  optionMsg = ''

  constructor(private alertify: AlertifyService) {
    ToggleButtonComponent.count ++ ;
    this.id = 'toggle' + ToggleButtonComponent.count;
  }

  ngOnInit() {
  }

  setChecking(event: any) {    
    if (!this.isDisabled) {
      this._isChecked = event.target.checked;

      if (this.propagateChange) {
        this.propagateChange(this._isChecked);
      }
      this.changed.emit(this._isChecked);

      if (this._isChecked == true) {
        this.currentmsg = this.msgON;
        this.optionMsg = this.msgOFF;
      }
      else {
        this.currentmsg = this.msgOFF
        this.optionMsg = this.msgON;
      }
    }
  }

  /*************************
    The writeValue method is called in 2 situations:
    1 - When the formControl is instantiated
        checkbox = new FormC ontrol({value: null, disabled: false})

    2 - When the formControl value changes
        checkbox.patchValue(3)
  **************************/
  writeValue(val: boolean): void {
    this._isChecked = val;
    if (this._isChecked == true) {
      this.currentmsg = this.msgON;
      this.optionMsg = this.msgOFF;
    }
    else {
      this.currentmsg = this.msgOFF
      this.optionMsg = this.msgON;
    }
  }

  /*************************
    The registerOnChange method should be called whenever the value changes,
    in this case, when the checkbox is clicked.
  **************************/
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /*************************
    The registerOnTouched method should be called whenever our UI is interacted with - like a blur event.
    You may be familiar with implementing Typeaheads from a library like Bootstrap or NGX-Bootstrap that has an onBlur method.
  **************************/
  registerOnTouched(fn: any): void {

  }

  /*************************
    Optional method
    The setDisabledState method is called in 2 situations:

    1 - When the formControl is instantiated with a disabled prop
      checkbox = new FormControl({value: null, disabled: false})
    2 - When the formControl disabled status changes
      checkbox.disable();
      checkbox.enable();

  **************************/
  setDisabledState?(disabled: boolean): void {
    this.isDisabled = disabled;
  }

}
