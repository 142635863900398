import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Partner, PartnerContact } from 'core';

@Injectable({
  providedIn: 'root'
})
export class PartnerContactService {

baseUrl = environment.apiEndpoint + '/partner/partnercontact';

constructor(private http: HttpClient) {}


GetPartnerContacts(partnerId: string): Observable<PartnerContact[]> {
  return this.http.get<PartnerContact[]>(this.baseUrl + '/' + partnerId);
}

AddPartnerContact(model: any): Observable<PartnerContact> {
  return this.http.post<PartnerContact>(this.baseUrl + '', model);
}

EditPartnerContact(model: any): Observable<PartnerContact> {
  return this.http.put<PartnerContact>(this.baseUrl + '/' + model.id, model);
}

DeletePartnerContact(model: any) {
  return this.http.delete(this.baseUrl + '/' + model.id);
}

}
