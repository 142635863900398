<div>
  <div style="width: 33%;" class="pr-1 countrycode" [class.noborder]="callingCode?.length > 0">
    <div>
      <label for="country">Phone</label>
      <div class="input-group mb-3">
        <or-type-ahead
          name="countryList"
          [labelField]="'name'" 
          [valueField]="'value'"
          [(ngModel)]="callingCode"
          [ngModelOptions]="{standalone: true}"
          (valueChange)="setCallingCode($event)"
          [selectedValues]="callingCode"
          [showLoading]="false"
          class="w-100"
          [dataCallback]="getCountryCode"
          placeholder="Code">
        </or-type-ahead>
      </div>
    </div>
  </div>
  <div style="width: 67%;"  class="pl-0">
    <div>
      <label for="phone">&nbsp;</label>
      <input
        id="phone"
        type="text"
        class="form-control"
        formControlName="phone"
        onkeypress="return isNumberKey(event)"
        maxlength="15"
        placeholder="Number">
    </div>
  </div>
</div>