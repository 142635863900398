
<div class="d-flex mb-3 mt-1 border-bottom">
  <div class="text-truncate or-secondary h4 m-2"> Quota Questions </div>
  <div class="vl ml-2 mr-auto"></div>
</div>

<div class="card">
  <div class="card-header or-primary-bg text-white">
    <p class="h4 inline  float-left"><em class="fa fa-user-check fa-sm"></em> Manage Quota Questions</p>
  </div>
  <div class="card-body">
    <div class="mt-0">
      <or-list [items]="questions" [layout]="layout" [actions]="actions" [enableExport]="false" [showActionColLabel]="true" (action)="handleAction($event)"></or-list>
    </div>
  </div>
</div>

<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Are you sure you want to delete {{this.deleteQuestion.question}}?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
  </div>
</ng-template>
