
<div class="grid mt-4" [class.viewonly]="viewonly">
  <div class="d-flex align-items-center justify-content-center">
    <span class="grey5 yLabel text-nowrap">LOI (minutes)</span>
  </div>
  <div class="">
    <div class="row align-items-baseline justify-content-center">
      <span class="grey5 xLabel text-nowrap">Incidence Rate</span>
    </div>
    <div class="row">
      <div [ngClass]="{'table-responsive' : data?.length > 19}" class="tableDiv">
        <table class="table table-sm table-bordered">
          <thead>
            <tr class="mainRow">
              <td class="blankCell" *ngIf="data?.length !== 0"></td>
              <td class="blankCell" *ngIf="data?.length !== 0"></td>
              @for (col of data?.cols; track col; let i = $index) {
                <td class="text-center" [ngClass]="{'first' : i === 0}">
                  <button class="btn btn-secondary removeBtn pb-2" [class.invisible]="viewonly" (click)="removeCol(i)"><i class="far fa-times fa-sm"></i></button>
                  <div class="input-group grey6">
                    <input
                      #inputCol
                      type="number"
                      mask="999"
                      style="background-color: #F1F4FA"
                      [patterns]="integerPattern"
                      (ngModelChange)="headerChange(i, $event, 'cols')"
                      [(ngModel)]="data.cols[i]"
                      class="form-control form-control-xs grey6">
                  </div>
                </td>
              }
              <!-- add cols -->
              <td class="text-center" style="vertical-align: bottom;">
                <div class="grey6 border-0">
                  <button class="bg-transparent border-0 pr-3 mb-1" [class.invisible]="viewonly" (click)="addCol()"><i class="far fa-plus fa-md grey3"></i></button>
                </div>
              </td>
            </tr>

          </thead>
          <tbody>
            @for (row of data?.rows; track row; let i_x = $index) {
              <tr>
                <td class="border-0 text-center">
                  <button class="btn btn-secondary removeBtn" [class.invisible]="viewonly" (click)="removeRow(i_x)"><i class="far fa-times fa-sm"></i></button>
                </td>
                <td class="text-center" style="background-color: #F1F4FA">
                  <div class="input-group grey6">
                    <input #inputRow type="number" mask="999" [patterns]="integerPattern" [(ngModel)]="data.rows[i_x]" (ngModelChange)="headerChange(i_x, $event, 'rows')" class="form-control form-control-xs grey6" style="background-color: #F1F4FA">
                  </div>
                </td>
                @for (col of data?.cols; track col; let i_y = $index) {
                  <td tooltip="LOR: {{data.rows[i_x] || 'null'}}, IR: {{col || 'null'}} - CPI: {{data.matrix[i_x][i_y] || 'null'}}" container="body">
                    <div class="input-group">
                      <input
                        type="number"
                        [(ngModel)]="data.matrix[i_x][i_y]"
                        (ngModelChange)="valueChange(i_x, i_y, $event)"
                        [ngStyle]="{'background-color': getCellBackgroundColor(data.matrix[i_x][i_y])}"
                        class="form-control form-control-sm">
                    </div>
                  </td>
                }
              </tr>
            }
            <!-- add rows -->
            <tr>
              <td class="blankCell border-0"></td>
              <td class="blankCell border-0 text-center">
                <button class="bg-transparent border-0 mt-1" [class.invisible]="viewonly" (click)="addRow()"><i class="far fa-plus fa-md grey3"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>




