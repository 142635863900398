import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Dropdown, JobsDropdown } from 'core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicService {
  baseUrl = environment.apiEndpoint + '/test/';

  constructor(private http: HttpClient) { }

  getCleanIDTest(): string {
    return this.baseUrl + 'cleanid';
  }
  getResponseIDTest(): string {
    return this.baseUrl + 'responseid';
  }
}
