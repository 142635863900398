import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: string = '', fields= []): any[] {
    if (!items) { return []; }
    if (searchText === '') { return items; }
    searchText = searchText.toLowerCase();

    let searchTexts = []
    //add the original text
    searchTexts[0] = searchText;

    //conditions if the search text contains different variations of '&' or 'and'
    if (searchText.includes('and')){
      searchTexts.push(searchText.replace(/and/g, "&"))
    }
    else if (searchText.includes(' &')) {
      searchTexts.push(searchText.replace(/ &/g, " and"))
    }
    else if (searchText.includes('& ')) {
      searchTexts.push(searchText.replace(/& /g, "and "))
    }
    else if (searchText.includes('&')) {
      searchTexts.push(searchText.replace(/&/g, " and"))
      searchTexts.push(searchText.replace(/&/g, "and "))
      searchTexts.push(searchText.replace(/&/g, " and "))
    }
    //Remove duplicates
    searchTexts = [...new Set(searchTexts)];

    let results = []

    //Itereate through the searchTexts array and search the items. Add any matches to the results array
    for (let text of searchTexts) {
      results.push(
        items.filter( it => {
          for (const field of fields) {
            if (it[field] != null && it[field].toString().toLowerCase().includes(text)) {
              return true;
            }
          }
          return false;
        })
      )
    }
    //flatten the array
    let flattenedResults = results.flatMap(innerArray => innerArray);
    //Remove duplicates
    flattenedResults = [...new Set(flattenedResults)];

    return flattenedResults;
  }
}
