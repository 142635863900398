import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
declare var google: any;

@Component({
  selector: 'or-trend-chart',
  templateUrl: './trend-chart.component.html',
  styleUrls: ['./trend-chart.component.css']
})
export class TrendChartComponent implements OnInit {

  static count = 0;

  @Input() data: any;
  @Input() backgroundColor = '#f0f0f0';

  @Output() callback = new EventEmitter();

  id: string;
  constructor() {
    TrendChartComponent.count++;
    this.id = 'trend_chart_' + TrendChartComponent.count;
   }

  ngOnInit() {
    this.drawChart();
  }

  drawChart() {
    if(this.data != null && this.data.length !== 0){
      let maxCount = 0;
      this.data.forEach(e => {
        if ( e.count > maxCount) {
          maxCount = e.count;
        }
      });
      const options = {
        legend: {position: 'none', textStyle: {fontSize: 12}, alignment: 'center'},
        backgroundColor: this.backgroundColor,
        chartArea: {
          top: '10%',
          right: '10%',
          bottom: '10%',
          left: '10%',
          width: '90%',
          height: '100%',
          backgroundColor: this.backgroundColor
        },
        curveType: 'function',
        vAxis: {
          title: '',
          gridlines: {color: 'none'},
          ticks: [0, maxCount]
        },
        hAxis: {
          title: '',
          format: 'M/d/yy',
          gridlines: {color: 'none'}
        },
        width: '90%',
        height: '95%'
      };
  
      google.charts.load('current', {packages: ['corechart']});
      google.charts.setOnLoadCallback(() => {
        const chartData = google.visualization.arrayToDataTable([['Date', 'Number']].concat(this.data));
        setTimeout(() => {
            const chartDiv = document.getElementById(this.id);
            const chart = new google.visualization.LineChart(chartDiv);
            chart.draw(chartData, options);
            this.callback.emit({ event });
          }, 500);
      });
    }
  }
}
