import { Component, Input, OnInit } from '@angular/core';
import { InstanceService } from 'core';

@Component({
  selector: 'app-pagetitle',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})
export class PagetitleComponent implements OnInit {
  page:any = {};

  @Input() subPageOpen: boolean = false;

  constructor(private instanceService: InstanceService) { }

  ngOnInit() {
    this.page = this.instanceService.GetCurrentSection();
  }

}

