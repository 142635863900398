@if (social != null) {
  <div class="icons">
    @if (social.facebook) {
      <a class="icon" href="{{ social.facebook }}" target="_blank"><i class="fab fa-facebook-f"></i></a>
    }
    @if (social.twitter) {
      <a class="icon" href="{{ social.twitter }}" target="_blank"><i class="fab fa-twitter"></i></a>
    }
    @if (social.google) {
      <a class="icon" href="{{ social.google }}" target="_blank"><i class="fab fa-google"></i></a>
    }
    @if (social.skype && social.skype.length>2) {
      <a class="icon" (click)="callSkype()" target="_blank"><i class="fab fa-skype"></i></a>
    }
    @if (social.linkedIn) {
      <a class="icon" href="{{ social.linkedIn }}" target="_blank"><i class="fab fa-linkedin-in"></i></a>
    }
  </div>
}
