import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AlertifyService } from 'core';
import { Observable } from 'rxjs';

interface IChangesEntry {
  trackObj: any,
  hasUnsavedChanges: (obj: any) => boolean
};

@Injectable({
  providedIn: 'root'
})
export class UnsavedchangesGuardService implements CanDeactivate<unknown> {
  private possibleChangesObjects: IChangesEntry[] = [];

  constructor(private alertify: AlertifyService) {
    // For catching unsaved changes when navigating to a new webpage / reloading
    window.addEventListener('beforeunload', (e) => {
      if (this.unsavedChangesExist()) {
        e.returnValue = 'unsaved';
        e.preventDefault();
      }
      
      return true; 
    
    });
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.checkAll();

  }

  checkAll() {
    if (this.unsavedChangesExist()) {
      // Prompt user
      return new Promise<boolean>((resolve, reject) => {
        this.alertify.confirmYesNo(
          'There are unsaved changes on this page.',
          'Do you wish to continue? Unsaved changes may be discarded.',
          () => { this.deregisterAll(); resolve(true) },
          () => { resolve(false) },
        );
      });
      
    } else {
      return true
    }
  }

  check(objList: object[], deregisterOnContinue = true): Promise<boolean> {
    
    return new Promise<boolean>((resolve, reject) => {
      if (this.possibleChangesObjects.find(entry => objList.includes(entry.trackObj) && entry.hasUnsavedChanges(entry.trackObj))) {
        this.alertify.confirmYesNo(
          'There are unsaved changes on this page.',
          'Do you wish to continue? Unsaved changes will be discarded.',
          () => {
            if (deregisterOnContinue) this.deregister(objList);
            resolve(true)
          },
          () => { resolve(false) },
        );
      } else {
        this.deregister(objList);
        resolve(true);
      }
    });
    
  }

  register<T>(
    trackObj: T,
    hasUnsavedChanges: (obj?: T) => boolean): IChangesEntry {
      // if this exact object is already registered, skip it
      if(!this.possibleChangesObjects.find(entry => entry.trackObj === trackObj)) {
        const newEntry = {
          trackObj,
          hasUnsavedChanges
        };
        this.possibleChangesObjects.push(newEntry);
        return newEntry;
      }
      return null;
  }

  deregister(objList: object[]) {
    this.possibleChangesObjects = this.possibleChangesObjects.filter(entry => !objList.includes(entry.trackObj));
  }

  deregisterAll() {
    this.possibleChangesObjects = [];
  }

  unsavedChangesExist() {
    return this.possibleChangesObjects.some(entry => entry.hasUnsavedChanges(entry.trackObj));
  }

  
}
