export interface Feasibility {
    limit: number;
    feasibilityGroups: FeasibilityGroups[];
}

export interface FeasibilityGroups {
    name: string;
    ir: number;
    loi: number;
    fieldPeriod: number;
    cpi: number;
    limit: number;
    geo: Geo;
    bucket: string[];
    title: FeasibilityAttribute;
    industry: FeasibilityAttribute;
    revenue: FeasibilityAttribute;
    size: FeasibilityAttribute;
    level: FeasibilityAttribute;
    quotaPercentMode: boolean;
    quotaItems: QuotaItems[];
}
export interface FeasibilityAttribute {
    exclude: boolean;
    values: string[];
 }

export interface Geo {
    country: string;
    state: string[];
 }

 export interface QuotaItems {
    field: string;
    quotas: QuotaRow[];
 }
 
export interface QuotaRow {
    id: string;
    values: string[];
    quota: number;
 }
