import { AnswerDefault } from './answer-default';
import { AnswerOption } from './answer-option';

export interface QuotaQuestion {
    id?: string;
    question?: string;
    urlIdentifier: '';
    category?: string;
    type?: string;
    description?: string;
    inputType?: string;
    instanceId?: string;
    preferNotToAnswer: false;
    options?: AnswerOption[];
    defaults?: AnswerDefault[];
    order?: number;
}
