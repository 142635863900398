import { Injectable } from '@angular/core';
import { __values } from 'tslib';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/core/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  baseUrl = environment.apiEndpoint + '/support';
  constructor(private http: HttpClient) { }

  createIdea(formData: FormData, summary: string,description:string) {   
    return this.http.post(this.baseUrl + '/jiraissue', formData, { params: { subject: summary,idea:description } });
  }

  
}
