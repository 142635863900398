import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable, forkJoin } from 'rxjs';
import { ProjectResponseIdSettings } from 'core';

@Injectable({
  providedIn: 'root'
})
export class ResponseIdService {

  baseUrl = environment.apiEndpoint + '/responseid/';

  constructor(private http: HttpClient) { }

  UpdateResponseIdSettings(projectId: string, settings: ProjectResponseIdSettings): Observable<any> {
    return this.http.put(this.baseUrl + 'settings', {projectId, ...settings});
  }
  GetResponseIdSettings(projectId: string): Observable<any> {
    return this.http.get(this.baseUrl + projectId + '/settings');
  }

  GetProjectResponseIdBatchList(projectId: string): Observable<any> {
    return this.http.get(this.baseUrl + projectId + '/batchList');
  }
  GetResponseIDLicenseDataV3(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'licensev3');
  }
  ValidateBatch(projectId: string, awsfileName: string,actualFileName: string,url:string): Observable<any> {
    return this.http.post(this.baseUrl + projectId + '/batch/validate/' + awsfileName+'/'+actualFileName+'/'+encodeURIComponent(url), null);
  }

  ProcessBatch(projectId: string, batchId: string, settings: any): Observable<any> {
    return this.http.post(this.baseUrl + 'batch/process/' + batchId, {projectId, ...settings});
  }
  GetBatchForProject(projectId: string): Observable<any> {
    return this.http.get(this.baseUrl + projectId + '/batch');
  }
  GetResponseIDFile(projectId, filename = '',email='',name='',respondentCount=0): Observable<any> {
    return this.http.get(this.baseUrl + projectId + '/export?filename='+filename+'&email='+email+'&name='+name+'&respondentCount='+respondentCount, {responseType: 'text'});
  }

  GetResponseIdBatchFile(projectId, filename = ''): Observable<any> {
    return this.http.get(this.baseUrl + projectId + '/exportBatchFile?filename='+filename, {responseType: 'text'});
  }
  GetProgress(projectId: string): Observable<any> {
    return this.http.get(this.baseUrl + projectId + '/progress');
  }
  GetResponseIdMetrics(projectId: string): Observable<any> {
    return this.http.get(this.baseUrl + projectId + '/metrics');
  }

  GetResponseIdProjectList(): Observable<any> {
    return this.http.get(this.baseUrl + 'projects');
  }
  GetResponseIdSummary(projectId: string): Observable<any> {
    return this.http.get(this.baseUrl + projectId + '/summary');
  }

}
