import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './filter.pipe';
import { MinuteSecondsPipe } from './minuteSeconds.pipe';
import { TruncateStringPipe } from './truncate-string.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { HighlightPipe } from './highlight.pipe';
import { CompareTextPipe } from './compareText.pipe';

@NgModule({
   imports: [
      CommonModule
   ],
   declarations: [
      FilterPipe,
      MinuteSecondsPipe,
      TruncateStringPipe,
      TimeAgoPipe,
      HighlightPipe,
      CompareTextPipe
   ],
   exports: [
      FilterPipe,
      MinuteSecondsPipe,
      TruncateStringPipe,
      TimeAgoPipe,
      HighlightPipe,
      CompareTextPipe
   ]
})
export class HelpersModule { }
