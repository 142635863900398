import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Dropdown, JobsDropdown } from 'core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {
  baseUrl = environment.apiEndpoint + '/DropDown/';

  constructor(private http: HttpClient) { }

  getRoles(): Observable<Dropdown[]> { return this.http.get<Dropdown[]>(this.baseUrl + 'roles', { headers : {cache : '1'}}); }
  getInstances(): Observable<Dropdown[]> { return this.http.get<Dropdown[]>(this.baseUrl + 'instances'); }
  getCleanIDInstances(): Observable<Dropdown[]> { return this.http.get<Dropdown[]>(this.baseUrl + 'cleanidinstances'); }
  projects(filter, id, type ): Observable<Dropdown[]> {
    return this.http.get<Dropdown[]>(this.baseUrl + 'projects?filter=' + filter + '&id=' + id + '&type=' + type);
  }
  getQuotaTemplates(filter, id, type): Observable<Dropdown[]> {
    return this.http.get<Dropdown[]>(this.baseUrl + 'quotaTemplates?filter=' + filter + '&id=' + id + '&type=' + type);
  }
  getImpersonationUsers(): Observable<Dropdown[]> { return this.http.get<Dropdown[]>(this.baseUrl + 'impersonationusers/' ); }

  getCountries(): Observable<Dropdown[]> { return this.http.get<Dropdown[]>(this.baseUrl + 'countries/', { headers : {cache : '1'}} ); }
  getFullCountries(): Observable<Dropdown[]> { return this.http.get<Dropdown[]>(this.baseUrl + 'fullcountries/', { headers : {cache : '1'}} ); }
  getClients(): Observable<Dropdown[]> { return this.http.get<Dropdown[]>(this.baseUrl + 'clients/', { headers : {cache : '1'}} ); }
  getPartners(): Observable<Dropdown[]> { return this.http.get<Dropdown[]>(this.baseUrl + 'partners/', { headers : {cache : '1'}} ); }
  getStates(): Observable<Dropdown[]> { return this.http.get<Dropdown[]>(this.baseUrl + 'states/' , { headers : {cache : '1'}}); }
  getConnections(): Observable<Dropdown[]> { return this.http.get<Dropdown[]>(this.baseUrl + 'connections/' , { headers : {cache : '1'}}); }
  getGlobalPartners(): Observable<Dropdown[]> { return this.http.get<Dropdown[]>(this.baseUrl + 'globalPartners/'); }
}
