import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'or-project-stage',
  templateUrl: './project-stage.component.html',
  styleUrls: ['./project-stage.component.scss']
})
export class ProjectStageComponent implements OnInit {
  @Input() stage: string;
  @Input() color: string;

  @Input() public set percentComplete(data) {
    if (isNaN(data)) return;

    const value = Math.min(100, Math.max(0, data)); //only from 0 to 100%
    this._percentage = Math.round(value);
  }

  _percentage: number = 0;

  constructor() { }

  ngOnInit() {

  }
}
