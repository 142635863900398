import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'or-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss']
})
export class ListFilterComponent implements OnInit {
  @Input() values: any;
  @Output() opened = new EventEmitter();
  @Output() filter = new EventEmitter();
  filterText = '';
  isOpen = false;
  constructor() { }

  ngOnInit() {
  }

  toggleFilter(event) {
    this.isOpen = !this.isOpen;
    if(this.isOpen){
      this.opened.emit();
    }
    event.stopPropagation();
  }
  applyFilter(){
    this.filter.emit(this.values.filter(e => e.selected));
    this.isOpen = false;
  }

}
