import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule, HelpersModule } from 'core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { QuotaQuestionsComponent } from './quota-questions.component';
import { QuotaEditComponent } from './quota-edit/quota-edit.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    RouterModule,
    HelpersModule,
    ReactiveFormsModule
  ],
  declarations: [QuotaQuestionsComponent, QuotaEditComponent]
})
export class QuotaQuestionsModule { }
