import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Client, ClientContact } from 'core';

@Injectable({
  providedIn: 'root'
})
export class ClientContactService {


baseUrl = environment.apiEndpoint + '/client/clientcontact';

constructor(private http: HttpClient) {}

GetClientContacts(clientId: string): Observable<ClientContact[]> {
  return this.http.get<ClientContact[]>(this.baseUrl + '/' + clientId);
}

AddClientContact(model: any): Observable<ClientContact> {
  return this.http.post<ClientContact>(this.baseUrl + '', model);
}

EditClientContact(model: any): Observable<ClientContact> {
  return this.http.put<ClientContact>(this.baseUrl + '/' + model.id, model);
}

DeleteClientContact(model: any) {
  return this.http.delete(this.baseUrl + '/' + model.id);
}
}
