@if (listsettings.appliedFilters.length > 0) {
  <div class="bg-light d-inline-block filters">
    <div class="px-0 py-2">
      @for (filter of listsettings.appliedFilters; track filter) {
        <div class="card bg-light mx-1 d-inline-block mb-2">
          <div class="card-header py-1 px-2">
            <small>{{ filter.label }}</small>
            <i class="far fa-window-close fa-sm cursor-pointer" (click)="removeFilter(filter)"></i>
          </div>
          <div class="card-body py-2 px-2">
            @for (value of filter.values; track value) {
              <span class="badge badge-pill badge-info mr-1">
                {{ value }}
              </span>
            }
          </div>
        </div>
      }
    </div>
  </div>
}
<div class="table-responsive scrollbar">
  <table class="table table-hover table-light table-striped">
    <thead>
      <tr>
        @for (col of layout; track col; let idx = $index) {
          @if (col.type !='actions-dropdown' && col.type!='actions-inline') {
            <th class="nowrap nav-font14 grey5">
              @if (col.filterable) {
                <or-list-filter [values]="col.filterValues" [ngClass]="{'position-relative': (idx+1) === layout?.length, 'last-column': (idx+1) === layout?.length}" (opened)="getFilterValues(col)" (filter)="applyFilter($event, col)"></or-list-filter>
                }&nbsp;
                <div class="d-inline-block" [ngClass]="{'sortable': col.sortable}" (click)="col.sortable > 0 && sortList(col)">
                  {{ col.label }}
                  @if (col.sortable) {
                    <i class="fas fa-sm or-secondary"
                    [ngClass]="{'fa-sort-down': sortBy == col.id && sortDir == 'DESC', 'fa-sort-up': sortBy == col.id && sortDir == 'ASC', 'fa-sort': sortBy != col.id}"></i>
                  }
                </div>
              </th>
            }
            @if (col.type=='actions-dropdown' || col.type=='actions-inline') {
              <th class="align-middle nav-font14 grey5">
                {{showActionColLabel == true ? col.label : "" }}
                <!-- TO DO: SUPPORT MORE TABLE LEVEL ACTIONS-->
                @if (enableExport) {
                  <i class="fas fa-download cursor-pointer or-secondary" (click)="exportTable()"></i>
                }
              </th>
            }
          }
        </tr>
        @if (summary != null) {
          <tr class="table-primary">
            @for (col of layout; track col) {
              <th>
                {{ summary[col.id] }}
              </th>
            }
          </tr>
        }
      </thead>
      <tbody>
        @for (item of displayList; track item; let i = $index) {
          <tr class="grey5 nav-font14" [ngClass]="{'table-info': item?.selected}">
            @for (col of layout; track col) {
              <td [class.selectable]="col.selectable" class="align-middle" (click)="rowSelected(col, item)"
                [ngClass]="'id-list-' + col.label.toLowerCase().replace(' ','')"
                [class.nowrap]="col.type !== null && col.type !== 'text' && col.type !== 'number' && col.type !== 'bool'">
                @if (col.editOnly || (item.editMode != null && item.editMode[col.id] != null)) {
                  @if (col.type == null || col.type == 'text') {
                    @if (col.options == null) {
                      <input type="text" class="form-control" [(ngModel)]="item.editMode[col.id].value" (ngModelChange)="autoSaveChange($event, item, col)">
                    } @else {
                      <select class="custom-select d-block w-100" [ngModel]="item.editMode[col.id].value"
                        (ngModelChange)="autoSaveChange($event, item, col)">
                        @for (option of col.options; track option) {
                          <option [ngValue]="option"> {{ option }}</option>
                        }
                      </select>
                    }
                  }
                  @if (col.type == 'number' || col.type == 'percent' || col.type == 'currency' ) {
                    <input type="text" class="form-control" [(ngModel)]="item.editMode[col.id].value">
                  }
                  @if (col.type == 'users') {
                    @if (isArrayOfObjects(item[col.id])) {
                      <or-type-ahead [labelField]="'name'" [valueField]="'id'" [dataCallback]="getUserList"
                        placeholder="Type to search..."
                        (valueChange)="item.editMode[col.id].value = ($event.length == 0) ? null : $event[0].id"
                      [selectedValues]="item[col.id]"></or-type-ahead>
                    }
                  }
                  @if (col.type == 'date' || col.type == 'countdown') {
                    <div class="input-group mb-1">
                      <input type="text"  autocomplete="off" class="form-control form-control-sm bg-white" readonly #dp="bsDatepicker"
                        [bsValue]="item.editMode[col.id].value" (bsValueChange)="autoSaveChange($event, item, col)"
                        bsDatepicker [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen:true, showWeekNumbers:false, customTodayClass: 'todays-date' }">
                        <div class="input-group-append">
                          <button class="btn btn-light border" type="button" (click)="dp.toggle()"><i
                          class="far fa-calendar-alt"></i></button>
                        </div>
                      </div>
                    }
                    <div class="mt-1 float-right">
                      @if (col.editable && !(col.editOnly == true)) {
                        <button (click)="item.editMode[col.id] = null" type="button"
                          class="btn btn-outline-secondary mx-1 py-2">
                          <i class="fa fa-times fa-xs"></i>
                        </button>
                      }
                      @if (col.editable && (col.autoSave == null || col.autoSave == false)) {
                        <button
                          (click)="saveChange(item, col)" type="button" class="btn btn-outline-secondary">
                          <i class="fa fa-check fa-xs"></i>
                        </button>
                      }
                    </div>
                  }
                  @if (!(col.editOnly == true) && (item.editMode == null || item.editMode[col.id] == null)) {
                    @if (col.editable && col.type != 'status') {
                      <span tooltip="Edit" (click)="enableEdit(item, col)">
                        <i class="fas fa-edit cursor-pointer or-secondary"></i>&nbsp;
                      </span>
                    }
                    @if (col.type == 'stage') {
                      <or-project-stage [stage]="item['currentStage']" [color]="item['currentStageColor']" [percentComplete]="(item?.stageCompleteTaskCount / item?.stageTotalTaskCount)*100"></or-project-stage>
                    }
                    @if (col.type == null || col.type == 'text' || col.type == 'number' || col.type == 'bool') {
                      @if (col.selectable && clickroute!='') {
                        <a class="nav-accent-blue font-weight-bold nav-link p-0" [routerLink]="[clickroute, item[rowid]]" [ngClass]="{'text-truncate': col.width > 0}" [style.width.px]="col.width">
                          {{ item[col.id] }}
                        </a>
                      }
                      @if (col.selectable && clickroute=='' && item.fileName != 'Rollback') {
                        <a class="nav-accent-blue font-weight-bold nav-link p-0" [ngClass]="{'text-truncate': col.width > 0}" [style.width.px]="col.width">
                          {{ item[col.id] }}
                        </a>
                      }
                      @if (col.selectable && clickroute=='' && item.fileName == 'Rollback') {
                        <a class="nav-accent-blue font-weight-bold p-0 noFile" [ngClass]="{'text-truncate': col.width > 0}" [style.width.px]="col.width">
                          {{ item[col.id] }}
                        </a>
                      }
                      @if (!col.selectable) {
                        <div [ngClass]="{'text-truncate': col.width > 0}" [style.width.px]="col.width" >
                          @if (col.type == 'number') {
                            {{ item[col.id] | number }}
                          }
                          @if (col.type != 'number') {
                            {{ item[col.id] }}
                          }
                        </div>
                      }
                    }
                    @if (col.type == 'percent') {
                      <div [ngClass]="{'text-truncate': col.width > 0}" [style.width.px]="col.width" >
                        {{ item[col.id] | number }} %
                      </div>
                    }
                    @if (col.type == 'currency') {
                      <div [ngClass]="{'text-truncate': col.width > 0}" [style.width.px]="col.width" >
                        {{ item[col.id] | currency }}
                      </div>
                    }
                    @if (col.type == 'cleanID') {
                      {{ cleanIDImplementation(item, col) }}
                    }
                    @if (col.type == 'icon') {
                      <i class="cursor-default" [ngClass]="item[col.id]"></i>
                    }
                    @if (col.type == 'status') {
                      <div class="or-dropdown-status id-list-status">
                        @if (col.editable) {
                          <or-dropdown-status [status]="item[col.id]" [projectId]="item.id"></or-dropdown-status>
                        }
                      </div>
                      @if (!col.editable ) {
                        <span [ngClass]="{'text-truncate': col.width > 0}" [style.width.px]="col.width">{{item[col.id]}}</span>
                      }
                    }
                    @if (col.type == 'link') {
                      <a target="_blank" href="http://{{item[col.id]}}">{{item[col.id]}}</a>
                    }
                    @if (col.type == 'email') {
                      <or-email-address [emailaddress]="item[col.id]"></or-email-address>
                    }
                    @if (col.type == 'img') {
                      <or-profile-image [url]="item[col.id]" [name]="item.name" size="small" showInitials="true"></or-profile-image>
                    }
                    @if (col.type == 'users') {
                      <div class="d-inline-flex ml-2 position-relative users">
                        @if (isArrayOfObjects(item[col.id])) {
                          @for (user of item[col.id]; track user) {
                            <span tooltip="{{ user.name }}" container="body">
                              <or-profile-image [name]="user.name" size="small" showInitials="true"></or-profile-image>
                            </span>
                          }
                        } @else {
                          <span tooltip="{{ item[col.id] }}" container="body">
                            <or-profile-image [name]="item[col.id]" size="small" showInitials="true"></or-profile-image>
                          </span>
                        }
                      </div>
                    }
                    @if (col.type == 'address') {
                      <or-address [address]="item[col.id]"></or-address>
                    }
                    @if (col.type == 'countdown') {
                      <span>{{ getCountdownString(item[col.id]) }} </span>
                      <div><small>{{ item[col.id] | date: 'MMM d, y' }}</small></div>
                    }
                    @if (col.type == 'date') {
                      {{ item[col.id] | date: 'MMM d, y' }}
                    }
                    @if (col.type == 'datetime') {
                      {{ item[col.id] | date: 'MMM d, y HH:mm' }}
                    }
                    @if (col.type == 'tag') {
                      @if (item[col.id].mode == null || item[col.id].mode == 'dot') {
                        <span
                          [ngClass]="'badge-' + item[col.id].type || 'info'" class="badge badge-pill p-2">
                          {{ item[col.id].label }}
                        </span>
                      }
                      @if (item[col.id].mode == 'badge') {
                        <span>
                          <div [ngClass]="'bg-' + item[col.id].type || 'info'" class="rounded-circle dot float-left mt-1"></div>
                          <small>&nbsp;{{ item[col.id].label }}</small>
                        </span>
                      }
                    }
                    @if (col.type == 'tags') {
                      <div class="row tags scrollbar overflow-y">
                        @for (tag of item[col.id]; track tag) {
                          <div class="badge badge-pill badge-light text-uppercase m-1 or-primary-bg text-white">
                            {{ tag.tag }}
                          </div>
                        }
                      </div>
                    }
                    @if (col.type == 'toggle') {
                      <or-toggle-button [id]="item.Id" [isChecked]="item[col.id]" [isDisabled]="true" msgON="YES" msgOFF="NO">
                      </or-toggle-button>
                    }
                    @if (col.type == 'actions-dropdown' && actions && auth.isInternal()) {
                      <div class="btn-group btn-group-sm id-list-actions" dropdown container="body">
                        <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-secondary" controls="dropdown-basic">
                          <span><i class="fa fa-ellipsis-h"></i></span>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                          @for (action of actions; track action) {
                            <li role="menuitem">
                              <or-action-button [label]="action.label" [description]="action.description" [icon]="action.icon" (selected)="actionSelected(item, action.type)"></or-action-button>
                            </li>
                          }
                        </ul>
                      </div>
                    }
                    @if (col.type == 'alert') {
                      @for (alert of item[col.id+'_icons']; track alert) {
                        <span container="body"  tooltip="{{ alert.message }}" class="mr-1">
                          <i [ngClass]="alert.icon"></i>
                        </span>
                      }
                    }
                    @if (col.type == 'health') {
                      @for (alert of item[col.id+'_icons']; track alert) {
                        <span html="true" tooltip="{{ alert.tooltip }}" container="body" class="health badge badge-pill {{ alert.status }} mr-1 p-2">
                          {{ alert.message }}
                        </span>
                      }
                    }
                    @if (col.type == 'actions-inline'  && actions) {
                      <div class="btn-group btn-group-sm">
                        @for (action of actions; track action) {
                          <button type="button" (click)="actionSelected(item, action.type)"
                            class="btn btn-outline-secondary" tooltip="{{ action.label }}">
                            <i [ngClass]="'fa fa-' + action.icon + ' or-secondary'"></i>
                          </button>
                        }
                      </div>
                    }
                    @if (col.type == 'actions-checkbox'  && actions) {
                      <div class="btn-group btn-group-sm">
                        @for (action of actions; track action) {
                          <input   class="btn btn-outline-secondary" tooltip="{{ action.label }}" (change)="actionSelected(item, action.type, $event)" type="checkbox">
                        }
                      </div>
                    }
                  }
                </td>
              }
            </tr>
          }
        </tbody>
      </table>
      @if (itemsToLoad < itemCount) {
        <button class="nav-btn-primary shadow-none w-100 mb-3 mt-3" (click)="loadMore()">Load More</button>
      }

    </div>
