<div class="d-flex mb-3 mt-1 border-bottom">
  <div class="text-truncate or-secondary h4 m-2"> Dashboard Manager </div>
</div>

<div class="card">
  <div class="card-header or-primary-bg text-white">
    <div>Dashboard Widgets</div>
  </div>
  <div class="card-body">
    <div class="mt-0">
      <div class="row mx-5">
        <div class="col mx-5">
          <div class="table-responsive">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th class="nowrap">Group</th>
                  <th class="nowrap">Widget</th>
                  <th class="nowrap">Role</th>
                </tr>
              </thead>
              <tbody>
                @for (widget of widgets; track widget; let idxwidget = $index) {
                  <tr>
                    <td class="align-middle nowrap bg-white">{{widget.groupName}}</td>
                    <td class="align-middle nowrap bg-white">{{widget.title}}</td>
                    <td class="align-middle nowrap">
                      <or-type-ahead
                        [selectedValues]="widget.roles === null ? [] : widget.roles"
                        [labelField]="'name'"
                        [valueField]="'id'"
                        multi=true
                        [dataCallback]="getRoleList"
                        placeholder="Select a Role"
                        (valueChange)="updateRole($event, widget)">
                      </or-type-ahead>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer text-right">
    <button class="btn or-primary-bg text-white" (click)="saveWidgetRoles()">Save</button>
  </div>
</div>