@if (!text) {
  <small class="cursor-pointer text-nowrap" (click)="toggleDropdown($event)" [class.clickable]="addClickableClass">
    {{ getCurrent() }} @if (showBtn === true) {
    <i class="fas fa-chevron-circle-down ml"></i>
  }
</small>
}

@if (text) {
  <span class="cursor-pointer text-nowrap" (click)="toggleDropdown($event)" [class.clickable]="addClickableClass">
    {{ text }} @if (showBtn === true) {
    <i class="fas fa-chevron-circle-down ml"></i>
  }
</span>
}

<div class="btn-group d-flex" dropdown #dropdown="bs-dropdown" [class.clickable]="addClickableClass">
  <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
    <div class="d-block text-right px-2" (click)="toggleDropdown($event)">
      <i class="far fa-window-close fa-sm cursor-pointer"></i>
    </div>

    @for (value of values; track value) {
      <li role="menuitem" class="expandable" >
        <a class="dropdown-item cursor-pointer" (click)="applyValue($event, value)">{{ value.name }}</a>
      </li>
    }
  </ul>
</div>


