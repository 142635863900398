import { v4 as uuidv4 } from 'uuid';

export interface Note {
    id: uuidv4;
    projectName: string;
    projectCode?: string;
    projectId?: string;
    userId?: string;
    name?: string;
    img?: File;
    message: string;
    isExternalEmail?: Boolean;
    externalEmailFrom: string;
    cDate: Date;
    uDate: Date;
    tags?: Tags[];
}

export interface Tags {
    id: uuidv4;
    tag: string;
}
