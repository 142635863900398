import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'or-view-toggle',
  templateUrl: './view-toggle.component.html',
  styleUrls: ['./view-toggle.component.scss']
})
export class ViewToggleComponent implements OnInit {
  @Input() showList = false;
  @Input() showCardGroup = false;
  @Input() showCard = false;
  @Input() showGantt = false;
  @Input() showCalendar = false;
  @Input() showPercent = false;
  @Input() showNumber = false;
  @Input() class = '';
  @Input() active = '';

  quotas = null;

  @Output() valueChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
    if (this.showNumber && this.showPercent) {
      this.quotas = 'quotas';
    }
  }

  switchMode(mode) {
    this.active = mode;
    this.valueChange.emit(mode);
  }

}
