import { Component, OnInit } from '@angular/core';
import { Partner, AlertifyService, PartnerService, DropdownService, UtilsService, AuthService, Webhook } from 'core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { SurveyEngineService } from 'projects/core/src/services/survey-engine.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-partner-edit',
  templateUrl: './partner-edit.component.html',
  styleUrls: ['./partner-edit.component.scss']
})
export class PartnerEditComponent implements OnInit {
  model: Partner;
  hooks: Webhook[];
  states: any[];
  submitted = false;
  partnerForm: FormGroup;
  connections: any[];
  tab = 'main-details';
  public vendorId;
  partnersGlobal: any[];
  selectedPartner: any;
  cloneid: string;

  constructor(
    private alertify: AlertifyService,
    private partnerService: PartnerService,
    private surveyEngineService: SurveyEngineService,
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    private dd: DropdownService,
    public utils: UtilsService) { }

  ngOnInit() {
    this.dd.getStates().subscribe( data => {
      this.states = data;
    }, error => {
       this.alertify.error('Unable to get states');
    }, () => {
    });

    this.route.paramMap.subscribe(params => {
      const id: string = params.get('id');
      this.cloneid = params.get('cloneid');
      this.vendorId = id;

      if (id) {
        this.loadData(id);
      } else if (this.cloneid) {
        this.loadData(this.cloneid);
      }else if (history.state.data != null) {
        this.model = history.state.data;
        this.bindForm();
      } else {
        this.router.navigate(['/vendors']);
      }
    });
  }

  submitForm() {
    this.submitted = true;

    if (!this.partnerForm.valid) { return; }
    if (this.partnerForm.value.instanceId === null) this.partnerForm.value.instanceId = this.auth.getInstance();
    if (this.cloneid) {
      this.partnerForm.value.id = null;
    }
    if (!this.partnerForm.value.id) {
      this.partnerService.AddPartner(this.partnerForm.value).subscribe(data => {
        this.model = data;
        this.saveWebhooks();
      }, error => {
         this.alertify.error('Unable to add vendor');
      }, () => {
        this.alertify.success('Vendor added successfully');
        if (this.tab === "webhooks") {
          this.loadData(this.vendorId)
          return;
        }
        this.router.navigate(['/vendors']);
      });
    } else {
      this.partnerService.EditPartner(this.partnerForm.value, true).subscribe(data => {
        this.model = data;
        this.saveWebhooks();
      }, error => {
         this.alertify.error('Unable to edit vendor');
      }, () => {
        this.alertify.success('Vendor edited successfully');
        if (this.tab === "webhooks") {
          this.loadData(this.vendorId)
          return;
        }
        this.router.navigate(['/vendors']);
      });
    }
  }
  saveWebhooks() {
    let hookId = this.partnerForm.value.webhookId ?? uuidv4();
    let hook = {
      id: hookId,
      instanceId: this.model.instanceId,
      vendorId: this.vendorId,
      hookEvent: this.partnerForm.value.hookEvent,
      method: this.partnerForm.value.method,
      endpoint: this.partnerForm.value.endpoint,
      urlParams: this.partnerForm.value.urlParams,
      headers: this.partnerForm.value.headers,
      body: this.partnerForm.value.body
    };
    if (hook.hookEvent === null || hook.method === null || hook.endpoint === null) return;

    if (this.partnerForm.value.webhookId === null) this.hooks.push(hook);
    else {
      let index = this.hooks.findIndex(x => x.id === hook.id);
      if (index === undefined || index === null) this.hooks.push(hook);
      else this.hooks[index] = hook;
    }

    this.surveyEngineService.saveWebhooks(this.vendorId, this.hooks).subscribe(data => {
    }, error => {
       this.alertify.error('Unable to save webhook');
    });
  }

  loadData(id: any) {
    forkJoin([
      this.partnerService.GetPartner(id, true),
      this.surveyEngineService.getWebhooks(id),
      this.dd.getGlobalPartners()
    ]).subscribe((responseList) => {
      this.model = responseList[0];
      this.hooks = responseList[1];
      this.partnersGlobal = responseList[2];
      this.selectedPartner = responseList[2].find(x => x.value.toLowerCase() === this.model.globalPartner.toLowerCase())?.value;
    }, error => {
      this.alertify.error('Unable to get vendor');
    }, () => {
      this.bindForm();
    });
  }

  bindForm() {
    if (this.model) {
      this.partnerForm = new FormGroup({
        id: new FormControl(this.model.id, [Validators.required]),
        name: new FormControl(this.model.name, [Validators.required]),
        vendorType: new FormControl(this.model.vendorType),
        website: new FormControl(this.model.website),
        img: new FormControl(this.model.img),
        instanceId: new FormControl(this.model.instanceId),
        linkSecurity: new FormControl(this.model.linkSecurity),
        userDomain: new FormControl(this.model.userDomain),
        connectionId: new FormControl(this.model.connectionId),
        address1: new FormControl(this.model.address.address1),
        address2: new FormControl(this.model.address.address2),
        city: new FormControl(this.model.address.city),
        country: new FormControl(this.model.address.country),
        state: new FormControl(this.model.address.state),
        zip: new FormControl(this.model.address.zip),
        facebook: new FormControl(this.model.social.facebook),
        linkedIn: new FormControl(this.model.social.linkedIn),
        twitter: new FormControl(this.model.social.twitter),
        skype: new FormControl(this.model.social.skype),
        completeRedirectURL: new FormControl(this.model.completeRedirectURL),
        terminateRedirectURL: new FormControl(this.model.terminateRedirectURL),
        overQuotaRedirectURL: new FormControl(this.model.overQuotaRedirectURL),
        qcRedirectURL: new FormControl(this.model.qcRedirectURL),
        globalPartner: new FormControl(this.selectedPartner),
        isGlobal: new FormControl(this.model.isGlobal),
        isPrimaryGlobalVendor: new FormControl(this.model.isPrimaryGlobalVendor),
        // webhooks
        webhookId: new FormControl(),
        hookEvent: new FormControl(""),
        method: new FormControl(""),
        endpoint: new FormControl(),
        urlParams: new FormControl(),
        headers: new FormControl(),
        body: new FormControl()
      });
    }
  }

  clonePartner(event) {
    let partnerId = event.target.value;
    this.partnerService.GetPartner(partnerId, true).subscribe(data => {
      this.selectedPartner = data;

      this.partnerForm = new FormGroup({
        name: new FormControl(this.selectedPartner.name + ' [clone]', [Validators.required]),
        description: new FormControl(this.selectedPartner.description),
        vendorType: new FormControl(this.selectedPartner.vendorType),
        website: new FormControl(this.selectedPartner.website),
        img: new FormControl(this.selectedPartner.img),
        instanceId: new FormControl(this.auth.getInstance()),
        connectionId: new FormControl(this.selectedPartner.connectionId),
        userDomain: new FormControl(this.selectedPartner.userDomain),
        address1: new FormControl(this.selectedPartner.address.address1),
        address2: new FormControl(this.selectedPartner.address.address2),
        city: new FormControl(this.selectedPartner.address.city),
        country: new FormControl(this.selectedPartner.address.country),
        state: new FormControl(this.selectedPartner.address.state),
        zip: new FormControl(this.selectedPartner.address.zip),
        facebook: new FormControl(this.selectedPartner.social.facebook),
        linkedIn: new FormControl(this.selectedPartner.social.linkedIn),
        twitter: new FormControl(this.selectedPartner.social.twitter),
        skype: new FormControl(this.selectedPartner.social.skype),
        completeRedirectURL: new FormControl(this.selectedPartner.completeRedirectURL),
        terminateRedirectURL: new FormControl(this.selectedPartner.terminateRedirectURL),
        overQuotaRedirectURL: new FormControl(this.selectedPartner.overQuotaRedirectURL),
        qcRedirectURL: new FormControl(this.selectedPartner.qcRedirectURL),
        globalPartner: new FormControl(partnerId),
        isGlobal: new FormControl(false),
        isPrimaryGlobalVendor: new FormControl(this.selectedPartner.isPrimaryGlobalVendor)
      });
    }, error => {
       this.alertify.error('Unable to get global vendor');
    });
  }
}
