import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges, OnChanges, HostListener } from '@angular/core';

@Component({
  selector: 'or-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss']
})
export class DropdownListComponent implements OnInit, OnChanges {

  @Input() values = [];
  @Input() value: string;
  @Input() text: string = '';
  @Input() search: boolean = false;
  @Input() noSelectionText: string = 'Select an option';
  @Input() noSearchText: string = 'Search';
  @Input() createText: string = 'Add new';
  @Input() showWhenClosed: boolean = false;
  @Input() searchFilter: string = null;
  @Input() createEnabled = false;
  @Input() container = 'body';

  @Output() selected = new EventEmitter();
  @Output() created = new EventEmitter();
  @Output() filter = new EventEmitter();

  @ViewChild('dropdown', {static: true}) dropdown;
  @ViewChild('search') searchElement;

  dropdownOpen = false;
  current = null;
  showList = false;
  createMode = false;
  newValue = '';

  constructor() { }

  ngOnInit() {
    this.dropdown.toggle(true);
    if (this.showWhenClosed) this.showList = false;
    if (this.search) {
      setTimeout(()=>{ // this will make the execution after the above boolean has changed
        this.searchElement.nativeElement.focus();
      },0);
    }
  }

  toggleDropdown() {
    if((this.values == null || this.values.length ==0) && !this.createEnabled) return '';
    if (this.value != null) {
      this.values = this.values.filter(e => e.id != this.value);
    }

    if (this.showWhenClosed) {
      this.showList = !this.showList;
    }

    if (!this.showList) {
      this.newValue = '';
      this.createMode = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.values == null || this.values.length ==0) return '';
    if (this.value != null) {
      const selected = this.values.filter(e=> e.id == this.value);
      this.values = this.values.filter(e => e.id != this.value);
      this.current = selected[0];
    }
  }

  applyValue(event, value) {
    if (value != this.current) {
      value.origValue = this.value;
      if (this.current) {
        this.values.push(this.current)
      }
      this.value = value.value;
      this.selected.emit(value);
    }
    if (!this.showWhenClosed) this.dropdown.toggle(true);
    else {
      this.toggleDropdown();
    }

  }

  getValues(event) {
    this.filter.emit(event);
  }

  createNewValue(event) {
    this.created.emit(this.newValue);
    this.endCreate();
  }

  endCreate() {
    this.createMode = false;
    this.newValue = '';
  }


  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    // check if isn't in edit mode and complete checkbox wasn't clicked
      // Check if the click target is not one of the dynamically created divs
      if (this.showWhenClosed && this.showList && !this.isCLickInsideDropdown(event)) {
        this.toggleDropdown();
        // this.endInline();
      }
  }

  private isCLickInsideDropdown(event: MouseEvent): boolean {
    // Check if the click target is inside any of the divs
    const htmlElement = event?.target as HTMLElement
    if (htmlElement.classList.contains("dropdown")) {
      return true
    }
    else if (htmlElement.classList.contains("dropdown-list")) {
      return true
    }
    else if (htmlElement.classList.contains("dropdown-list-item")) {
      return true
    }
    else if (htmlElement.classList.contains("dropdown-input")) {
      return true
    }
    return false;
  }
}


