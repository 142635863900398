import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {  DropdownService } from '../../services/dropdown.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'or-dedupe-status-setup',
  templateUrl: './dedupe-status-setup.component.html',
  styleUrls: ['./dedupe-status-setup.component.scss']
})
export class DedupeStatusSetupComponent implements OnInit {
  _depudeList;

  @Input()  public set dedupeList(data) {
    this._depudeList = data.filter((e, index) => (index === 0 || e.tag !== '' || e.otherId != null))
    this.addOtherDedupeRow();
  };

  @Input() projectId;
  @Output() listChange = new EventEmitter();

  constructor(private dropdownService: DropdownService,) { }

  ngOnInit() {
  }

  addOtherDedupeRow() {
    const row = {
      id: uuidv4(),
      projectId: this.projectId,
      tag: '',
      otherId: null,
      otherName: '',
      start: false,
      complete: false,
      oq: false,
      qc: false,
      terminate: false
    };
    this._depudeList.push(row);
  }
  updateDedupeOtherId(selected, dedupe){
    dedupe.otherId = (selected.length === 0) ? null : selected[0].value;
    dedupe.otherName = (selected.length === 0) ? '' : selected[0].name;
  }

  removeOtherDedupeRow(index) {
    this._depudeList.splice(index, 1);
    this.handleChange();
  }
  getProjectList = (filter) => {
    return this.dropdownService.projects(filter, this.projectId, 'survey');
  }

  handleChange() {
    this.listChange.emit(this._depudeList.filter((e, index) => (index === 0 || e.tag !== '' || e.otherId != null)));
  }
}
