import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'or-brand-card',
  templateUrl: './brand-card.component.html',
  styleUrls: ['./brand-card.component.scss']
})
export class BrandCardComponent implements OnInit {
  @Input() item: any;
  @Output() selected = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  cardSelected() {
    this.selected.emit(this.item);
  }
}
