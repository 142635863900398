<div class="modal-header">
  <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">{{!model.id ? 'Add New CPI Rate Card ' : (model.id && editMode) ? 'Edit ' : '' }}{{model.name}}</h2>
  <div class="d-flex justify-content-end">
    <i class="fas fa-close grey4" (click)="handleClose(true)"></i>
  </div>
</div>
<div class="modal-body nav-font14 scrollbar overflow-y pb-3">
  <form [formGroup]="getForm()" novalidate>
    @if (!model.id || (model.id && editMode)) {
      <div class="row align-items-center mb-3">
        <div class="col-3 grey4">
          <label class="required">Rate Card Name</label>
        </div>
        <div class="col">
          <input type="text" class="form-control form-control-sm form-control-nav" placeholder="Rate Card Name" formControlName="name" id="name"
          [ngClass]="submitted && utils.showCssValidField(partnerRateCardForm, 'name')" />
        </div>
      </div>
      <div class="row align-items-center mb-3">
        <div class="col-3 grey4">Default CPI<i class="fal fa-info-circle cursor-default grey4 nav-font14 mx-2" placement="bottom" tooltip=""></i></div>
        <div class="col">
          <input type="text" mask="separator.2" class="form-control form-control-sm form-control-nav" placeholder="Default CPI" formControlName="defaultCPI" id="defaultCPI" />
        </div>
      </div>
      <div class="row align-items-center mb-4">
        <div class="col-3 grey4 pr-0">Enable rate card<i class="fal fa-info-circle cursor-default grey4 nav-font14 mx-2" placement="bottom" tooltip=""></i></div>
        <div class="col d-flex align-items-center">
           <or-toggle-button id="enabled" name="enabled" size="small" formControlName="enabled" class="light"></or-toggle-button>
        </div>
      </div>
    }
    @else {
      @if (model.name.length < 13) {
        <div class="row align-items-center mb-3 gridview" [class.viewonly]="!partnerRateCardForm">
          <div class="grey5">
            <span class="font-weight-bold ml-2 pl-1">Rate Card Name:</span>
            <span class="ml-2">{{model.name}}</span>
          </div>
          <div class="grey5 text-center">
            <span class="font-weight-bold">Default CPI</span>
            <i class="fak fa-info grey4 fa-md mx-2"></i>
            <span class="ml-2">{{model.defaultCPI}}</span>
          </div>
          @if(partnerRateCardForm) {
            <div class="grey5 d-flex align-items-center justify-content-center">
              <span class="font-weight-bold">Enable rate card</span>
              <i class="fak fa-info grey4 fa-md mx-2"></i>
                <or-toggle-button size="small" [(ngModel)]="model.enabled" [ngModelOptions]="{standalone: true}" (changed)="toggleEnable($event)" class="light"></or-toggle-button>
            </div>
          }
        </div>
      } @else {
        <div class="row align-items-center mb-3">
          <div class="col grey5">
            <span class="font-weight-bold">Rate Card Name:</span>
            <span class="ml-2">{{model.name}}</span>
          </div>
        </div>
        <div class="row align-items-center mb-3">
          <div class="col-4 grey5 pr-0">
            <span class="font-weight-bold">Default CPI</span>
            <i class="fak fa-info grey4 fa-md mx-2"></i>
            <span class="ml-2">{{model.defaultCPI}}</span>
          </div>
          @if(partnerRateCardForm) {
            <div class="col grey5 d-flex align-items-center px-0">
              <span class="font-weight-bold">Enable rate card</span>
              <i class="fak fa-info grey4 fa-md mx-2"></i>
                <or-toggle-button size="small" [(ngModel)]="model.enabled" [ngModelOptions]="{standalone: true}" (changed)="toggleEnable($event)" class="light"></or-toggle-button>
            </div>
          }
        </div>
      }
    }

    <div class="row align-items-start mb-5">
      <div class="col">
        <or-vendor-cpi-grid [data]="model.rateTable" [viewonly]="(model.id && !editMode)" (dataChange)="rateTableChange($event)"></or-vendor-cpi-grid>
      </div>
    </div>

    <div class="col text-right pr-0">
      <button type="button"
              class="btn mx-2 shadow-none font-weight-bold nav-font14"
              [ngClass]="!partnerRateCardForm ? 'nav-btn-accent' : 'nav-btn-primary'"
              (click)="handleClose()">
        {{editMode ? 'Cancel' : 'Close'}}
      </button>

      @if(partnerRateCardForm) {
        <button type="button" [disabled]="!partnerRateCardForm.valid" [ngClass]="partnerRateCardForm.valid ? 'nav-btn-accent' : 'nav-btn-disabled'"
          class="btn shadow-none font-weight-bold nav-font14" (click)="handleSave()">
          {{!model.id || (model.id && editMode)  ? 'Save ' : 'Edit'}}
        </button>
      }
    </div>
  </form>
</div>


