import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CleanidService {

  baseUrl = environment.apiEndpoint + '/cleanid/';

  constructor(private http: HttpClient) { }

  GetCleanIDLicenseData(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'license');
  }
  GetCleanIDLicenseDataV3(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'licensev3');
  }

  GetCleanIDThresholds(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'thresholds', {
      headers : {cache : '1' }
    });
  }

  GetCleanIDSettings(instanceId: string) {
    // Gets the current settings and the predefined profiles
    const response1 = this.http.get<any>(this.baseUrl + 'settings?instanceId=' + instanceId);
    const response2 = this.http.get<any>(this.baseUrl + 'profiles', { headers : {cache : '1' }});
    return forkJoin([response1, response2]);
  }

  SaveCleanIDSettings(model: any) {
    return this.http.put<any>(this.baseUrl + 'settings', model);
  }

  GetCleanIDMarkerDataFromNav(projectId, thresholdOn, partnerId) {
    return this.http.get<any>(this.baseUrl + 'markers/' + projectId + '?thresholdOn=' + thresholdOn + '&partnerId=' + partnerId );
  }

  GetDataQualityReport(projectId): Promise<any> {
    return this.http.get(this.baseUrl + projectId + '/report', {responseType: 'text'}).toPromise();
  }
  GetDataQualityReportByBidNumber(bidNumber): Promise<any> {
    return this.http.get(this.baseUrl + 'family/' + bidNumber + '/report', {responseType: 'text'}).toPromise();
  }

  

}
