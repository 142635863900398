import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'or-error-display',
  templateUrl: './error-display.component.html',
  styleUrls: ['./error-display.component.scss']
})
export class ErrorDisplayComponent implements OnInit {

  @Input() showFeedbackRequired: boolean;
  @Input() showFeedbackMinlenght: boolean;
  @Input() showFeedbackEmail: boolean;

  @Input() invalidFeedbackRequired: string;
  @Input() invalidFeedbackMinlenght: string;
  @Input() invalidFeedbackEmail: string;

  ngOnInit(): void {

  }

}
