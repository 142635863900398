<form [formGroup]="partnerForm" (ngSubmit)="partnerAdd()" novalidate>
  <div class="card m-3">
    <div class="card-header text-white or-primary-bg">
      <p class="h4 inline"><em class="fa fa-project-diagram fa-sm"></em> New Vendor Details</p>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3 col-sm-12">
          <label for="name" class="required">Name</label>
          <input type="text" class="form-control" formControlName="name" id="name" [ngClass]="submitted && utils.showCssValidField(partnerForm, 'name')" />
        </div>
        <div class="col-md-3 col-sm-12">
          <label for="website">Website</label>
          <input type="website" class="form-control" formControlName="website" id="website" placeholder="www.example.com" />
        </div>
        <div class="col-md-3 col-sm-12">
          <label for="website">Type</label>
          <select class="form-control or-dropdown"  formControlName="vendorType" id="vendorType">
              <option value="Unclassified">Unclassified</option>
              <option value="Expert Network">Expert Network</option>
              <option value="Mid-Level Traditional">Mid-Level Traditional</option>
              <option value="Programmatic">Programmatic</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-sm-12">
          <span tooltip="If you would like to restrict vendor users to particular email domains when being added by Vendor Admins">
            <i class="fas fa-info-circle opacity-20 or-secondary"></i>
          </span>
          <label for="userDomain" >Vendor User domain</label>
          <input type="text" class="form-control" formControlName="userDomain" id="userDomain" />
        </div>
        @if (!isGlobal && partnersGlobal.length > 0) {
          <div class="col-md-3 col-sm-12">
            <label for="globalPartner">Global Vendors</label>
            <div class="justify-content-center or-dropdown">
              <select class="form-control or-dropdown" (change)="clonePartner($event)">
                @for (p of partnersGlobal; track p) {
                  <option [value]="p.value">{{p.name}}</option>
                }
              </select>
            </div>
          </div>
        }
        @if (auth.isSuperAdmin() && isGlobal) {
          <div class="col-md-3 col-sm-12">
            <label for="isPrimaryGlobalVendor" >Primary Global Vendor</label>
            <or-toggle-button msgON="On" msgOFF="Off" formControlName="isPrimaryGlobalVendor" class="light"></or-toggle-button>
          </div>
        }
      </div>
      <div class="row m-3">
        <div class="col-12">
          <label for="img">Profile <span class="text-muted"> (512 x 512 PNG or JPEG)</span></label>
        </div>
        <div class="col-md-12 col-lg-6">
          <or-upload [smallMsg]="'512 x 512 PNG or JPG'" [showPreviewImg]=true [directToS3]=true [acceptImages]=true formControlName="img" id="img" ></or-upload>
        </div>
      </div>
      <h5>Redirects <small class="text-muted">(Optional)</small></h5>
      <div class="row">
        <div class="col-6">
          <label for="completeRedirectURL">CompleteRedirectURL</label>
          <input type="text" class="form-control" formControlName="completeRedirectURL" id="completeRedirectURL" placeholder="http://example.com/c" />
        </div>
        <div class="col-6">
          <label for="terminateRedirectURL">TerminateRedirectURL</label>
          <input type="text" class="form-control" formControlName="terminateRedirectURL" id="terminateRedirectURL" placeholder="http://example.com/t" />
        </div>
        <div class="col-6">
          <label for="overQuotaRedirectURL">OverQuotaRedirectURL</label>
          <input type="text" class="form-control" formControlName="overQuotaRedirectURL" id="overQuotaRedirectURL" placeholder="http://example.com/oq" />
        </div>
        <div class="col-6">
          <label for="qcRedirectURL">QCRedirectURL</label>
          <input type="text" class="form-control" formControlName="qcRedirectURL" id="qcRedirectURL" placeholder="http://example.com/qc" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6 col-sm-12">
          <h5>Address <small class="text-muted">(Optional)</small></h5>
          <div>
            <label for="address">Address Line 1</label>
            <input type="text" class="form-control" formControlName="address1" id="address1" placeholder="1234 Main St">
            <div class="invalid-feedback">Please enter your address.</div>
          </div>
          <div>
            <label for="address2">Address Line 2 </label>
            <input type="text" class="form-control"  formControlName="address2" id="address2" placeholder="Apartment or suite">
          </div>
          <div>
            <label for="country">City</label>
            <input type="text" class="form-control" id="city" formControlName="city" placeholder="">
          </div>
          <div>
            <label for="state">State/Province</label>
            <input type="text" class="form-control" id="state" formControlName="state" placeholder="">
          </div>
          <div>
            <label for="country">Country</label>
            <input type="text" class="form-control" id="country" formControlName="country" placeholder="">
          </div>
          <div>
            <label for="zip">Zip/Post Code</label>
            <input type="text" class="form-control" id="zip" formControlName="zip" placeholder="">
            <div class="invalid-feedback">
              Zip code required.
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <h5>Social <small class="text-muted">(Optional)</small></h5>
          <div>
            <label for="facebook" >Facebook</label>
            <input type="text" class="form-control"  formControlName="facebook" id="facebook" placeholder="http://facebook.com/example" >
          </div>
          <div>
            <label for="linkedIn">LinkedIn</label>
            <input type="text" class="form-control"  formControlName="linkedIn" id="linkedIn" placeholder="http://linkedIn.com/example" >
          </div>
          <div>
            <label for="twitter">Twitter </label>
            <input type="text" class="form-control" formControlName="twitter" id="twitter" placeholder="http://twitter.com/example" >
          </div>
          <div>
            <label for="skype">Skype </label>
            <input type="text" class="form-control" formControlName="skype" id="skype" placeholder="Username" >
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-right mt-3">
      <div class="btn btn-light mr-2" [routerLink]="['/vendors']"  type="button">Cancel</div>
      <button class="btn or-primary-bg text-white" type="submit">Save</button>
    </div>
  </div>
</form>
