<div class="btn-group {{quotas}}">
  @if (showList) {
    <button tooltip="List" type="button" (click)="switchMode('list')" class="id-projectlist-view-list btn px-2 py-1 {{class}}" [ngClass]="{active: active == 'list'}"> <i class="fak fa-list fa-sm"></i></button>
  }
  @if (showCard) {
    <button tooltip="Cards" type="button" (click)="switchMode('card')" class="id-projectlist-view-card btn px-2 py-1 {{class}}" [ngClass]="{active: active == 'card'}"><i  class="fak fa-view-cards fa-lg"></i></button>
  }
  @if (showCardGroup) {
    <button tooltip="Stacked Cards" type="button" (click)="switchMode('cardGroup')" class="id-projectlist-view-stack btn px-2 py-1 {{class}}" [ngClass]="{active: active == 'cardGroup'}"> <i class="far fa-layer-group fa-sm"></i></button>
  }
  @if (showCalendar) {
    <button tooltip="Calendar" type="button" (click)="switchMode('calendar')" class="id-projectlist-view-calendar btn px-2 py-1 {{class}}" [ngClass]="{active: active == 'calendar'}"><i class="fak fa-calendar fa-md"></i></button>
  }
  @if (showGantt) {
    <button  tooltip="Gantt" type="button" (click)="switchMode('gantt')" class="id-projectlist-view-gannt btn px-2 py-1 {{class}}" [ngClass]="{active: active == 'gantt'}"><i class="fak fa-gantt fa-sm"></i></button>
  }
  @if (showNumber) {
    <button type="button" (click)="switchMode('number')" class="btn px-2 py-1 {{class}} {{quotas}}" [ngClass]="{active: active == 'number'}"><i class="fak fa-hashtag fa-lg"></i></button>
  }
  @if (showPercent) {
    <button type="button" (click)="switchMode('percent')" class="btn px-2 py-1 {{class}} {{quotas}}" [ngClass]="{active: active == 'percent'}"><i class="fak fa-percent fa-lg"></i></button>
  }
</div>
