import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { User } from 'core';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.apiEndpoint + '/auth/';
  tokenName = 'TOKEN_' + environment.apiEndpoint;
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  constructor(private http: HttpClient, private router: Router) {}

  loginAdmin(model: any) {
    return this.http.post(this.baseUrl + 'login', model).pipe(
      map((response: any) => {
        const user = response;
        if (user && user.token != null) {
          this.decodedToken = this.jwtHelper.decodeToken(user.token);
          if (user?.user?.roles.includes('Admin') || user?.user?.roles.includes('SuperAdmin')) {
            localStorage.setItem('user', JSON.stringify(user.user));
            localStorage.setItem(this.tokenName, user.token);
            localStorage.setItem('instanceId', user.user.instanceId);
           }
        }
        return response;
      })
    );
  }

  login(model: any) {
    return this.http.post(this.baseUrl + 'login', model).pipe(
      map((response: any) => {
        const user = response;
        if (user && user.token != null) {
            localStorage.setItem(this.tokenName, user.token);
            this.decodedToken = this.jwtHelper.decodeToken(user.token);
            localStorage.setItem('user', JSON.stringify(user.user));
        }
        return response;
      })
    );
  }

  guestLogin(token: string) {
    return this.http.post(this.baseUrl + 'guestlogin/' + token, {}).pipe(
      map((response: any) => {
        const user = response;
        if (user && user.token != null) {
            localStorage.setItem(this.tokenName, user.token);
            this.decodedToken = this.jwtHelper.decodeToken(user.token);
            localStorage.setItem('user', JSON.stringify(user.user));
        }
        return response;
      })
    );
  }

  impersonate(email: string, instance: string) {
    return this.http.get(this.baseUrl + 'impersonate?email=' + encodeURIComponent(email) + '&instance='+instance).pipe(
      map((response: any) => {
        const user = response;
        if (user) {
          localStorage.setItem(this.tokenName, user.token);
          this.decodedToken = this.jwtHelper.decodeToken(user.token);
          localStorage.setItem('user', JSON.stringify(user.user));
        }
      })
    );
  }
  supportLogon(path) {
    return this.http.get(this.baseUrl +'sso/initiated?path='+path);
  }

  forgot(model: any) {
    return this.http.post(this.baseUrl + 'ForgotPassword', model);
  }

  changePassword(model: any) {
    return this.http.post(this.baseUrl + 'ChangePassword', model);
  }

  reset(model: any) {
    return this.http.post(this.baseUrl + 'ResetPassword', model);
  }
  confirmEmailToken(model: any) {
    return this.http.post(this.baseUrl + 'ConfirmEmailToken', model);
  }

  loggedIn() {
    const token = this.getToken();
    if (this.jwtHelper.isTokenExpired(token)) {
      return false;
    }
   
    return true;
  }
  getToken() {
    return localStorage.getItem(this.tokenName);
  }
  autoLogIn(token) {
    var autoToken = this.jwtHelper.decodeToken(token);
    if(autoToken.role.includes('Admin') || autoToken.role.includes('SuperAdmin'))
    {
      localStorage.setItem(this.tokenName, token);
      return this.http.get(this.baseUrl + 'me').pipe(
        map((user: any) => {
         localStorage.setItem('user', JSON.stringify(user));
         localStorage.setItem('instanceId', user.instanceId);
        })
      );
    }
    return null;

  }

  isInternal() {
    if (this.getUser().roles.includes('Admin') || this.getUser().roles.includes('Member')) {
      return true;
    }
    else return false;
  }

  isClient() {
    if (this.getUser().roles.includes('Client')) {
      return true;
    }
    else return false;
  }

  isAdmin() {
    if (this.getUser().roles.includes('Admin')) {
      return true;
    }
    else return false;
  }

  isGuest() {
    if (this.getUser().roles.includes('Guest')) {
      return true;
    }
  }

  // OpinionRoute Instances for Dev, Stag and Live Env
  isORInstance() {
    const instanceId = this.getUser().instanceId?.toLowerCase() ?? '';
    if (['aaade042-c139-4285-33fb-08d76ad2902a','339eb389-c219-4e1d-9378-08d7ae29a187'].includes(instanceId)) {
      return true;
    }
    else return false;
  }
  isSuperAdmin() {
    if (this.getUser().superAdmin) {
      return true;
    }
    else return false;
  }
  isAbleToImpersonate() {
    if (this.getUser().ableToImpersonate) {
      return true;
    }
    else return false;
  }
  isVendorAdmin() {
    if (this.getUser().roles.includes('VendorAdmin')) {
      return true;
    }
    else return false;
  }
  isVendor() {
    if (this.getUser().roles.includes('Vendor')) {
      return true;
    }
    else return false;
  }
  setUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser(): User {
    return JSON.parse(localStorage.getItem('user'));
  }

  // Only used on Admin Project
  getInstance() {
    return localStorage.getItem('instanceId');
  }

  // Only used on Admin Project
  saveInstance(instance: any) {
    localStorage.setItem('instanceId', instance);
  }

  logOut() {
    localStorage.removeItem(this.tokenName);
    localStorage.removeItem('user');
    localStorage.removeItem('comments');
    localStorage.removeItem('instanceId');
    localStorage.removeItem('sections');
    localStorage.removeItem('sectionsLinks');
    location.reload();
    return false;
  }

  roleMatch(allowedRoles): boolean {
    let isMatch = false;
    let token = localStorage.getItem(this.tokenName);
    if (token == null) return false;

    let decodedToken =  this.jwtHelper.decodeToken(token);
    if (decodedToken == null) return false;

    const userRoles = decodedToken.role as Array<string>;
    allowedRoles.forEach(element => {
      if (userRoles.includes(element)) {
        isMatch = true;
        return;
      }
    });
    return isMatch;
  }
}
