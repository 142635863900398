<div class="card brandcard">
  <div class="card-body">
    <div class="row">
      <div class="col-4">
        <or-profile-image [url]="item.img" [name]="item.name" size="large" type="square" showInitials="true"></or-profile-image>
      </div>
      <div class="col-8">
        <a class="h5 or-primary-link d-block" (click)="cardSelected()">{{ item.name }}</a>
        <div class="info">
          <or-website [url]="item.website"></or-website>
          <hr class="or-dottedline">
          <or-phone [phone]="item.phone"></or-phone>
          <or-address [address]="item.address"></or-address>
          <!-- <or-email-address [emailaddress]="item.email"></or-email-address> -->
          <!--<or-social-links [social]="item.social"></or-social-links>-->
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer py-2 or-primary">
    <div class="row">
      <div class="col">
        <h5 class="d-inline"></h5>
      </div>
    </div>
  </div>
</div>

