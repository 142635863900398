<address>
  @if (address.address1 || address.address2) {
    <div><i class="fas fa-map-marker-alt or-secondary"></i> {{ (address.address1 || '') +', '+ (address.address2 || '') }}</div>
  }
  @if (address.city || address.state) {
    <div><i class="fas fa-city or-secondary"></i> {{ (address.city ? address.city + ' - ' : '') }} {{ (address.state || '') }}</div>
  }
  @if (address.country) {
    <div><i class="fas fa-globe or-secondary"></i> {{ address.country || '' }}</div>
  }
</address>
