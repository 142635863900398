export interface ProjectTask {
    id?: string;
    taskName: string;
    description?: string;
    status?: string;
    assignedToUsers?: any[];
    startDate?: Date;
    dueDate?: Date;
    completeDate?: Date;
    showTask?:boolean;
    assignedToDisplayName?:string;
    startDate_dt?: Date;
    dueDate_dt?: Date;
    sortOrder?: number;
    stageId?: string;
    taskLengthHours?: number;
    taskLengthMinutes?: number;
    daysFromProjectStart?: number; // for schedule
    taskLength?: number; // for schedule
    reoccurrenceSettings?: ReoccurrenceSettings;
}

export interface TemplateProjectTask {
    id?: string;
    taskName: string;
    description?: string;
    daysFromProjectStart?: number;
    taskLength?: number;
    sortOrder: number;
    stageId: string;
}

export interface ReoccurrenceSettings {
    id?: string;
    startDate: Date;
    endDate: Date;
    startDate_dt: Date;
    endDate_dt: Date;
    frequency: number;
    frequencyType: string;
    days: string;
    daysArray?: any[];
}
