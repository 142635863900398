import { AppIntegration, Integration } from 'core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AppIntegrationService {
  baseUrl = environment.apiEndpoint + '/appIntegration';
  file: File;

  requestId = '';

  constructor(private http: HttpClient) { }

  // insertAppIntegrationSettings(setting: AppIntegration) {
  //   return this.http.post(this.baseUrl + '/insert', setting);
  // }

  insertAppIntegrationSettings(setting: AppIntegration) {
    return this.http.post(this.baseUrl + '/insert', setting);
  }
  testKeys(setting: AppIntegration) {
    return this.http.post(this.baseUrl + '/testKey', setting);
  }
  getAppIntegration(integrationId: string,instanceId:string): Observable<AppIntegration> {
    return this.http.get<AppIntegration>(this.baseUrl + '/' + integrationId+'/'+instanceId );
  }
  getAllIntegrations(instanceId:string): Observable<Integration[]> {
    return this.http.get<Integration[]>(this.baseUrl + '/GetAllIntegrations/'+instanceId );
  }

  getAvailableIntegrations(instanceId:string): Observable<Integration[]> {
    return this.http.get<Integration[]>(this.baseUrl + '/GetAvailableIntegrations/'+instanceId );
  }

  DeleteIntegrations(id: string): Observable<Integration> {
    return this.http.delete<Integration>(this.baseUrl + '/DeleteIntegration/' + id);
  }  
}
