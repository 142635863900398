@if (_stages) {
  <div class="stage-steps d-flex">
    @for (stage of _stages; track stage; let i = $index) {
      <span class="btn btn-arrow-right nav-font14"
            [class.active]="stage.isCompleted"
            [class.current]="currentStage === stage.name"
            [ngStyle]="{'--stage-color': stage.color}" (click)="selectStage(stage)">
        {{ stage.name }}
      </span>
    }
  </div>
}

