<div class="cards">
  <div class="card-header d-flex align-items-center bg-white border-bottom-0">
    <span class="h6 nav-secondary font-weight-bold nav-font18">Homepage</span>
  </div>
  <div class="">
    <p class="nav-font14 mx-3 px-1">Here you can configure which cards will be displayed for each Project Stage in Nav Homepage.</p>
    <div class="mx-3 mb-5 px-1 py-2">
      @if (stages.length == 0) {
        <div class="nav-font14 grey5">
          No stages. Go to tab 'Stage and Tasks' and add stage to begin.
        </div>
      }

      @if (stages.length > 0) {
        <div class="grid mt-3">
          @for (item of stages; track item; let idx = $index) {
            <div class="">
              <div class="card px-3 pb-3 pt-2">
                <div class="row">
                  <div class="hex col-1 pt-1 px-2">
                    <span class="dot d-inline-block mr-2" [style]="'background-color:'+ item.color+';'"></span>
                  </div>
                  <div class="grey4 font-weight-bold nav-font14 pr-0 pt-1 col-10">
                    {{item.name}}
                  </div>
                </div>
                @if (item.homepageCards.length > 0) {
                  <div class="row d-flex justify-content-center mt-3"
                    [dndDropzone]="['card']"
                    (dndDrop)="onDrop($event, item.homepageCards)">

                    <div class="dndPlaceholder border rounded-1 opacity-25" dndPlaceholderRef></div>

                    @for (card of item.homepageCards; track card; let i = $index) {
                      <!-- draggable cards -->
                      <div
                        [dndDraggable]="card"
                        dndType="card"
                        dndEffectAllowed="move"
                        class="col-12 mb-3">
                        <div class="row">
                          <div class="col-1 text-right pr-0" dndHandle>
                            <i class="fas fa-grip-dots-vertical dragIcon"></i>
                          </div>
                          <div class="col-10 pr-0 cardName">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text fak fa-trash fa-error cursor-pointer px-2" (click)="removeCard(item, i)"></span>
                              </div>
                                <select class="form-control form-control-sm form-control-nav pl-1 py-0" [(ngModel)]="card.type">
                                  <option value="">-- Select Card --</option>
                                  <option value="client">Client</option>
                                  <option value="closure">Closure</option>
                                  <option value="datacleaning">Data Cleaning</option>
                                  <option value="dates">Dates</option>
                                  <option value="field-management">Field Management</option>
                                  <option value="invoice">Invoice</option>
                                  <option value="sample-specification">Sample Specification</option>
                                  <option value="schedule">Schedule</option>
                                  <option value="survey-links-vendors">Survey Links & Vendors</option>
                                  <option value="survey-testing">Survey Testing</option>
                                  <option value="team">Team</option>
                                  <option value="quality">Quality</option>
                                  <option value="quotas">Quotas</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  }
                  <div class="row d-flex justify-content-center">
                    <div class="nav-font14 grey5 col-12">
                      <div class="row mb-1">
                        @if (item.homepageCards.length == 0) {
                          <div class="col">
                            <span class="d-block">No cards. Add homepage card to this stage.</span>
                            <div class="row mt-3">
                              <div class="col cursor-pointer navfont-14 nav-accent-blue font-weight-bold text-right" (click)="addCard(item)">
                                <i class="far fa-plus pr-1"></i>
                                Add card
                              </div>
                            </div>
                          </div>
                        }
                        @else {
                          <div class="col cursor-pointer navfont-14 nav-accent-blue font-weight-bold text-right" (click)="addCard(item)">
                            <i class="far fa-plus pr-1"></i>
                            Add card
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          }
        </div>
      }
      </div>
    </div>
  </div>
