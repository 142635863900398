import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectDataService {

  constructor() { }

  private messageProjectSource = new BehaviorSubject(null);
  private messageAnyData = new BehaviorSubject(null);
  private messageFamilySource = new BehaviorSubject(null);
  private messageActionNarBar = new BehaviorSubject(null);
  private messageSideBar = new BehaviorSubject(null);
  private messageSideBarVendorView = new BehaviorSubject(null);
  private messageSideBarClientView = new BehaviorSubject(null);
  private messageProjectStatus = new BehaviorSubject(null);
  private messagePageName = new BehaviorSubject(null);

  getAnyData = this.messageAnyData.asObservable();
  getProject = this.messageProjectSource.asObservable();
  getFamily = this.messageFamilySource.asObservable();
  getActionNavBar = this.messageActionNarBar.asObservable();
  getSidebarMode = this.messageSideBar.asObservable();
  getVendorViewSidebar = this.messageSideBarVendorView.asObservable();
  getClientViewSidebar = this.messageSideBarClientView.asObservable();
  getProjectStatus = this.messageProjectStatus.asObservable();
  getPageName = this.messagePageName.asObservable();

  setCurrentProject(data: any) {
    this.messageProjectSource.next(data);
  }

  setPageName(data: any) {
    this.messagePageName.next(data);
  }
  setAnyData(data: any) {
    this.messageAnyData.next(data);
  }
  setCurrentFamily(data: any) {
    this.messageFamilySource.next(data);
  }
  setActionNavBar(data: any) {
    this.messageActionNarBar.next(data);
  }
  toggleSidebar(data: any) {
    this.messageSideBar.next(data);
  }
  setvendorViewSidebar(data: any) {
    this.messageSideBarVendorView.next(data);
  }
  setclientViewSidebar(data: any) {
    this.messageSideBarClientView.next(data);
  }
  setProjectStatus(data: any) {
    this.messageProjectStatus.next(data);
  }
}
