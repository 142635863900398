<div class="btn-group" dropdown #dropdown="bs-dropdown" [container]="container" [isDisabled]="disabled">
  @if (status != 'Invoiced' && !isClient) {
    <div id="button-basic" (click)="toggleStatus($event)" type="button" controls="dropdown-basic">
      <span class="cursor-pointer nav-font14 text-nowrap nav-status-selected {{statusClass}}" [class.open]="dropdown.isOpen" [class.cursor-default]="isAClient()">
        <i class="fak fa-{{statusClass}} font-weight-bold"></i>{{ status }}@if (!isAClient()) {
        <i class="fak fa-down-arrow fa-xs"></i>
      }
    </span>
  </div>
}
@if (status == 'Invoiced' || isClient) {
  <div type="button" controls="dropdown-basic">
    <span class="cursor-pointer nav-font14 text-nowrap nav-status {{statusClass}}">
      <i class="fak fa-{{statusClass}} font-weight-bold"></i>{{ status }}
    </span>
  </div>
}
<ul id="dropdown-status" *dropdownMenu class="dropdown-menu dropdown-menu-right" (mouseleave)="dropdown.hide()" [class.position-relative]="isPositionRelative" role="menu" aria-labelledby="button-basic">
  @for (value of _values; track value; let idx = $index) {
    <li role="menuitem" class="expandable status-item" [class.closed]="closed" [class.border-bottom-0]="(idx+1) === _values?.length">
      <a class="dropdown-item cursor-pointer nav-status" (click)="applyStatus($event, value?.status)">
        <i class="fak fa-{{value?.class }} font-weight-bold mr-2"></i>{{ value?.status }}
      </a>
    </li>
  }

  @if (partnerId == null) {
    <div class="px-3 mt-2 expandable" [class.closed]="!closed">
      <small class="mb-2 d-block">You have changed this Project's status to <span class="font-weight-bold">{{ status }}</span></small>
      <small class="d-block">Would you also like to update all Vendors status for this project?</small>
      <div class="float-right py-2 px-1">
        <button class="small-button btn nav-btn-primary btn-sm shadow-none mx-1" type="button" (click)="toggleStatus($event)"><i class="fa fa-times mt-1"></i></button>
        <button class="small-button btn nav-btn-accent btn-sm shadow-none" type="button" (click)="applyStatusPartners($event)"><i class="fa fa-check mt-1"></i></button>
      </div>
    </div>
  }

  @if (partnerId != null) {
    <div class="px-3 mt-2 expandable" [class.closed]="!closed">
      <small class="mb-2 d-block">You have changed this Vendors status to <span class="font-weight-bold">{{ status }}</span></small>
      <small class="d-block">Would you also like to update the Project status for this project?</small>
      <div class="float-right py-2 px-1">
        <button class="small-button btn nav-btn-primary btn-sm shadow-none mx-1" type="button" (click)="toggleStatus($event)"><i class="fa fa-times mt-1"></i></button>
        <button class="small-button btn nav-btn-accent btn-sm shadow-none" type="button" (click)="applyStatusProject($event)"><i class="fa fa-check mt-1"></i></button>
      </div>
    </div>
  }
</ul>
</div>

<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Invoice</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Are you sure you want to change project status to invoiced? Once a project is Invoiced the status can no longer be changed.</p>
    <button type="button" class="btn nav-btn-primary btn-sm shadow-none mx-1" (click)="openModalRef.hide()">No</button>
    <button type="button" class="btn nav-btn-accent btn-sm shadow-none" (click)="confirmStatusChange()">Yes</button>
  </div>
</ng-template>

<ng-template #modalNoLiveUrl>
  <div class="modal-header nav-error pt-2 pr-2">
    <div class="col pt-2 px-0">
      <div class="row d-flex align-items-center mx-0">
        <i class="fa-message-warning fak mx-2 nav-font20"></i>
        <span class="font-weight-bold nav-font16">Change Status Warning</span>
      </div>
    </div>
    <div class="col-2 text-right px-0">
      <i class="far fa-close grey4 nav-font18 p-1 pr-2" (click)="openModalRef.hide()"></i>
    </div>
  </div>
  <div class="modal-body pt-0">
    <h5 class="grey5 d-block mb-3 mx-2">Missing Live URL for Survey Entry Links, add to change field status.</h5>
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100 align-items-center">
      <div class="col text-right px-0">
        <button type="button" class="btn btn-sm nav-btn-accent shadow-none" (click)="openModalRef.hide()">Ok</button>
      </div>
    </div>
  </div>
</ng-template>


