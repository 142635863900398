<div class="action-button px-3 py-1 {{size}}" [class.dark]="style =='dark'" (click)="actionSelected($event)">
  @if (icon) {
    <i class="{{ icon }} align-self-center" [ngClass]="{'fa-2x' : size == 'large'}" aria-hidden="true"></i>
  }
  <div class="body" [class.d-inline-flex]="!description" >
    <span class="m-0 align-self-center font-weight-bold nav-font14" [ngClass]="size == 'large' ? 'h5' : 'h6'">{{ label }}</span>
    @if (description != null && description != '') {
      <p class="m-0 small">
        {{ description }}
      </p>
    }
  </div>
</div>
