<form [formGroup]="editForm" (ngSubmit)="edit()" novalidate>
  <div class="card m-3">
    <div class="card-header text-white or-primary-bg">
      <p class="h4 inline"><em class="fa fa-users fa-sm"></em> User Details</p>
    </div>
    <div class="card-body">
      <input type="hidden" class="form-control" formControlName="id" id="id" required="true">
      <div class="row m-1">
        <div class="col-md-6 col-sm-12 mb-3">
          <label for="firstName" class="required">First name</label>
          <input type="text" class="form-control" formControlName="firstName" id="firstName"
            [ngClass]="submitted && utils.showCssValidField(editForm, 'firstName')">
          </div>
          <div class="col-md-6 col-sm-12 mb-3">
            <label for="lastName" class="required">Last name</label>
            <input type="text" class="form-control" id="lastName" formControlName="lastName"
              [ngClass]="submitted && utils.showCssValidField(editForm, 'lastName')">
            </div>
          </div>
          <div class="row  m-1">
            <div class="col-md-6 col-sm-12 mb-3">
              <label for="email" class="required">Email</label>
              <input type="text" class="form-control" formControlName="newemail" id="newemail" (focus)="emailFocus=true" (blur)="onBlurEmail()">
              <!-- show message if newemail input is focused-->
              @if (emailFocus || editForm.get('newemail').value != editForm.get('email').value) {
                <div class="alert alert-warning mx-1 mt-2">
                  Changing email will change login credentials
                </div>
              }
            </div>
            <div class="col-2 pr-1 countrycode" [class.noborder]="callingCode?.length > 0">
              <div>
                <label for="country">Phone</label>
                <div class="input-group mb-3" >
                  <or-type-ahead
                    name="countryList"
                    [labelField]="'name'"
                    [valueField]="'value'"
                    [(ngModel)]="callingCode"
                    [ngModelOptions]="{standalone: true}"
                    (valueChange)="setCallingCode($event)"
                    [selectedValues]="callingCode"
                    [showLoading]="false"
                    class="w-100"
                    [type]="'countryCode'"
                    [dataCallback]="getCountryCode"
                    placeholder="Code">
                  </or-type-ahead>
                </div>
              </div>
            </div>
            <div class="col-4 pl-0">
              <div>
                <label for="phone">&nbsp;</label>
                <input id="phone" type="text" class="form-control" formControlName="phone" placeholder="Number">
              </div>
            </div>
          </div>
          <div class="row m-1">
            <div class="col-md-6 mb-3">
              <label class="required">User Type</label>
              <select class="custom-select d-block w-100" formControlName="role" id="role">
                <option value="Member">Internal Member</option>
                <option value="Admin">Site Admin</option>
                <option value="Client">Client</option>
                <option value="Vendor">Vendor</option>
                <option value="VendorAdmin">Vendor Admin</option>
              </select>
            </div>
            @if (showClients) {
              <div class="col-md-6 col-sm-12 mb-3">
                <label class="required">Clients</label>
                <select class="custom-select d-block w-100" formControlName="clientId" id="clientId" required="">
                  @for (client of clients; track client) {
                    <option value="{{ client.id }}">{{ client.name }}</option>
                  }
                </select>
                <div class="invalid-feedback">
                  Please select a client.
                </div>
              </div>
            }
            @if (showVendors) {
              <div class="col-md-6 col-sm-12">
                <label class="required">Vendors</label>
                <select class="custom-select d-block w-100" formControlName="vendorId" id="vendorId" required="">
                  @for (vendor of vendors; track vendor) {
                    <option value="{{ vendor.id }}">{{ vendor.name }}</option>
                  }
                </select>
                <div class="invalid-feedback">
                  Please select a vendor.
                </div>
              </div>
            }
            @if (showImpersonateButton()) {
              <div class="col-md-6 col-sm-12">
                <div><label for="ableToImpersonate">Able to Impersonate?</label></div>
                <button type="button" class="btn btn-outline-light btn-sm border-0">
                  <or-toggle-button [id]="'ableToImpersonate'" formControlName="ableToImpersonate"></or-toggle-button>
                </button>
              </div>
            }
          </div>

          <div class="row mt-4 m-1">
            <div class="col-md-3 col-sm-12 mb-3">
              <div><label for="projectAccess">Block User Logon?</label></div>
              <button type="button" class="btn btn-outline-light btn-sm border-0">
                <or-toggle-button [id]="'blockLogon'" formControlName="blockLogon" (changed)="blockLogon($event)"></or-toggle-button>
              </button>
            </div>
            @if (!isBlockLogon) {
              <div class="col-md-3 col-sm-12 mb-3">
                <div><label>Password Reset</label></div>
                <button type="button" class="btn btn-light mr-2" (click)="sendPasswordReset()">Password Reset</button>
              </div>
            }
          </div>

          <div class="row m-1">
            <div class="col-md-6 col-sm-12 mb-3">
              <div>
                <label for="title">Title </label>
                <input type="text" class="form-control" maxlength="30" formControlName="title" id="title" placeholder="Title">
              </div>
            </div>
            @if (!showVendors && !showClients) {
              <div class="col-md-6 col-sm-12 mb-3">
                <div class="manager">
                  <label for="manager">Manager </label>
                  <or-type-ahead [selectedValues]="selectedManagers === null ? [] : selectedManagers" [labelField]="'name'" [valueField]="'id'" [dataCallback]="getUserList" placeholder="Search for a User" (valueChange)="setManager($event)"></or-type-ahead>
                </div>
              </div>
            }
          </div>

          <div class="row m-3">
            <div class="col-md-12 col-lg-6">
              <label for="img">Profile <span class="text-muted"> (512 x 512 PNG or JPEG)</span></label>
              <div>
                <or-upload [showPreviewImg]=true [directToS3]=true [acceptImages]=true formControlName="image"></or-upload>
              </div>
            </div>
            <div class="col-md-12 col-lg-6 pt-1">
              <or-listbox [items]="roles" [headers]="headers" [loadSelected]="selectedRoles" (selected)="handleSelectedRoles($event)"></or-listbox>
            </div>
          </div>

          <div class="row m-1">
            <div class="col-md-6 col-sm-12 country">
              <div>
                <label for="country">Country</label>
                <or-type-ahead
                  name="countryList"
                  [labelField]="'value'"
                  [valueField]="'value'"
                  [(ngModel)]="countryList"
                  [ngModelOptions]="{standalone: true}"
                  (valueChange)="setCountry($event)"
                  [selectedValues]="countryList"
                  [showLoading]="false"
                  class="mr-2 w-100"
                  [dataCallback]="getCountryList"
                  placeholder="Type to Find">
                </or-type-ahead>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div>
                <label for="slackUsername">Slack Username </label>
                <input type="text" class="form-control" formControlName="slackUsername" id="slackUsername" placeholder="Slack Username">
              </div>
            </div>

          </div>
        </div>

        <div class="card-footer text-right mt-3">
          <div class="btn btn-light mr-2"  (click)="cancel()" type="button">Cancel</div>
          <button class="btn or-primary-bg text-white" type="submit">Save</button>
        </div>
      </div>
    </form>
