import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
declare var google: any;

@Component({
  selector: 'or-log-chart',
  templateUrl: './log-chart.component.html',
  styleUrls: ['./log-chart.component.scss']
})
export class LogChartComponent implements OnInit, OnChanges {

  static count = 0;

  @Input() public set data(data) {
    this.markers = data;
    this.drawChart();
  }

  @Input() backgroundColor = '#f0f0f0';
  @Input() title = 'Chart';
  @Output() callback = new EventEmitter();

  markers;

  id: string;
  constructor() {
    LogChartComponent.count++;
    this.id = 'log_chart_' + LogChartComponent.count;
   }

  ngOnInit() { }

  ngOnChanges() {
    this.drawChart();
  }

  drawChart() {
    if (this.markers != null && this.markers.length !== 0) {
      let maxCount = 0;
      this.markers.forEach(e => {
        if ( e.count > maxCount) {
          maxCount = e.count;
        }
      });

      const options = {
        title: this.title,
        legend: {position: 'none', textStyle: {fontSize: 12}, alignment: 'center'},
        backgroundColor: this.backgroundColor,
        chartArea: {
          top: '10%',
          right: '10%',
          bottom: '10%',
          left: '10%',
          width: '90%',
          height: '100%',
          backgroundColor: this.backgroundColor
        },
        curveType: 'function',
        vAxis: {
          title: 'Occurrences',
          gridlines: {color: 'none'},
          ticks: [0, maxCount]
        },
        hAxis: {
          title: '',
          format: 'MMM/d hh:mm'
          // gridlines: {color: 'none'}
        },
        width: '90%',
        height: '95%'
      };

      google.charts.load('current', {packages: ['corechart']});
      google.charts.setOnLoadCallback(() => {
        const chartData = google.visualization.arrayToDataTable([['datetime', 'number']].concat(this.markers));
        setTimeout(() => {
            const chartDiv = document.getElementById(this.id);
            const chart = new google.visualization.LineChart(chartDiv);
            chart.draw(chartData, options);
            this.callback.emit({ event });
          }, 500);
      });
    }
  }
}
