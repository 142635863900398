import { ReportsService } from './../../../services/reports.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { InstanceService } from '../../../../../core/src/services/instance.service';
import { AuthService } from 'projects/core/src/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'or-cleanid-report',
  templateUrl: './cleanid-report.component.html',
  styleUrls: ['./cleanid-report.component.scss']
})
export class CleanidReportComponent implements OnInit {

  iframeUrl = '';
  reportId = "89";
  instanceId = '';
  @ViewChild('myIframe') public myIframe;


  constructor(private instanceService: InstanceService, private reportService: ReportsService, public auth: AuthService, private route: ActivatedRoute) {

    this.route.params.subscribe((params) => {
      this.reportId = (params.mode == 'nav') ? "174" : "89";

      if (params.instanceid != null) {
        this.instanceId = params.instanceid;
      }
      this.loadDashboard();
    });
  }
  ngOnInit() {

  }
  loadDashboard() {
    this.reportService.GenerateMetabaseEmbedToken(this.reportId, this.instanceId).subscribe(data => {
      this.iframeUrl = data;
      const iframe = this.myIframe.nativeElement;
      if (iframe != null) {
        iframe.src = this.iframeUrl;
      }
    }, error => {

    });
  }
}
