<div class="card">
  <div class="card-header text-white  or-primary-bg">
    <p class="h4 inline float-left"><em class="fa fa-key fa-sm"></em> Manage Security Keys</p>
    <div class="float-right">
      <div class="form-inline form">
        <button type="button" class="btn btn-sm btn-outline-light inline" (click)="openModal(modal)"
          routerLinkActive="active">
          <em class="fa fa-plus-circle"></em> Add Security Key
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="mt-0">
      <or-list [items]="securitykeys" [layout]="layout" [actions]="actions" (action)="handleAction($event)">
      </or-list>
    </div>
  </div>
</div>

<!-- MODAL ADD PUBLIC KEY -->
<ng-template #modal>
  <div class="modal-header">
    <p class="h4 position-absolute"><em class="fa fa-key fa-sm"></em> New Security Key</p>
    <button type="button" class="close pull-right text-white" aria-label="Close"
      (click)="resetForm(); modalOpenRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editForm" (ngSubmit)="submitForm()" novalidate>
      <input type="hidden" formControlName="id" id="id">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="row mb-3">
                <div class="col">
                  <label for="name" class="required">Name</label>
                  <div class="input-group">
                    <input type="text" class="form-control" formControlName="name" id="name"
                      [ngClass]="submitted && utils.showCssValidField(editForm, 'name')"
                      (ngModelChange)="checkForDuplicateName($event)">
                  </div>
                  @if (editForm.controls.name.errors?.duplicate) {
                  <span class="form-error">
                    Cannot have duplicate name!
                  </span>
                  }
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <label for="value" class="required">Type</label>
                  <div class="input-group">
                    <select class="form-control" formControlName="type" id="type">
                      <option value="Standard">Standard</option>
                      <option value="DecipherAPI">Decipher API</option>
                    </select>
                  </div>
                </div>
              </div>
              @if (editForm.controls.type.value === 'Standard') {
              <div class="row mb-3">
                <div class="col">
                  <label for="value" class="required">Value</label>
                  <div class="input-group">
                    <input type="text" class="form-control" formControlName="value" id="value"
                      [ngClass]="submitted && utils.showCssValidField(editForm, 'value')">
                  </div>
                </div>
              </div>
              }@else{
              <!-- @if (editForm.controls.type.value !== 'Standard' && editForm.controls.type.value !== null) { -->
              <div class="row mb-3">
                <div class="col">
                  <label for="value" class="required">Base URL</label>
                  <div class="input-group">
                    <input type="text" class="form-control" formControlName="baseURl" id="baseURl">                      
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <label for="value" class="required">Credentials</label>
                  <div class="input-group">
                    <input type="text" class="form-control" formControlName="credentials" id="credentials">        
                  </div>
                </div>
              </div>
            }
            </div>

          </div>
        </div>
        <div class="card-footer text-right">
          <button type="submit" class="btn btn-sm or-primary-bg text-white"
            [disabled]="editForm.controls.name.errors?.duplicate">
            <em class="fa fa-save"></em> Save Security Key
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Do you wish to continue?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="modalOpenConfirmRef.hide()">No</button>
  </div>
</ng-template>