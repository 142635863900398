import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'or-title',
  template: `@if (title) {
  <div class="userTitle font-weight-light text-truncate"> {{ title }}</div>
}
@if (!title) {
  <div class="userTitle"> </div>
}`
})
export class TitleComponent implements OnInit {

  @Input()title: string;

  constructor() { }

  ngOnInit() {

  }

}
