import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'or-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss']
})
export class DataGridComponent implements OnInit {
  @Input() type = '';
  @Input() x_label = '';
  @Input() y_label = '';
  @Input() sign = '';
  @Input() data = [];
  @Output() updateValue: EventEmitter < any > = new EventEmitter;

  inputVal = null;
  valueLength = 0;
  scoreLength = 0;
  percentControl;
  expectedDoubleValue;

  constructor() { }

  ngOnInit() {

    this.valueLength = this.data.length;
    this.scoreLength = this.data.length * this.data.length;
  }

  
  removeElement(index) {

      this.data.splice(index, 1);

      for (var _x = 0; _x < this.data.length; _x++){
        this.data[_x]['links'].splice(index, 1)
      }

  }


  numberOnly(type, val, event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    this.expectedDoubleValue = null;

    if (type == 'percent'){
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false
      }
      if (val != null && (val[0]=='0' || val[0] == 0)) {
        return false
      }
      return true;
    }
    if (type == 'penalty'){
      this.expectedDoubleValue = String(val) + event.key;

      if (!String(val) || charCode == 48){ // if key input is '0'
        if (String(val) == '0') {
          return false
        }
        return true
      }
      else if (charCode > 31 && (charCode < 48 || charCode > 57) ) {
        return false
      }

      else if (Number(val) == event.key) {
        return false
      }
      else if (charCode > 31 && (charCode < 48 || charCode > 57) ) {
        return false
      }     
      return true
    }
    return false;

  }

  scoreChange(x_index, y_index, value){

    if (this.expectedDoubleValue) {
      if (value != this.expectedDoubleValue) {
        value = Number(value[0])
      }
      else if ((value != 10 && value != '10') && (value.length == 2) ){
        value = Number(String(value)[1]);

      }
      else if (value.length == 3) {
        value = Number(String(value)[2])
      }
      else {
        value = Number(value)
      }
    }

    if (!String(value)) {
      this.data[x_index]['links'][y_index]['value'] == null
    }
    else {
      this.data[x_index]['links'][y_index]['value'] = Number(value);
    }
  }

  sort() {
    this.data.sort( this.sortValues('actual'));
    for (var _x = 0; _x < this.data.length; _x++) {
      this.data[_x]['links'].sort( this.sortValues('expected'));
    }
  }

  valueChange(index, value) {
    if (value != null) {
      while((value.charAt(0) === '0') && (value.length > 1)){
        value = value.substring(1);
      }
      value = Number(value);
    }
    this.data[index]['actual'] = value;

    for (var _x = 0; _x < this.data.length; _x++) {
      this.data[_x]['links'][index]['expected'] = value;
    }
  }


  sortValues(att) {
    return function(a,b){
      var x;
      var y;
      if (a[att] == null) {
        x = 10000
      }
      else {
        x = Number(a[att])

      }
      if (b[att] == null) {
        y = 10000
      }
      else {
        y = Number(b[att])
      }

      if (x > y) {
          return 1;
      } else if(x < y){

          return -1;
      }
      return 0;
    }
  }



  addCol(){
    var newColLink = [];
    if (this.inputVal) {
      this.inputVal= Number(this.inputVal)
    }
    for (var _y=0; _y < this.data.length; _y ++) {
      newColLink.push({expected: this.data[_y]['actual'], value: null})
    }

    this.data.push({actual: this.inputVal, links: newColLink});

    for (var _x = 0; _x < this.data.length; _x++) {
      this.data[_x]['links'].push({expected: this.inputVal, value: null})
      this.data[_x]['links'].sort(this.sortValues('expected'))
      this.scoreLength ++;
    }
    
    this.data.sort( this.sortValues('actual'));
    this.valueLength ++;
    this.inputVal = null;
  }

}
