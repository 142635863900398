import { Component, OnInit, Input, EventEmitter, Output, ViewChildren, ElementRef, QueryList } from '@angular/core';
@Component({
  selector: 'or-vendor-cpi-grid',
  templateUrl: './vendor-cpi-grid.component.html',
  styleUrls: ['./vendor-cpi-grid.component.scss']
})
export class VendorCpiGridComponent implements OnInit {

  @Input() data:any = {};

  @Input() viewonly:boolean = false;

  dataTable: {
    rows: (number | null)[], // LOIs
    cols: (number | null)[], // IRs
    matrix: (number | null)[][]  // CPIs
  } = {
    rows: [5],
    cols: [5],
    matrix: [[null]]
  };

  @ViewChildren('inputCol') inputCols!: QueryList<ElementRef>;
  @ViewChildren('inputRow') inputRows!: QueryList<ElementRef>;

  integerPattern = { 9: { pattern: new RegExp('^[0-9]*$'), optional: true } };

  @Output() dataChange: EventEmitter<any> = new EventEmitter;

  constructor() { }

  ngOnInit() {
    if (!this.data || this.data?.matrix.length === 0) {
      this.data = this.dataTable;
    }
  }

  addRow() {
    this.data.rows.push(null);
    const newRow = Array(this.data.cols.length).fill(null);
    this.data.matrix.push(newRow);
    this.dataChange.emit(this.data);
  }

  addCol() {
    this.data.cols.push(null);
    this.data.matrix.forEach(row => row.push(null));
    this.dataChange.emit(this.data);
  }

  headerChange(idx: number, event?: any, type?: string): void {
    if (type == 'cols') {
      this.data.cols[idx] = Number(event) || null;
    } else {
      this.data.rows[idx] = Number(event) || null;
    }
    this.dataChange.emit(this.data);

    setTimeout(() => {
      if (type == 'cols') {
        this.inputCols.toArray()[idx]?.nativeElement.focus();
      } else {
        this.inputRows.toArray()[idx]?.nativeElement.focus();
      }
    }, 10);
  }

  valueChange(i_x: number, i_y?: number, event?: any): void {
    if (i_y && event) {
      this.data.matrix[i_x][i_y] = Number(event) || null;
    }
    this.dataChange.emit(this.data);
  }

  getCellBackgroundColor(cellValue: number | null): string {
    return 'white';
    // if (cellValue === null) {
    //   return 'white'; // Default background
    // } else if (cellValue < 5) {
    //   return 'rgba(0,128,0, 0.3)'; // Green
    // } else if (cellValue < 10) {
    //   return 'rgba(255,140,0,0.3)'; // Orange
    // } else {
    //   return 'rgba(255,0,0,0.3)'; // Red
    // }
  }

  removeRow(index: number): void {
    this.data.rows.splice(index, 1);
    this.data.matrix.splice(index, 1);
    this.dataChange.emit(this.data);
  }

  removeCol(index: number): void {
    this.data.cols.splice(index, 1);
    this.data.matrix.forEach(row => row.splice(index, 1));
    this.dataChange.emit(this.data);
  }
}
