import { Component, Input, OnInit } from '@angular/core';
import { AlertMessageService } from '../../services/alert-message.service';
// import { AlertifyService } from '../../services/alertify.service';
// import { AuthService } from '../../services/auth.service';
// import { UserService } from '../../services/user.service';
import { AlertifyService, AuthService, ProjectDataService, UserService } from 'core';

@Component({
  selector: 'or-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  @Input() items: any;
  loading = false;

  constructor(private alertService: AlertMessageService,
     private projectDataService: ProjectDataService,
    private alertify: AlertifyService, public auth: AuthService, public userService: UserService) {   }

  ngOnInit(): void {

    setTimeout(() => this.checkNewMessages(), 5000);

    // Subscribe to the event newMessageReceived from AlertService.
    this.alertService.newMessageReceived.subscribe(data => {
      if (data.action == 'notify') {
        this.blinkNotifications();
      }
    });
  }

  isClientview: boolean = false;
  ngAfterViewInit() {
    setTimeout(() => {
      this.projectDataService.getClientViewSidebar.subscribe(data => {
        if (data) {
          this.isClientview = data != '';
        }
      });
    }, 500 * 1);

  }

  checkNewMessages() {
    let user = this.auth.getUser();
    this.alertService.checkNewMessages(user.instanceId, user.lastAlertViewDate).subscribe(data => {
      if (data) {
        this.blinkNotifications();
      }
    }, error => {
        console.log('Unable to check new Messages');
    });
  }

  blinkNotifications() {
    let user = this.auth.getUser();
    user.hasNewMessages = true;
    this.auth.setUser(user);
  }

  openMessages() {
    this.loading = true;
    let user = this.auth.getUser();
    let lastViewTimestamp = new Date(user.lastAlertViewDate).getTime();

    this.alertService.getLatestAlerts().subscribe(data => {
        this.items = data.filter(item => {
          var date = new Date(item.lastUpdated);
          date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
          item.time = this.timeSince(date);
          item.isNew = (new Date(item.lastUpdated).getTime() > lastViewTimestamp) || (item.time == 'just now');
          return item;
        });
      },
      error => {
        this.alertify.error('Unable to get notifications');
      },
      () => {
        // Set the LastAlertViewDate and hasNewMessages = false when Open the Messages
        user.hasNewMessages = false;
        var date = new Date();
        user.lastAlertViewDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        this.auth.setUser(user);
        this.loading = false;
      });
  }

  timeSince(date) {
    const intervals = [
      { label: 'year', seconds: 31536000 },
      { label: 'month', seconds: 2592000 },
      { label: 'day', seconds: 86400 },
      { label: 'hour', seconds: 3600 },
      { label: 'minute', seconds: 60 },
      { label: 'second', seconds: 0 }
    ];

    const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
    const interval = intervals.find(i => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);
    if (!isFinite(count) && interval.label == 'second')
        return 'just now';

    return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
  }

}
