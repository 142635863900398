import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { Role, RoleUser } from '../models/role';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class RoleService {
  baseUrl = environment.apiEndpoint + '/role/';

constructor(private http: HttpClient) { }

  GetRoles(forceRefresh = false): Observable<Role[]> {
    return this.http.get<Role[]>(this.baseUrl + 'roles', {
      headers : {cache : '1', refresh: forceRefresh? '1':'0' }
    });
  }
  GetUserRolesForInstance(userId: string, addTypes: boolean = false): Observable<Role[]> {
    return this.http.get<Role[]>(this.baseUrl + 'userRolesForInstance/' + userId).pipe(
      map((response) => {
        if(addTypes){
          response.push({id:'Internal', name:'Internal User Type'});
          response.push({id:'Client', name:'Client User Type'});
          response.push({id:'Vendor', name:'Vendor User Type'});
        }
        return response;
      }
    ));
  }

  GetInstanceRoles(instanceId: string, addTypes: boolean = false): Observable<Role[]> {
    return this.http.get<Role[]>(this.baseUrl + 'all/' + instanceId).pipe(
      map( (response) => {
        if(addTypes){
          response.push({id:'Internal', name:'Internal User Type'});
          response.push({id:'Client', name:'Client User Type'});
          response.push({id:'Vendor', name:'Vendor User Type'});
        }
        return response;
      }
    ));
  }

  AddRole(model: any): Observable<Role> {
    return this.http.post<Role>(this.baseUrl, model);
  }

  EditRole(model: any): Observable<Role> {
    return this.http.put<Role>(this.baseUrl + model.id, model);
  }

  DeleteRole(id: string) {
    return this.http.delete(this.baseUrl  + id);
  }

  AddUser(model: any): Observable<RoleUser> {
    return this.http.post<RoleUser>(this.baseUrl + 'user/add', model);
  }

  RemoveUser(id: any) {
    return this.http.delete(this.baseUrl + 'user/' + id);
  }

  RemoveUserRoles(userId: any) {
    return this.http.delete(this.baseUrl + 'user/roles/' + userId);
  }

  GetAllRoleUsers(roleId: string): Observable<RoleUser[]> {
    return this.http.get<RoleUser[]>(this.baseUrl + 'user/' + roleId);
  }

}
