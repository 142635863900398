<div class="tagsInputContainer">
  <div class="tagsinput" #tagsInput [style.border-bottom-left-radius]="(options.length > 0 && filter != '') ? '0px' : '10px'" [style.border-bottom-right-radius]="(options.length > 0 && filter != '') ? '0px' : '10px'">
    <div class="tag-add" #tagsAdd>
      <input
        [size]="placeholder.length"
        type="text"
        [(ngModel)]="filter"
        (ngModelChange)="updateSearch()"
        (keydown.enter)="onEnter($event)"
        (keyup)="onKeyup($event)"
        [placeholder]="placeholder" class="form-control form-control-sm inputSearch" />
      </div>
    </div>

    <div class="btn-group dropdown" dropdown #dropdown="bs-dropdown" [container]="container">

      <ul id="dropdown-list" *dropdownMenu class="dropdown-menu dropdown-menu-right py-0 pull-right" role="menu" aria-labelledby="button-basic" [style.width]="tagsInput.offsetWidth + 'px'">
        @for (tag of options; track tag) {
          <li role="menuitem" class="expandable">
            <a class="dropdown-item dropdown-list-item cursor-pointer text-truncate nav-font12 px-2" (click)="addTagFromDropdown(tag)">{{ tag.value }}
              <i class="far fa-lg fa-times float-right grey3 ml-3 mt-2 removeTagFromDropdown" (click)=" $event.preventDefault(); $event.stopPropagation(); removeTagFromDropdown(tag.id)"></i>
            </a>
            <hr class="p-0 my-0 mx-1">
          </li>
        }
      </ul>
    </div>
  </div>
