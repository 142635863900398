import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService, UtilsService } from 'core';
import { UnsavedchangesGuardService } from '../../../../main/src/app/_guards/unsavedchanges.guard.service';

@Component({
  selector: 'or-vendor-edit-cpi',
  templateUrl: './vendor-edit-cpi.component.html',
  styleUrls: ['./vendor-edit-cpi.component.scss'],
  providers: []
})
export class VendorEditCpiComponent {

  @Input() partnerRateCardForm: FormGroup;
  @Input() model: any = {};
  @Input() openModalRef: any;

  @Output() saveVendorCpi = new EventEmitter<any>();

  submitted = false;
  editMode = false;
  oldModel = {};

  constructor(
    public auth: AuthService,
    public utils: UtilsService,
    private unsavedChangesService: UnsavedchangesGuardService) { }

  ngOnInit() {
    this.oldModel = JSON.parse(JSON.stringify(this.model));
  }

  getForm() {
    return this.partnerRateCardForm || new FormGroup({});
  }

  handleSave() {
    if (!this.model.id || (this.model.id && this.editMode)) {
      this.savePartnerCpi();
    }
    else
      this.editMode = true;
  }

  handleClose(close = false) {
    this.model = this.oldModel;
    this.saveVendorCpi.emit({type: 'rollback' , data: this.model});

    if (this.editMode && !close) {
      this.editMode = false;
    }
    else  {
      this.unsavedChangesService.deregister([this.partnerRateCardForm]);
      this.openModalRef.hide();
    }
  }

  rateTableChange($event) {
    this.model.rateTable = $event;
  }

  savePartnerCpi() {
    this.submitted = true;

    if (!this.partnerRateCardForm.valid) {
      return;
    }

    this.sortDataTable();

    this.partnerRateCardForm.controls['rateTable'].setValue(this.model.rateTable);
    const type = !this.model.id ? 'add' : 'edit';
    this.saveVendorCpi.emit({type , data: this.partnerRateCardForm});
  }

  sortDataTable() {
    let dataTable = this.model.rateTable;

    if (!dataTable.rows.length || !dataTable.cols.length || !dataTable.matrix.length) {
      return;
    }

    const rowIndices = dataTable.rows
      .map((value, index) => ({ value, index }))
      .sort((a, b) => (a.value ?? Infinity) - (b.value ?? Infinity));

    const colIndices = dataTable.cols
      .map((value, index) => ({ value, index }))
      .sort((a, b) => (a.value ?? Infinity) - (b.value ?? Infinity));

    const sortedMatrix: (number | null)[][] = rowIndices.map(({ index: rowIndex }) =>
      colIndices.map(({ index: colIndex }) => dataTable.matrix[rowIndex][colIndex])
    );

    dataTable.rows = rowIndices.map(({ value }) => value);
    dataTable.cols = colIndices.map(({ value }) => value);
    dataTable.matrix = sortedMatrix;
  }

  toggleEnable(evt) {
    this.saveVendorCpi.emit({type: 'toggle' , data: this.model});
  }

}
