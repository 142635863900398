export interface AppIntegration {
    id?: string;
    integrationId?: string;
    status:boolean;
    instanceId?: string;
    isDeleted: boolean;
   // settings?: AppIntegrationSettings;
    clientAppKey: string;
    clientSecret:string;
}

// export interface AppIntegrationSettings {
//     clientAppKey?: string;
//     clientSecret?:string; 
// }

