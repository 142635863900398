@if (_rule.ruleType == 'live' || _rule.ruleType == 'scheduled' || _rule.ruleType == null) {
  <span class="font-weight-normal conditions d-inline-block" [class.editing]="_rule.editing">

    <div class="position-relative">

      <!-- Recipient -->
      <ng-template *ngTemplateOutlet="tplAlertRecipient"></ng-template>

      <span class="condition-text nav-font14 grey5 position-relative mx-1 font-weight-bold">  via  </span>

      <!-- Method -->
      <ng-template *ngTemplateOutlet="tplAlertMethod"></ng-template>

      <span class="condition-text nav-font14 grey5 position-relative mx-1 font-weight-bold">  when  </span>

      <!-- Scope Type -->
      <ng-template *ngTemplateOutlet="tplScopeType"></ng-template>

      <!-- Metrics -->
      <ng-template *ngTemplateOutlet="tplMetrics"></ng-template>

      @if (selectedMetric !== 'Quota cells') {
        <span class="condition-text nav-font14 grey5 position-relative mx-1 font-weight-bold">  are  </span>
      }

      <!-- Operators -->
      <ng-template *ngTemplateOutlet="tplOperators"></ng-template>

      <!-- Metric number -->
      <ng-template *ngTemplateOutlet="tplMetricNumber"></ng-template>

      <!-- Date Time -->
      @if (['Above','Below'].includes(selectedOperator)) {
        <ng-template *ngTemplateOutlet="tplDateTime"></ng-template>
      }

    </div>
  </span>
}

  @if (_rule.ruleType == 'task') {
    <span class="font-weight-normal conditions d-inline-block" [class.editing]="_rule.editing">
      <span class="condition-text font-weight-light">If Task </span>
      <!-- chosen task type list -->
      <span class="condition-field scrollbar overflow-y" style="max-height: 250px;" dropdown #ddScope="bs-dropdown" container="body" placement="top" [isDisabled]="!_rule.editing" [insideClick]="true">
        <span dropdownToggle aria-controls="dropdown-scope">{{selectedTask}}</span>
        <ul id="dropdown-schedule-item" *dropdownMenu class="dropdown-menu dropdown-menu-right position-relative" role="menu" aria-labelledby="basic-link">
          <div class="scrollbar overflow-y" style="max-height: 250px;">
            @for (task of listTasks; track task) {
              <li>
                <a class="dropdown-item cursor-pointer" (click)="applySelectedTask(task)">{{task.stage}} | {{task.name}}</a>
              </li>
            }
          </div>
        </ul>
      </span>
      <span class="condition-text">&nbsp;</span>
      <span class="condition-text font-weight-light">  is  </span>
      <span class="condition-text font-weight-light">  not  </span>
      <span class="condition-text font-weight-light">  complete  </span>
      <span class="condition-text font-weight-light">  by  </span>
      <span class="condition-text">&nbsp;</span>
      <!-- Date -->
      <span class="condition-field" [class.editable]="_rule.editing">
        @if (!_rule.editing) {
          <span>{{selectedScheduledDate.label}}</span>
        }
        @if (_rule.editing) {
          <div class="d-inline">
            <input type="text"  autocomplete="off"
              (bsValueChange)="selectedScheduledDate.value = $event"
              (onHidden)="applyScheduledDate()"
              (mouseleave)="handleFields('date');"
              (blur)="handleFields('date');"
              bsDatepicker
              readonly
              [bsValue]="selectedScheduledDate.value"
              [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', isAnimated: true, isOpen:true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
              class="form-control  form-control-nav form-control-sm d-inline bg-white date text-center">
            </div>
          }
        </span>
        <span class="condition-text font-weight-light">&nbsp;at  </span>
        <!-- Time -->
        <span class="condition-field timepicker" [class.editable]="_rule.editing">
          @if (!_rule.editing) {
            <span>{{selectedScheduledTime.label}}</span>
          }
          @if (_rule.editing) {
            <div class="d-inline-flex position-relative" style="top: 5px">
              <timepicker [(ngModel)]="selectedScheduledTime.value" (ngModelChange)="applyScheduledDate()" [showMeridian]="true" [showSpinners]="false" [minuteStep]="1"></timepicker>
            </div>
          }
        </span>
        <span class="condition-text font-weight-light mr-1">, alert  </span>
        <!-- Alert Recipient -->
        <span class="condition-field" dropdown #ddAlertRecipient="bs-dropdown" container="body" placement="top right" [isDisabled]="!_rule.editing">
          <span dropdownToggle aria-controls="dropdown-alertRecipient">{{ selectedTaskAlertRecipient }}</span>
          <ul id="dropdown-alertRecipient" *dropdownMenu class="dropdown-menu dropdown-menu-right position-relative" role="menu" aria-labelledby="basic-link">
            @for (recipient of taskAlertRecipients; track recipient) {
              <li>
                <a class="dropdown-item cursor-pointer" (click)="applyTaskAlertRecipient(recipient)">{{ recipient }}</a>
              </li>
            }
          </ul>
        </span>
        <span class="condition-text font-weight-light">  by  </span>
        <!-- Alert Method -->
        <span class="condition-field" dropdown #ddAlertMethod="bs-dropdown" container="body" placement="top right" [isDisabled]="!_rule.editing">
          <span dropdownToggle aria-controls="dropdown-alertMethod">{{selectedAlertMethod}}</span>
          <ul id="dropdown-alertMethod" *dropdownMenu class="dropdown-menu dropdown-menu-right position-relative" role="menu" aria-labelledby="basic-link">
            @for (alert of alertMethods; track alert) {
              <li>
                <a class="dropdown-item cursor-pointer" (click)="applyAlertMethod(alert)">{{alert}}</a>
              </li>
            }
          </ul>
        </span>
      </span>
    }


  <!-- Templates for Alert Recipient Dropdown -->
  <ng-template #tplAlertRecipient>

    @if (!_rule.editing) {
      <span class="condition-field d-inline-block text-nowrap">
        {{ _rule.ruleActions[0].alertRecipientType.replaceAll(',', ', ') }}
      </span>
    }

    <!-- Adding or Editing -->
    @if (_rule.editing) {
      <div class="d-inline-block clickable">
        @for (recipient of listRecipients; track recipient; let i = $index) {
          <div class="dropdown alertRecipient condition-field d-inline-block mr-1 clickable" dropdown #ddAlertRecipient="bs-dropdown" container="body" [insideClick]="true"
            [isDisabled]="!_rule.editing" [class.open]="ddAlertRecipient.isOpen">
            <div dropdownToggle class="cursor-pointer w-100 position-relative d-flex align-items-center clickable">

              @if (recipient?.value === 'Select user') {
                <i class="fa fa-xmark mr-auto grey4 fa-sm font-weight-normal position-relative clickable" [style]="!ddAlertRecipient.isOpen ? 'top:1px' : ''"
                [class.fa-rotate-180]="ddAlertRecipient.isOpen" [class.d-none]="i===0" (click)="removeNewRecipient(i)"></i>
              }

              <span class="pr-4 nav-font14 my-1 d-flex-inline selectedAlertRecipient text-truncate clickable"
                  [tooltip]="recipient?.type === 'email' ? recipient.label : ''"
                  [ngClass]="recipient?.value === 'Select user' ? 'grey3' : 'grey5'">
                {{ recipient?.value }}
              </span>

              @if (recipient?.value === 'Select user') {
                <i class="fak fa-down-arrow fa-xs ml-auto grey4 clickable" [class.fa-rotate-180]="ddAlertRecipient.isOpen"></i>
              } @else {
                <i class="fa fa-xmark ml-auto grey4 fa-sm font-weight-normal clickable" (click)="removeNewRecipient(i)"></i>
              }

            </div>
            <ul *dropdownMenu class="dropdown-menu dropdown-recipient nav-font14 py-0" role="menu">
              @for (option of recipient.options; track option ) {
                <li role="menuitem" class="mx-1 my-1 cursor-pointer clickable">
                  <a class="dropdown-item px-1 pt-2 cursor-pointer d-block border-bottom text-truncate clickable"
                    container="body"
                    [tooltip]="option?.label.includes('@') ? option?.label : ''"
                     (click)="applyAlertRecipient(recipient, option); ddAlertRecipient.hide()">
                    {{ option?.label }}
                  </a>
                </li>
              }

              <li role="menuitem" class="mx-1 my-1 cursor-pointer clickable" [class.d-none]="!addingEmail">
                <a class="dropdown-item recipientEmail px-1 clickable">
                  <div class="row">
                    <div class="col">
                      <span class="d-block nav-font12 grey4 mb-2 clickable">Type e-mail and click 'Add'</span>
                      <textarea [autosize]="false" rows="2" [(ngModel)]="newRecipient" (keyup)="checkEmail($event);" class="form-control form-control-sm form-control-nav nav-font12 clickable" placeholder="email@address.com"></textarea>
                      <div class="mt-2 d-flex justify-content-end clickable">
                        <button class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold mr-2 clickable" (click)="cancelRecipientEmail()">Cancel</button>
                        <button
                          class="btn shadow-none font-weight-bold nav-font14 pb-1 clickable"
                          [ngClass]="!emailValidator.valid ? 'nav-btn-disabled' : 'nav-btn-accent'"
                          [disabled]="!emailValidator.valid"
                          (click)="addNewRecipientEmail(i); ddAlertRecipient.hide()">Add
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li role="menuitem" class="mx-1 my-1 cursor-pointer clickable" [class.d-none]="addingEmail">
                <a class="dropdown-item px-1 clickable">
                  <div class="col d-flex align-items-center px-0 nav-accent-blue clickable" (click)="addingEmail=true">
                    <i class="far fa-plus pr-2 clickable"></i>
                    <span class="font-weight-bold clickable">Add e-mail</span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        }

        <span class="nav-font14 font-weight-bold d-inline-flex align-items-center mr-2 clickable position-relative" style="top:1px"
            [ngClass]="!canAddNewRecipient ? 'grey2' : 'nav-accent-blue cursor-pointer'"
            (click)="!canAddNewRecipient ? null: addNewRecipient()"
            [attr.disabled]="!canAddNewRecipient ? true : null">
          <i class="fak fa-plus fa-xl mx-2"></i> Add user
        </span>

      </div>
    }
  </ng-template>

  <!-- Template for Alert Method Dropdown -->
  <ng-template #tplAlertMethod>
    @if (!_rule.editing) {
      <span class="condition-field d-inline-block clickable" style="max-width: 260px;" >
        {{ selectedAlertMethod }}
      </span>
    }

    @if (_rule.editing) {
      <div class="dropdown alertMethod condition-field d-inline-block clickable" dropdown #ddAlertMethod="bs-dropdown" container="body" [isDisabled]="!_rule.editing" [class.open]="ddAlertMethod.isOpen">
        <div dropdownToggle class="cursor-pointer w-100 position-relative d-flex align-items-center clickable">
          <span class="pr-4 nav-font14 my-1 d-flex-inline text-truncate clickable" [ngClass]="selectedAlertMethod === 'Select method' ? 'grey3' : 'grey5'">{{ selectedAlertMethod }}</span>
          <i class="fak fa-down-arrow fa-xs ml-auto grey4" [class.fa-rotate-180]="ddAlertMethod.isOpen"></i>
        </div>
        <ul *dropdownMenu class="dropdown-menu dropdown-method nav-font14 py-0" role="menu">
          @for (method of alertMethods; track method) {
            <li role="menuitem" class="mx-1 my-1 cursor-pointer clickable">
              <a class="dropdown-item px-1 pt-2 cursor-pointer d-flex clickable"
                  [class.border-bottom]="($index+1) < alertMethods?.length"
                  [tooltip]="getMethodTooltip(method)"
                  container="body"
                  placement="bottom"
                  [class.slack]="method === 'Slack' && anyWithoutSlackUsername"
                  (click)="(method === 'Slack' && anyWithoutSlackUsername) ? null : applyAlertMethod(method)">{{method}}
              </a>
            </li>
        }
        </ul>
      </div>
    }
  </ng-template>

  <!-- Template for ScopeType Dropdown -->
  <ng-template #tplScopeType>
    @if (!_rule.editing) {
      <span class="condition-field d-inline-block mr-2" style="max-width: 260px;" >
        {{ selectedScope }}
      </span>
    }

    @if (_rule.editing) {
      <div class="dropdown alertScopeType condition-field d-inline-block" dropdown #ddScope="bs-dropdown" container="body" [isDisabled]="!_rule.editing" [class.open]="ddScope.isOpen">
        <div dropdownToggle class="cursor-pointer w-100 position-relative d-flex align-items-center">
          <span class="pr-4 nav-font14 my-1 d-flex-inline text-truncate" [ngClass]="selectedScope === 'Select metric type' ? 'grey3' : 'grey5'">{{ selectedScope }}</span>
          <i class="fak fa-down-arrow fa-xs ml-auto grey4" [class.fa-rotate-180]="ddScope.isOpen"></i>
        </div>
        <ul *dropdownMenu class="dropdown-menu dropdown-scope nav-font14 py-0 scrollbar overflow-y" role="menu">
          @for (scope of scopeTypes; track scope) {
            @if (scope.type === 'Project') {
              <li role="menuitem" class="mx-1 my-1 cursor-pointer">
                <a class="dropdown-item px-1 pt-2 border-bottom cursor-pointer d-flex" (click)="applyScope('Project', scope)">{{scope.label}}</a>
              </li>
            }
            @if (scope.type === 'Vendor') {
              @for (vendor of scope.vendors; track vendor) {
                <li role="menuitem" class="mx-1 my-1 cursor-pointer">
                  <a class="dropdown-item px-1 pt-2 cursor-pointer d-flex" [class.border-bottom]="($index+1) < scope.vendors?.length" (click)="applyScope('Vendor', scope, vendor)">{{vendor.label}}</a>
                </li>
              }
            }
          }
        </ul>
      </div>
    }
  </ng-template>

  <!-- Template for Metrics Dropdown -->
  <ng-template #tplMetrics>
    @if (!_rule.editing) {
      <span class="condition-field d-inline-block" style="max-width: 260px;" >
        {{ selectedMetric }}
      </span>
    }

    @if (_rule.editing) {
      <div class="dropdown alertMetric condition-field d-inline-block ml-1" dropdown #ddMetric="bs-dropdown" container="body" [isDisabled]="!_rule.editing" [class.open]="ddMetric.isOpen">
        <div dropdownToggle class="cursor-pointer w-100 position-relative d-flex align-items-center">
          <span class="pr-4 nav-font14 my-1 d-flex-inline text-truncate" [ngClass]="selectedMetric === 'Select metric' ? 'grey3' : 'grey5'">{{ selectedMetric }}</span>
          <i class="fak fa-down-arrow fa-xs ml-auto grey4" [class.fa-rotate-180]="ddMetric.isOpen"></i>
        </div>
        <ul *dropdownMenu class="dropdown-menu dropdown-metrics nav-font14 py-0" role="menu">
          @for (metric of metricTypes; track metric) {
            @if (metric != 'Quota cells' || _rule?.scopeType === 'Project') {
              <li role="menuitem" class="mx-1 my-1 cursor-pointer">
                <a class="dropdown-item px-1 pt-2 cursor-pointer d-flex" [class.border-bottom]="($index+1) < metricTypes?.length" (click)="applyMetric(metric)">{{metric}}</a>
              </li>
            }
          }
        </ul>
      </div>
    }
  </ng-template>

  <!-- Template for Operators Dropdown -->
  <ng-template #tplOperators>
    @if (selectedMetric === 'Quota cells') {
      <span class="condition-text nav-font14 grey5 position-relative ml-2 font-weight-bold">have reached</span>
    }
    @else {
      @if (!_rule.editing) {
        <span class="condition-field d-inline-block" style="max-width: 260px;" >
          {{ selectedOperator }}
        </span>
      }
  
      @if (_rule.editing) {
        <div class="dropdown alertOperators condition-field d-inline-block" dropdown #ddOperator="bs-dropdown" container="body" [isDisabled]="!_rule.editing" [class.open]="ddOperator.isOpen">
          <div dropdownToggle class="cursor-pointer w-100 position-relative d-flex align-items-center">
            <span class="pr-4 nav-font14 my-1 d-flex-inline text-truncate" [ngClass]="selectedOperator === 'Select' ? 'grey3' : 'grey5'">{{ selectedOperator }}</span>
            <i class="fak fa-down-arrow fa-xs ml-auto grey4" [class.fa-rotate-180]="ddOperator.isOpen"></i>
          </div>
          <ul *dropdownMenu class="dropdown-menu dropdown-operators nav-font14 py-0" role="menu">
            @for (operator of operators; track operator) {
              <li role="menuitem" class="mx-1 my-1 cursor-pointer">
                <a class="dropdown-item px-1 pt-2 border-bottom cursor-pointer d-flex" [class.border-bottom]="($index+1) < operators?.length" (click)="applyOperator(operator)">{{operator.label}}</a>
              </li>
            }
          </ul>
        </div>
      }
    }
  </ng-template>

  <!-- Template for Metric number-->
  <ng-template #tplMetricNumber>
    @if (selectedMetric === 'Quota cells') {
      <span class="condition-text nav-font14 grey5 position-relative mr-2 font-weight-bold"> their limit</span>
    }
    @else {
      <span class="condition-field ml-1" [class.editable]="_rule.editing">
        @if (!_rule.editing) {
          <span class="condition-field d-inline-block mr-1" style="max-width: 260px;">{{selectedMetricNumber.label}}@if (selectedMetricNumber.value > -1) {
            {{selectedMetricNumber.suffix}}
          }</span>
        }
        @if (_rule.editing) {
          <div class="d-inline">
            <input type="text" mask="99999"
              [patterns]="integerPattern"
              [(ngModel)]="selectedMetricNumber.label"
              (mouseleave)="handleFields('number');"
              (blur)="handleFields('number');"
              (focus)="$event.target.select()"
              class="form-control form-control-nav form-control-sm d-inline text-center">
            {{selectedMetricNumber.suffix}}
          </div>
        }
      </span>
    }
    
  </ng-template>

  <!-- Template for Date & Time -->
  <ng-template #tplDateTime>

    <span class="condition-text nav-font14 grey5 position-relative font-weight-bold" [ngClass]="_rule.editing ? 'mx-1' : 'mr-1'">  on  </span>

    <!-- Date -->
    <span class="condition-field" [class.editable]="_rule.editing">
      @if (!_rule.editing) {
        <span class="condition-field d-inline-block">{{selectedScheduledDate.label}}</span>
      }
      @if (_rule.editing) {
        <div class="input-group form-control-date d-inline-flex">
          <input type="text" autocomplete="off"
            (bsValueChange)="selectedScheduledDate.value = $event"
            (onHidden)="applyScheduledDate()"
            (mouseleave)="handleFields('date');"
            (blur)="handleFields('date');"
            bsDatepicker
            readonly
            [bsValue]="selectedScheduledDate.value"
            #dpDate="bsDatepicker"
            [bsConfig]="{ dateInputFormat: 'MM/DD/YY', isAnimated: true, isOpen:true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
            class="form-control nav-font12 date d-inline bg-white px-2">
            <span class="input-group-text bg-white" (click)="dpDate.toggle()"><i class="fak fa-sm fa-calendar grey4"></i></span>
        </div>
      }
    </span>

    <span class="condition-text nav-font14 grey5 position-relative mx-1 font-weight-bold">  at  </span>

    <!-- Time -->
    <span class="condition-field timepicker" [ngClass]="_rule.editing ? 'editable position-relative d-inline-flex' : ''">
      @if (!_rule.editing) {
        <span class="condition-field d-inline-block">{{selectedScheduledTime.label}}</span>
      }
      @if (_rule.editing) {
        <div class="position-relative">
          <timepicker [(ngModel)]="selectedScheduledTime.value"
            (ngModelChange)="applyScheduledDate()"
            [showMeridian]="true"
            (isValid)="isTimepickerValid($event)"
            [showSpinners]="false"
            [minuteStep]="1">
          </timepicker>
        </div>
      }
    </span>

  </ng-template>




