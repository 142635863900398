<div class="shadow-sm mx-2 my-2 p-3">
  @if (mode=='view') {
    <div>
      <div class="mb-2">
        <span class="h6">{{ contact.firstName + ' ' + contact.lastName }}</span>
        <i class="fas fa-edit float-right or-secondary" (click)="enableEdit()"></i>
      </div>
      <div>
        <or-phone [phone]="contact.phone"></or-phone>
      </div>
      <div>
        <or-email-address [emailaddress]="contact.email"></or-email-address>
      </div>
      <div class="">
        <span class=" small text-muted">Last Login: {{contact.lastLogin == "0001-01-01T00:00:00" ? 'Never' : (contact.lastLogin| date :'medium')}}</span>
      </div>
    </div>
  }
  @if (mode=='edit' || mode =='new') {
    <div>
      <form (ngSubmit)="submit(contactForm.form)" (change)="onFormChange()" #contactForm="ngForm" novalidate>
        <div class="form-group">
          <label for="firstName" class="required">First Name</label>
          <input [(ngModel)]="editContact.firstName" required
            [ngClass]="submitted && utils.showCssValidField(contactForm.form, 'firstName')" name="firstName" type="text"
            class="form-control form-control-sm form-control-nav" id="firstName" placeholder="Enter First Name">
          </div>
          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input [(ngModel)]="editContact.lastName" name="lastName" type="text" class="form-control form-control-sm form-control-nav" id="lastName"
              placeholder="Enter Last Name">
            </div>
            <div class="form-group">
              <label for="contactEmail" class="required">Email address</label>
              <input [(ngModel)]="editContact.email" name="email" required email [disabled]="mode=='edit'"
                [ngClass]="submitted && utils.showCssValidField(contactForm.form, 'email')" type="email" class="form-control form-control-sm form-control-nav"
                id="contactEmail" aria-describedby="emailHelp" placeholder="Enter Email">
              </div>
              <div class="form-group">
                <label for="contactPhone">Phone</label>
                <input [(ngModel)]="editContact.phone" name="phone" type="email" class="form-control form-control-sm form-control-nav" id="contactPhone"
                  placeholder="Enter Phone Number">
                </div>
                @if (showBilling) {
                  <div class="form-group">
                    <label for="contactBilling">Billing</label>
                    <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="editContact.billing" name="billing">
                    </or-toggle-button>
                  </div>
                }
                <div>
                  <div class="float-left">
                    @if (mode=='edit') {
                      <button type="button" (click)="delete()"
                      class="mt-2 btn btn-sm btn-danger mx-1 text-left">Delete</button>
                    }
                  </div>
                  <div class="text-right">
                    <button type="button" (click)="cancel();" class="btn btn-sm nav-btn-primary mx-1 shadow-none">Cancel</button>
                    <button type="submit" class="btn btn-sm nav-btn-accent shadow-none">Save</button>
                  </div>
                </div>
              </form>
            </div>
          }
        </div>
