export interface PublicKey {
  id: string;
  apiKey: string;
  privateApiKey: string;
  name: string;
  startDate: Date;
  endDate: Date;
  hits?: number;
  maxHits: number;
  transactionOffset?: number;
  hitsRemaining?: number;
  urlWhitelist: string;
  enabled: boolean;
  instanceId: string;
  scope: string;
}
