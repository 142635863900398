
export interface PartnerRateCard {
  id?: string;
  partnerId: string;
  name: string;
  defaultCPI?: number;
  enabled?: boolean;
  rateTable?: PartnerRateCardTable;
}

export interface PartnerRateCardTable {
  rows: number[];                  // Array of LOI values
  cols: number[];                  // Array of IR values
  matrix: (number | null)[][];     // 2D array for CPI values at intersections of LOI and IR
}


