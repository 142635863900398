import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'or-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @Input() values = [];
  @Input() value: string;
  @Input() text: string = '';
  @Input() showBtn = true;
  @Input() addClickableClass = false;

  @Output() selected = new EventEmitter();

  @ViewChild('dropdown', {static: false}) dropdown;

  constructor() { }

  ngOnInit() {
  }

  toggleDropdown(event) {
    this.dropdown.toggle(true);
  }
  getCurrent(){
    if(this.values == null || this.values.length ==0 || this.value == null) return '';

    const selected = this.values.filter(e=> e.value == this.value);
    if(selected.length == 0) return '';
    else return selected[0].name;
  }
  applyValue(event, newValue) {
    newValue.origValue = this.value;
    this.value = newValue.value;
    this.selected.emit(newValue);
    this.dropdown.toggle(true);
  }

}
