<div class="" [ngClass]="selectedType == null ? 'card' : ''">
  @if (selectedType == null) {
    <div class="card-header text-white or-primary-bg">
      <p class="h4 inline float-left" [ngClass]="selectedType == null ? '' : 'm-2'"><em class="fa fa-cog fa-sm"></em> Project Types</p>
      <div class="float-right">
        <div class="form-inline form">
          @if (selectedType == null) {
            <button type="button" (click)="addProjectType()" class="btn btn-sm btn-outline-light inline"><em class="fa fa-plus-circle"></em> Add Project Type</button>
          }
        </div>
      </div>
    </div>
  }
  <div [ngClass]="selectedType == null ? 'card-body' : ''">
    @if (selectedType == null) {
      <div class="mt-0">
        <or-list [items]="projectTypes" [layout]="layout" [actions]="actions" [enableExport]=false (action)="handleAction($event)"></or-list>
      </div>
    }

    <form #typesForm="ngForm" novalidate>
      @if (selectedType != null) {
        <div class="d-flex mb-3 mt-2 border-bottom ml-2">
          <div class="sectionbtn menu-item mt-1 pb-1 mx-3" [class.active]="tab == 'main-details'">
            <span class=" cursor-pointer px-0"
            (click)="tab = 'main-details'">Main Details </span>
          </div>
          <div class="sectionbtn menu-item mt-1 pb-1 mx-3" [class.active]="tab == 'sections'" >
            <span class=" cursor-pointer px-0"
            (click)="tab = 'sections'">Sections </span>
          </div>
          <div class="sectionbtn menu-item mt-1 pb-1 mx-3" [class.active]="tab == 'stages'" >
            <span class=" cursor-pointer px-0"
            (click)="tab = 'stages'">Stages and tasks </span>
          </div>
          <div class="sectionbtn menu-item mt-1 pb-1 mx-3" [class.active]="tab == 'homepage'" >
            <span class=" cursor-pointer px-0"
            (click)="tab = 'homepage'">Homepage </span>
          </div>
        </div>
        @if (tab == 'main-details') {
          <app-project-types-main-details [selectedType]="selectedType" [healthConfigs]="healthConfigs" [securityConfigs]="secretKeys"></app-project-types-main-details>
        }
        @if (tab == 'sections') {
          <app-project-types-sections [sections]="selectedType.sections" [stages]="selectedType.stages" [roles]="roles"></app-project-types-sections>
        }
        @if (tab == 'stages') {
          <app-project-types-stages [stages]="selectedType.stages"></app-project-types-stages>
        }
        @if (tab == 'homepage') {
          <app-project-types-homepage [stages]="selectedType.stages"></app-project-types-homepage>
        }
      }
    </form>
  </div>
  @if (selectedType != null) {
    <div class="card-footer text-right">
      <div class="btn btn-light mr-2" (click)="cancelEdit()" type="button">Cancel</div>
      <button class="btn or-primary-bg text-white" (click)="saveProjectType()">Save</button>
    </div>
  }
</div>

<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Are you sure you want to delete the Project Type '{{this.deleteType.name}}'?</p>
    <p class="h6">This will no longer allow you to create or open these projects.</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
  </div>
</ng-template>
