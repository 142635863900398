import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'or-listbox',
  templateUrl: './list-box.component.html',
  styleUrls: ['./list-box.component.scss']
})
export class ListBoxComponent {
  @Input() public set showCount(show: boolean) { this._showCount = show; }
  @Input() public set headers(data) { this._headers = data; }
  @Input() public set items(data) {
    this._data = data;
    this.handleLoading();
  }

  @Input() public set loadSelected(data) {
    this._selectedFields = data ?? [];
    this.handleLoading();
  }

  @Output() selected = new EventEmitter();

  _showCount: boolean = false;
  _data = [];
  _headers = [];
  _distinctParents = [];
  _selectedParents = [];
  _selectedFields = [];
  _unselectedFields = [];

  constructor() { }

  ngOnInit() {
    this.handleLoading();
  }

  handleLoading() {
    if (this._headers.length === 2) {
      this._distinctParents = [];
      this.manageFields();
    } else if (this._headers.length === 3) {
      this._distinctParents = [...new Set(this._data.map(item => item?.parent))];
      if (this._distinctParents[0] === undefined) this._distinctParents = [];
    }
  }

  handleParent(parent) {
    this._selectedParents.includes(parent)
      ? this._selectedParents = this._selectedParents.filter(x => x !== parent)
      : this._selectedParents.push(parent);
    this.manageParentFields();
  }
  manageParentFields() {
    let list = [];
    for (let index in this._data) {
      if (this._selectedParents.includes(this._data[index].parent)) {
        if(this._selectedFields.filter(x => x.id === this._data[index].id).length <= 0) {
          list.push(this._data[index])
        }
      }
    }
    this._unselectedFields = list;
  }
  manageFields() {
    let list = [];
    for (let index in this._data) {
      if(this._selectedFields.filter(x => x.id === this._data[index].id).length <= 0) {
        list.push(this._data[index])
      }
    }
    this._unselectedFields = list;
  }
  addRemoveField(fieldId, action) {
    switch (action) {
      case 'add':
        this._selectedFields.push(this._data.find(x => x.id === fieldId));
        this._unselectedFields = this._unselectedFields.filter(x => x.id !== fieldId);
        break;
      case 'remove':
        this._selectedFields = this._selectedFields.filter(x => x.id !== fieldId);
        this._distinctParents.length > 0
          ? this.manageParentFields()
          : this.manageFields();
        break;
      default: 
      break;
    }
    this.selected.emit(this._selectedFields);
  }
}
