import { Pipe, PipeTransform } from '@angular/core';
import * as diff from 'diff';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'compareText'
})
export class CompareTextPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

   transform(text2: string, text1: string): any {
    if (!text1) { 
      const temp = document.createElement('div');
      temp.innerHTML = text2;
      return temp.textContent || temp.innerText || ''
    }
    
    const tokenized1 = this.tokenizeHtml(text1);
    const tokenized2 = this.tokenizeHtml(text2);

    const wordDiffs = diff.diffWords(tokenized1.join(' '), tokenized2.join(' '));

    let result = '';
    let openTagStack: string[] = [];

    wordDiffs.forEach((part) => {
      if (part.added) {
        result += this.applyTagStack(openTagStack) + '<ins>' + part.value + '</ins>';
      } else if (part.removed) {
        result += this.applyTagStack(openTagStack) + '<del>' + part.value + '</del>';
      } else {
        result += this.applyTagStack(openTagStack) + part.value;
      }
    });

    return result + this.applyTagStack(openTagStack);
  }

  private tokenizeHtml(html: string): string[] {
    const div = document.createElement('div');
    div.innerHTML = html;

    const tokens: string[] = [];
    const extractTokens = (node: Node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        tokens.push(node.textContent);
      } else {
        for (let i = 0; i < node.childNodes.length; i++) {
          extractTokens(node.childNodes[i]);
        }
      }
    };

    extractTokens(div);

    return tokens.join(' ').split(' ');
  }

  private applyTagStack(tagStack: string[]): string {
    return tagStack.reverse().join('');
  }

}
