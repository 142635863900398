<div class="card">
  <div class="card-header or-primary-bg text-white">
    <p class="h4 inline float-left"><em class="fa fa-chart-line fa-sm"></em> Project Health Configuration</p>
    <div class="float-right">
      <div class="form-inline form">
        @if (!this.configSelected) {
          <button type="button" (click)="createNewConfig()" class="btn btn-sm btn-outline-light inline"><em class="fa fa-plus-circle"></em> Add Config</button>
        }
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="mt-0">
      <div class="row">
        <div class="col-lg-12 col-xl-12">

          @if (!this.configSelected) {
            <div class="table-responsive">
              <or-list [items]="this.configs" [layout]="layout" [actions]="actions" [enableExport]="false" (action)="handleAction($event)"></or-list>
            </div>
          }


          @if (this.configSelected) {
            <div class=" mainCard pt-4 ml-5 mr-5">
              <div class="row pb-3">
                <div class="col-md-4 col-sm-12 form-group">
                  <label for="projectName" class="required">Name </label>
                  <input type="text" [(ngModel)]="this.configName" (ngModelChange)="configNameChange()" maxlength=40 name="configName" id="projectName" class="form-control form-control-sm" [ngClass]="!nameValid && utils.showCssValidField(editForm, 'configName')">
                  <div _ngcontent-ihk-c127="" class="invalid-feedback">Please enter name.</div>
                </div>
                <div class="col-md-5 col-sm-12  mr-1 form-group">
                  <label for="projectDesc">Description </label>
                  <input type="text" [(ngModel)]="this.configDesc" maxlength=100 name="configDesc" id="projectDesc" class="form-control form-control-sm">
                </div>
                <div class="col-md-3 float-right col-sm-12 mt-4 p-0 pt-1 pl-2" style="max-width:160px;">
                  <button class="cancelBtn btn btn-sm btn-outline-secondary mr-2" (click)="cancelEdit()" type="button">Close</button>
                  <button class="saveBtn btn btn-sm or-primary-bg text-white" type="submit" (click)="saveHealthConfig()">Save Config</button>
                </div>
              </div>
              <div class="d-flex mb-3 mt-1 border-bottom ml-5 mr-5">
                <div class="sectionbtn nav-item">
                  <a class="nav-link or-primary mt-1 cursor-pointer" [class.font-weight-bold]="tabselector == 'tab1'" (click)="renderGeneral()">General</a>
                </div>
                <div class="sectionbtn nav-item">
                  <a class="nav-link or-primary mt-1 cursor-pointer" [class.font-weight-bold]="tabselector == 'tab2'" (click)="renderIR()">IR</a>
                </div>
                <div class="sectionbtn nav-item">
                  <a class="nav-link or-primary mt-1 cursor-pointer" [class.font-weight-bold]="tabselector == 'tab3'" (click)="renderLOI()">LOI</a>
                </div>
                <div class="sectionbtn nav-item">
                  <a class="nav-link or-primary mt-1 cursor-pointer" [class.font-weight-bold]="tabselector == 'tab4'" (click)="renderTest()">Test Settings</a>
                </div>
              </div>
              @if (tabselector == 'tab1') {
                <ng-container class="tabs">
                  <div class="row GeneralSettingsRow ml-5">
                    <div class="col-md-3 col-sm-12 mt-4">
                      <div class="row mb-3 float-right pr-3">
                        <table>
                          <thead><th></th><th style="width: 50%"></th></thead>
                          <tbody>
                            <tr>
                              <td class="text-right pr-3"># Min Starts</td>
                              <td  class="float-right">
                                <div class="input-group">
                                  <input maxlength="7"
                                    (keypress)="numberOnly('percent', starts, $event)"
                                    type="text"
                                    name="minStart"
                                    [(ngModel)]="starts[0].value"
                                    class="form-control form-control-sm text-right"
                                    placeholder="# of starts">
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="row pt-3 float-right pr-3 borderTop">
                          <table>
                            <thead>
                              <tr>
                                <th></th>
                                <th style="width:30%" class="tableHeading text-center text-secondary">Penalty</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="text-right pr-3">Past Deadline:</td>
                                <td class="float-right">
                                  <div class=" mb-2">
                                    <div class="input-group ">
                                      <input
                                        maxlength="3"
                                        (keypress)="numberOnly('penalty', pace[0].pastDead, $event)"
                                        type="text"
                                        [(ngModel)]="pace[0].pastDead"
                                        (ngModelChange)="updateElement('pace', 0, 'pastDead', $event)"
                                        class="form-control form-control-sm text-right">
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-right pr-3">Negative Pace:</td>
                                  <td>
                                    <div class="input-group ">
                                      <input
                                        maxlength="3"
                                        (keypress)="numberOnly('penalty', pace[0].negPace, $event)"
                                        type="text" name="negPace"
                                        [(ngModel)]="pace[0].negPace"
                                        (ngModelChange)="updateElement('pace', 0, 'negPace', $event)"
                                        class="form-control form-control-sm text-right">
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="col-md-5 col-sm-12 mb-5 mt-2 ml-5">
                            <accordion  [isAnimated]="true">
                              <accordion-panel
                                class="firstTab"
                                [(isOpen)]="this.openAccordions[0].open">
                                <div accordion-heading>
                                  <div class="row">
                                    <div class="col settingTitle pl-4">Duplicates</div>
                                    <div class="col text-right">
                                      @if (this.openAccordions[0].open) {
                                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                                      }
                                      @if (!this.openAccordions[0].open) {
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div class="card-body pl-0 pr-0">
                                  <div class="mt-0">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th style="width:30%" class="pl-3 tableHeading text-center text-secondary">%</th>
                                          <th style="width:10%" class=" pl-3 tableHeading text-center text-secondary">Penalty</th>
                                          <th style="width: 20%"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        @for (element of dupes; track element; let i = $index) {
                                          <ng-container class="row mb-2">
                                            <tr>
                                              <td class="pb-2">
                                                <div class="pl-3 input-group input-group-sm">
                                                  <input
                                                    (keypress)="numberOnly('percent', element.percentage, $event)" type="text"
                                                    maxlength="5"
                                                    name="dupePercent"
                                                    [(ngModel)]=element.percentage
                                                    (ngModelChange)="updateElement('dupes', i, 'percentage', $event)"
                                                    class="form-control form-control-sm">
                                                  </div>
                                                </td>
                                                <td class="pb-2">
                                                  <div class="pl-3 input-group input-group-sm">
                                                    <input
                                                      maxlength="3"
                                                      (keypress)="numberOnly('penalty', element.value, $event)"
                                                      type="text"
                                                      name="dupePenalty"
                                                      [(ngModel)]=element.value
                                                      (ngModelChange)="updateElement('dupes', i, 'value', $event)"
                                                      class="form-control form-control-sm">
                                                    </div>
                                                  </td>
                                                  <td class="pb-2">
                                                    @if (i != 0) {
                                                      <div class="col-1 pl-2 pt-1">
                                                        <button class="btn btn-secondary removeBtn" (click)="removeElement('dupes', i)"><i class="fa fa-times"></i></button>
                                                      </div>
                                                    }
                                                  </td>
                                                </tr>
                                              </ng-container>
                                            }
                                          </tbody>
                                        </table>
                                        <div class="row">
                                          <div class="col-10 text-right pr-0 ml-3">
                                            <button class="btn  btn-outline-secondary btn-xs inline" type="button" (click)="appendElement('dupes')">+ ADD</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </accordion-panel>
                                  <accordion-panel
                                    [(isOpen)]="this.openAccordions[1].open">
                                    <div accordion-heading>
                                      <div class="row">
                                        <div class="col settingTitle pl-4 ">Overquota</div>
                                        <div class="col text-right">
                                          @if (this.openAccordions[1].open) {
                                            <i class="fa fa-angle-up" aria-hidden="true"></i>
                                          }
                                          @if (!this.openAccordions[1].open) {
                                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div class="card-body pl-0 pr-0">
                                      <div class="mt-0">
                                        <table>
                                          <thead>
                                            <tr>
                                              <th style="width:30%" class="pl-3 tableHeading text-center text-secondary">#</th>
                                              <th style="width:10%" class=" pl-3 tableHeading text-center text-secondary">Penalty</th>
                                              <th style="width: 20%"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            @for (element of oq; track element; let i = $index) {
                                              <ng-container class="row mb-2">
                                                <tr>
                                                  <td class="pb-2">
                                                    <div class="pl-3 input-group input-group-sm">
                                                      <input
                                                        (keypress)="numberOnly('percent', element.percentage, $event)"
                                                        type="text"
                                                        maxlength="5"
                                                        name="dupePercent"
                                                        [(ngModel)]=element.percentage
                                                        (ngModelChange)="updateElement('oq', i, 'percentage', $event)"
                                                        class="form-control form-control-sm"
                                                        >
                                                      </div>
                                                    </td>
                                                    <td class="pb-2">
                                                      <div class="pl-3 input-group input-group-sm">
                                                        <input
                                                          maxlength="3"
                                                          min="0"
                                                          max="10"
                                                          (keypress)="numberOnly('penalty', element.value, $event)"
                                                          type="text"
                                                          name="oqPenalty"
                                                          [(ngModel)]=element.value
                                                          (ngModelChange)="updateElement('oq', i, 'value', $event)"
                                                          class="form-control form-control-sm">
                                                        </div>
                                                      </td>
                                                      <td class="pb-2">
                                                        @if (i != 0) {
                                                          <div class="col-1 pl-2 pt-1">
                                                            <button class="btn btn-secondary removeBtn" (click)="removeElement('oq', i)"><i class="fa fa-times"></i></button>
                                                          </div>
                                                        }
                                                      </td>
                                                    </tr>
                                                  </ng-container>
                                                }
                                              </tbody>
                                            </table>
                                            <div class="row">
                                              <div class="col-10 text-right pr-0 ml-3">
                                                <button class="btn  btn-outline-secondary btn-xs inline" type="button" (click)="appendElement('oq')">+ ADD</button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </accordion-panel>
                                      <accordion-panel
                                        [(isOpen)]="this.openAccordions[2].open">
                                        <div accordion-heading>
                                          <div class="row">
                                            <div class="col settingTitle pl-4">DOR</div>
                                            <div class="col text-right">
                                              @if (this.openAccordions[2].open) {
                                                <i class="fa fa-angle-up" aria-hidden="true"></i>
                                              }
                                              @if (!this.openAccordions[2].open) {
                                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                              }
                                            </div>
                                          </div>
                                        </div>
                                        <div class="card-body pl-0 pr-0">
                                          <div class="mt-0">
                                            <table>
                                              <thead>
                                                <tr>
                                                  <th style="width:30%" class="pl-3 tableHeading text-center text-secondary">%</th>
                                                  <th style="width:10%" class=" pl-3 tableHeading text-center text-secondary">Penalty</th>
                                                  <th style="width: 20%"></th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                @for (element of dor; track element; let i = $index) {
                                                  <ng-container class="row mb-1">
                                                    <tr>
                                                      <td class="pb-2">
                                                        <div class="pl-3 input-group input-group-sm">
                                                          <input
                                                            (keypress)="numberOnly('percent', element.percentage, $event)"
                                                            type="text"
                                                            maxlength="3"
                                                            name="dorPercent"
                                                            [(ngModel)]=element.percentage
                                                            (ngModelChange)="updateElement('dor', i, 'percentage', $event)"
                                                            class="form-control form-control-sm">
                                                            <div class="input-group-append">
                                                              <span class="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td class="pb-2">
                                                          <div class="pl-3 input-group input-group-sm">
                                                            <input
                                                              maxlength="3"
                                                              (keypress)="numberOnly('penalty', element.value, $event)"
                                                              type="text" name="dorPenalty"
                                                              [(ngModel)]=element.value
                                                              (ngModelChange)="updateElement('dor', i, 'value', $event)"
                                                              class="form-control form-control-sm">
                                                            </div>
                                                          </td>
                                                          @if (i != 0) {
                                                            <td class="col-1 pl-2 pt-1 pb-2">
                                                              <button class="btn btn-secondary removeBtn" (click)="removeElement('dor', i)"><i class="fa fa-times"></i></button>
                                                            </td>
                                                          }
                                                        </ng-container>
                                                      }
                                                    </tbody>
                                                  </table>
                                                  <div class="row">
                                                    <div class="col-10 text-right pr-0 ml-3">
                                                      <button class="btn  btn-outline-secondary btn-xs inline" type="button" (click)="appendElement('dor')">+ ADD</button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </accordion-panel>
                                            <accordion-panel
                                              class="lastTab"
                                              [(isOpen)]="this.openAccordions[3].open">
                                              <div accordion-heading>
                                                <div class="row">
                                                  <div class="col settingTitle pl-4">QC</div>
                                                  <div class="col text-right">
                                                    @if (this.openAccordions[3].open) {
                                                      <i class="fa fa-angle-up" aria-hidden="true"></i>
                                                    }
                                                    @if (!this.openAccordions[3].open) {
                                                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="card-body pl-0 pr-0">
                                                <div class="mt-0">
                                                  <table>
                                                    <thead>
                                                      <tr>
                                                        <th style="width:30%" class="pl-3 tableHeading text-center text-secondary">%</th>
                                                        <th style="width:10%" class=" pl-3 tableHeading text-center text-secondary">Penalty</th>
                                                        <th style="width: 20%"></th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      @for (element of qc; track element; let i = $index) {
                                                        <ng-container class="row mb-2">
                                                          <tr>
                                                            <td class="pb-2">
                                                              <div class="pl-3 input-group input-group-sm">
                                                                <input
                                                                  (keypress)="numberOnly('percent', element.percentage, $event)"
                                                                  type="text"
                                                                  maxlength="3"
                                                                  name="dupePercent"
                                                                  [(ngModel)]=element.percentage
                                                                  (ngModelChange)="updateElement('qc', i, 'percentage', $event)"
                                                                  class="form-control form-control-sm">
                                                                  <div class="input-group-append">
                                                                    <span class="input-group-text">%</span>
                                                                  </div>
                                                                </div>
                                                              </td>
                                                              <td class="pb-2">
                                                                <div class="pl-3 input-group input-group-sm">
                                                                  <input
                                                                    [(ngModel)]=element.value (ngModelChange)="updateElement('qc', i, 'value', $event)"
                                                                    maxlength="3"
                                                                    (keypress)="numberOnly('penalty', element.value, $event)" type="text"
                                                                    name="dupePenalty"
                                                                    class="form-control form-control-sm">
                                                                  </div>
                                                                </td>
                                                                @if (i != 0) {
                                                                  <td class="col-1 pl-2 pt-1">
                                                                    <button class="btn btn-secondary removeBtn" (click)="removeElement('qc', i)"><i class="fa fa-times"></i></button>
                                                                  </td>
                                                                }
                                                              </tr>
                                                            </ng-container>
                                                          }
                                                        </tbody>
                                                      </table>
                                                      <div class="row">
                                                        <div class="col-10 text-right pr-0 ml-3">
                                                          <button class="btn  btn-outline-secondary btn-xs inline" type="button" (click)="appendElement('qc')">+ ADD</button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </accordion-panel>
                                              </accordion>
                                            </div>
                                          </div>
                                        </ng-container>
                                      }
                                      @if (tabselector == 'tab2') {
                                        <or-data-grid [sign]="irSign" [x_label]="irXLabel" [y_label]="irYLabel" [type]="irType" [data]="ir"></or-data-grid>
                                      }
                                      @if (tabselector == 'tab3') {
                                        <or-data-grid [sign]="loiSign"[x_label]="loiXLabel" [y_label]="loiYLabel" [type]="loiType" [data]="loi"></or-data-grid>
                                      }
                                      @if (tabselector == 'tab4') {
                                        <div class="row mt-4 mb-3">
                                          <div class="col-1"></div>
                                          <div class="col-1 pr-0 mr-0" style="min-width:100px; max-width: 100px" >
                                            <label>Total Score:</label>
                                          </div>
                                          <div class="col-1 d-flex" style="min-width:80px; max-width: 80px">
                                            <div class="input-group input-group-sm">
                                              <input type="number" [(ngModel)]="totalTestScore" readonly class="form-control form-control-sm">
                                            </div>
                                          </div>
                                        </div>
                                        <div class="row tests mr-5 ml-5">
                                          @for (test of tests; track test; let i = $index) {
                                            <div class="mb-4" style="width:240px;">
                                              @if (test.testType == 'IR' || test.testType == 'LOI') {
                                                <div>
                                                  <div class="card-header p-0 mt-3">
                                                    <p class="h5 text-secondary">{{test.testType}}</p>
                                                  </div>
                                                  <hr>
                                                    <div class="card-body mb-3 mt-3 p-0">
                                                      <div class="row">
                                                        <div class="col-3 text-right" >
                                                          <label>Expected:</label>
                                                        </div>
                                                        @if (test.testType == 'IR') {
                                                          <div class="col-5">
                                                            <div class="input-group input-group-sm">
                                                              <input
                                                                (keypress)="numberOnly('percent', test.expected, $event)"
                                                                type="text"
                                                                maxlength="5"
                                                                class="form-control form-control-sm form-control-sm"
                                                                [(ngModel)]=test.expected (ngModelChange)="setTestValues(i, 'expected', $event)">
                                                                <div class="input-group-append">
                                                                  <span class="input-group-text">{{irSign}}</span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          }
                                                          @if (test.testType == 'LOI') {
                                                            <div class="col-5 pr-0">
                                                              <div class="input-group input-group-sm">
                                                                <input
                                                                  style=" border-bottom-right-radius: 0; border-top-right-radius: 0;"
                                                                  (keypress)="numberOnly('percent', test.expected, $event)"
                                                                  type="text"
                                                                  maxlength="5"
                                                                  class="form-control form-control-sm form-control-sm"
                                                                  [(ngModel)]=test.expected (ngModelChange)="setTestValues(i, 'expected', $event)"
                                                                  >
                                                                  @if (test.testType == 'LOI') {
                                                                    <div class="input-group-append">
                                                                      <span class="input-group-text" style="padding-left: 2px; padding-right: 4px; font-size:80%; padding-top: 7px;">{{loiSign}}</span>
                                                                    </div>
                                                                  }
                                                                </div>
                                                              </div>
                                                            }
                                                          </div>
                                                          <div class="row mt-1">
                                                            <div class="col-3 text-right">
                                                              <label>Actual:</label>
                                                            </div>
                                                            @if (test.testType == 'IR') {
                                                              <div class="col-5">
                                                                <div class="input-group input-group-sm">
                                                                  <input
                                                                    (keypress)="numberOnly('percent', test.actual, $event)"
                                                                    type="text"
                                                                    maxlength="5"
                                                                    class="form-control form-control-sm form-control-sm"
                                                                    [(ngModel)]=test.actual (ngModelChange)="setTestValues(i, 'actual', $event)">
                                                                    <div class="input-group-append">
                                                                      <span class="input-group-text">{{irSign}}</span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              }
                                                              @if (test.testType == 'LOI') {
                                                                <div class="col-5 pr-0">
                                                                  <div class="input-group input-group-sm">
                                                                    <input
                                                                      (keypress)="numberOnly('percent', test.actual, $event)"
                                                                      type="text"
                                                                      maxlength="5"
                                                                      class="form-control form-control-sm form-control-sm"
                                                                      [(ngModel)]=test.actual (ngModelChange)="setTestValues(i, 'actual', $event)"
                                                                      >
                                                                      @if (test.testType == 'LOI') {
                                                                        <div class="input-group-append">
                                                                          <span class="input-group-text" style="padding-left: 2px; padding-right: 4px; font-size:80%; padding-top: 7px;">{{loiSign}}</span>
                                                                        </div>
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                }
                                                              </div>
                                                              <div class="row mt-1">
                                                                <div class="col-3">
                                                                  <label>Score:</label>
                                                                </div>
                                                                <div class="col-1" style="min-width:30%">
                                                                  <div class="input-group input-group-sm">
                                                                    <input type="number" [(ngModel)]=test.score readonly class="form-control form-control-sm">
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        }
                                                        @if (test.testType != 'Starts' && test.testType != 'IR' && test.testType != 'LOI' && test.testType != 'Pace') {
                                                          <div>
                                                            <div class="card-header p-0 mt-3">
                                                              <p class="h5 text-secondary">{{test.testType}}</p>
                                                            </div>
                                                            <hr>
                                                              <div class="card-body mb-3 mt-3 p-0">
                                                                <div class="row">
                                                                  <div class="col-3 text-left" >
                                                                    @if (test.testType == 'QC' || test.testType == 'DOR' || test.testType == 'Duplicates') {
                                                                      <label>Rate:</label>
                                                                    }
                                                                    @if (test.testType == 'Overquota') {
                                                                      <label>Number:</label>
                                                                    }
                                                                  </div>
                                                                  <div class="col-5">
                                                                    <div class="input-group input-group-sm">
                                                                      <input
                                                                        (keypress)="numberOnly('percent', test.rate, $event)"
                                                                        type="text"
                                                                        maxlength="5"
                                                                        class="form-control form-control-sm form-control-sm"
                                                                        [(ngModel)]=test.rate
                                                                        (ngModelChange)="setTestValues(i, 'rate', $event)">
                                                                        @if (test.testType == 'QC' || test.testType == 'DOR' || test.testType == 'Duplicates') {
                                                                          <div class="input-group-append">
                                                                            <span class="input-group-text">%</span>
                                                                          </div>
                                                                        }
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div class="row mt-1">
                                                                    <div class="col-3 text-left">
                                                                      <label>Score:</label>
                                                                    </div>
                                                                    <div class="col-1" style="min-width:30%">
                                                                      <div class="input-group input-group-sm">
                                                                        <input type="number" [(ngModel)]=test.score readonly class="form-control form-control-sm">
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            }
                                                            @if (test.testType == 'Pace') {
                                                              <div>
                                                                <div class="card-header p-0 mt-3">
                                                                  <p class="h5 text-secondary">{{test.testType}}</p>
                                                                </div>
                                                                <hr>
                                                                  <div class="card-body mb-3 mt-3 p-0">
                                                                    <div class="row">
                                                                      <div class="col-5 mr-0 pr-0 text-left" >
                                                                        <label>Past Deadline:</label>
                                                                      </div>
                                                                      <div class="col-5">
                                                                        <div>
                                                                          <input type="checkbox" class=" --or-primary m-1" [(ngModel)]=test.pastDead (ngModelChange)="setTestValues(i, 'pastDead', $event)">
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <div class="row mt-1">
                                                                      <div class="col-5 pr-0 text-left" >
                                                                        <label>Negative Pace:</label>
                                                                      </div>
                                                                      <div class="col-5">
                                                                        <div>
                                                                          <input type="checkbox" class="m-1" [(ngModel)]=test.negPace (ngModelChange)="setTestValues(i, 'negPace', $event)">
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <div class="row mt-1">
                                                                      <div class="col-5 text-left">
                                                                        <label>Score:</label>
                                                                      </div>
                                                                      <div class="col-1" style="min-width:30%">
                                                                        <div class="input-group input-group-sm">
                                                                          <input type="number" [(ngModel)]=test.score readonly class="form-control form-control-sm">
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              }
                                                              @if (test.testType == 'Starts') {
                                                                <div>
                                                                  <div class="card-header p-0 mt-3">
                                                                    <p class="h5 text-secondary">{{test.testType}}</p>
                                                                  </div>
                                                                  <hr>
                                                                    <div class="card-body mb-3 mt-3 p-0">
                                                                      <div class="row">
                                                                        <div class="col-3 text-left" >
                                                                          <label>Starts:</label>
                                                                        </div>
                                                                        <div class="col-4">
                                                                          <div class="input-group input-group-sm">
                                                                            <input type="number" class="form-control form-control-sm form-control-sm" [(ngModel)]=test.value (ngModelChange)="setTestValues(i, 'value', $event)">
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                }
                                                              </div>
                                                            }
                                                          </div>
                                                        }
                                                      </div>
                                                    }

                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
















