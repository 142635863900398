


<div class="card m-3">
  <div class="card-header text-white or-primary-bg">
    <p class="h4 inline"><em class="fa fa-project-diagram fa-sm"></em> Edit Quota Question</p>
  </div>
  <div class="card-body pl-5 pr-5">
    <form [formGroup]="questionForm" (ngSubmit)="questionUpdate()" novalidate>
      <input type="hidden" formControlName="id"/>
      <input type="hidden" formControlName="instanceId"/>
      <input type="hidden" formControlName="inputType"/>
      <div class="row">
        <div class="col-6 mb-3 mt-3"><h2>{{selectedType != 'HouseholdIncome' ? selectedType : 'Household Income'}}</h2></div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12 mb-3">
          <label class="required" for="question" class="required">Question Text</label>
          <input type="text" class="form-control" formControlName="question" id="question" [ngClass]="submitted && utils.showCssValidField(questionForm, 'question')" />
          <div class="invalid-feedback">Please enter question.</div>
        </div>
        <div class="col-md-6 col-sm-12 mb-3">
          <div class="mt-3"><label></label></div>
          <!-- <label for="category">Category</label>
          <input class="form-control" [readonly]="true" formControlName="category" id="category" [value]="model.category">
          <select [disabled]="'disabled'" class="custom-select" formControlName="category" id="category">
            <option *ngFor="let category of categories" [value]="category.value">{{category.name}}</option>
          </select> -->
          <div>
            <button (click)="openPreview()" type="button" class="previewBtn mr-3 align-bottom"><i class="fa fa-solid fa-eye mr-2"></i>Respondent View</button>
          </div>
        </div>
      </div>

      <hr class="my-5">

      <div class="pb-5 px-3">
        <div class="row mb-2">
          <h4 class=" or-secondary font-weight-normal mb-0 mt-1 mr-1">
            URL Demo Identifier
          </h4>
          <div class="col-2 px-3">
            <div class="float-left demoIdentifier input-group">
              <div class="input-group-append">
                <span class="input-group-text px-2">or_</span>
              </div>
              <input type="text" formControlName="urlIdentifier" id="urlIdentifier"
                class=" text-left text-info form-control">
              </div>
            </div>
          </div>
          <div class="row">
            <h5 class="col-12 px-4 py-2 text-secondary font-weight-normal font-italic">
              https://www.survey.com/example1&or1=navigatorID&<span class="text-info font-weight-bold">or_{{questionForm.value.urlIdentifier}}</span>=<span class="text-success font-weight-bold">DemoValue</span>
            </h5>
          </div>
        </div>


        @if (model && model.type != 'Gender') {
          <div class="col-md-8 col-sm-12" >
            @if (model.type == 'HouseholdIncome') {
              <h3 class="mb-4 or-secondary">Default Options</h3>
              <div class="or-secondary p-2 w-75 border defaultOptionsBox row mb-2 ml-3">
                Lowest Value: Less Than {{hhiMin | currency :'USD':'symbol':'1.0-0' }}
              </div>
              <div class="or-secondary p-2 w-75 border defaultOptionsBox row mb-2 ml-3">
                Increments: $5k increments until $100,000. $50k increments after that
              </div>
              <div class="or-secondary p-2 mb-5 w-75 border defaultOptionsBox row mb-2 ml-3">
                Highest Value: {{hhiMax | currency :'USD':'symbol':'1.0-0' }} or More
              </div>
            }
            @if (model.type == 'Age' || model.type=='Division' || model.type == 'Region' || model.type == 'State') {
              <h3 class="mb-4 or-secondary">Default Option</h3>
              @if (model.type == 'Age') {
                <div class="or-secondary p-2 border defaultOptionsBox row mb-5 ml-3">
                  Open date picker or text box accepting dates
                </div>
              }
              @if (model.type == 'Division' || model.type == 'Region' || model.type == 'State') {
                <div class="or-secondary p-2 border defaultOptionsBox row mb-5 ml-3">
                  Open text box accepting US Zip Codes
                </div>
              }
            }
          </div>
        }

        <div class="col-md-8 col-sm-12">
          <h3 class="mb-2 or-secondary">{{(selectedType==='Hispanic' || selectedType==='Ethnicity' || selectedType==='Gender') ? 'Default Options' : 'Default Quotas'}}</h3>
             <div class="row quotaLabels mb-2">
              <div class="col-md-1 arrowBtnDiv"></div>
              <!-- <div *ngIf="selectedType != 'HouseholdIncome'" class="col-md-6 text-center" [ngClass]="{'col-md-6': selectedType == 'Ethnicity' || selectedType == 'Hispanic' || selectedType == 'Gender'}">Values</div>
              <div *ngIf="selectedType == 'HouseholdIncome'" class="col-md-8 text-center">Values</div>  -->
              @if (selectedType != 'HouseholdIncome'&& selectedType != 'Age') {
                <div class="col-md-5" [class.mr-5]="selectedType != 'Region'"></div>
              }
              @else {
                <div class="col-md-2"> </div>
                <div class="col-md-1 betweenMaxMin"> </div>
                <div [ngClass]="selectedType == 'Age' ? 'col-md-2' : 'col-md-4'" class="mr-5"> </div>
              }
              <div class="col-md-2 ml-4 p-0 text-secondary text-left font-weight-bold default">
                Census Values
              </div>
              <div class=" col-md-2 ml-4 p-0 text-left text-success font-weight-bold demo">
                Demo Value
                <span class="float-right pr-1"
                      tooltip={{this.tooltip}}>
                  <i class="fas fa-info-circle fa-xs opacity-40 or-secondary"></i>
                </span>
              </div>
            </div>

          <!-- ETHNICITY, GENDER, HISPANIC OR REGION-->
          @if (selectedType != 'Age' && selectedType != 'HouseholdIncome') {
            @if (selectedType != 'Region') {
              @for (option of questionForm.get('options')['controls']; track option; let i = $index) {
                <div formArrayName="options">
                  @if (option.get('value').value !== 'pnta') {
                    <div [formGroupName]="i" class="row mb-2">
                      <div class="mt-2 col-md-1 arrowBtnDiv">
                        <button tabindex="-1" class="row m-0 mb-2 arrowBtn" type="button" (click)="changeOrder('options', i, -1)"><i class="fas fa-xs fa-chevron-up"></i></button>
                        <button tabindex="-1" class="row m-0 arrowBtn" type="button" (click)="changeOrder('options', i, 1)"><i class="fas fa-xs fa-chevron-down"></i></button>
                      </div>
                      <div class="col-md-5">
                        <input class=" form-control" placeholder="Value" type="text" [required]="true"  formControlName="value" id="value" [ngClass]="submitted && utils.showCssValidField(questionForm.get('options').get(i.toString()), 'value')" />
                      </div>
                      <div class="mt-1 pr-5">
                        <button tabindex="-1" class=" p-0 btn btn-sm removeBtn" (click)="removeOptionClick(i)" type="button"><i class="fas fa-thin fa-times"></i></button>
                      </div>
                      <div class=" default mr-4">
                        <div class="input-group">
                          <input type="text" [required]="true" mask="99999" (change)="calculateTotalPercent()" class="text-left form-control" formControlName="default" id="default" [ngClass]="submitted && utils.showCssValidField(questionForm.get('options').get(i.toString()), 'default')">
                          <div class="input-group-append">
                            <span class="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                      <div class="demo">
                        <div class="input-group">
                          <input type="text" class="text-left form-control" formControlName="demo" id="demo" >
                        </div>
                      </div>
                    </div>
                  }
                </div>
              }
            }
            @else {
              @for (option of questionForm.get('defaults')['controls']; track option; let i = $index) {
                <div formArrayName="defaults">
                  <div [formGroupName]="i" class="row mb-2">
                    <ng-container >
                      <div class="mt-2 col-md-1 arrowBtnDiv">
                        <button tabindex="-1" class="row m-0 mb-2 arrowBtn" type="button" (click)="changeOrder('defaults', i, -1)"><i class="fas fa-xs fa-chevron-up"></i></button>
                        <button tabindex="-1" class="row m-0 arrowBtn" type="button" (click)="changeOrder('defaults', i, 1)"><i class="fas fa-xs fa-chevron-down"></i></button>
                      </div>
                      <div class="col-md-5 col-sm-12 mr-4">
                        <input tabindex="-1" type="text" [readonly]="true" class="form-control" formControlName="value" id="value"/>
                      </div>
                      <div class="default mr-4">
                        <div class="input-group">
                          <input type="text" [required]="true" (change)="calculateTotalPercent()" mask="99999" class="text-left form-control" formControlName="percent" id="percent" [ngClass]="submitted && utils.showCssValidField(questionForm.get('defaults').get(i.toString()), 'percent')">
                          <div class="input-group-append">
                            <span class="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                      <div class="demo">
                        <div class="input-group">
                          <input tabindex="-1" type="text" class="text-left form-control" [readonly]="true">
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              }
            }
            <div class="row mb-2" >
              <div class="mt-2 col-md-1 arrowBtnDiv">
              </div>
              <div class="col-md-5">
              </div>
              <div class="mt-1" [class.pr-5]="selectedType != 'Region'">
                <div style="width:23.777px"></div>
              </div>
              <div class=" default mr-4">
                <div class="input-group">
                  <input tabindex="-1" type="text"  readonly mask="99999" class="text-left form-control" [ngModel]="questionTotal" [ngModelOptions]="{standalone: true}" >
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>
              <div class="demo">
              </div>
            </div>
          }
        
          <!-- HHI OR AGE -->
          @if (selectedType == 'HouseholdIncome' || selectedType == 'Age') {
            @for (option of questionForm.get('defaults')['controls']; track option; let idx = $index) {
              <div formArrayName="defaults">
                @if (selectedType != 'Gender') {
                  <div [formGroupName]="idx" class="row mb-2">
                    @if (selectedType == 'Age' || selectedType == 'HouseholdIncome') {
                      <div class="mt-2 col-md-1 arrowBtnDiv">
                        <button tabindex="-1" class="row m-0 mb-2 arrowBtn" type="button" (click)="changeOrder('defaults', idx, -1)"><i class="fas fa-xs fa-chevron-up"></i></button>
                        <button tabindex="-1" class="row m-0 arrowBtn" type="button" (click)="changeOrder('defaults', idx, 1)"><i class="fas fa-xs fa-chevron-down"></i></button>
                      </div>
                      @if (selectedType == 'Age') {
                        <div class="col-md-2 col-sm-12">
                          <input #minAgeInput type="text" placeholder='Min' [required]="true" mask="9999999" [patterns]="integerPattern" class="text-left form-control" 
                            formControlName="min" id="min" [ngClass]="submitted && utils.showCssValidField(questionForm.get('defaults').get(idx.toString()), 'min')"
                            (blur)="checkMinMaxAge(minAgeInput, 'min', idx)"/>
                        </div>
                        <div class="col-md-1 mt-2 text-center betweenMaxMin">to</div>
                        <div class="col-md-2 col-sm-12">
                          <input #maxAgeInput type="text" placeholder='Max' [required]="true" mask="9999999" [patterns]="integerPattern" class="text-left form-control" 
                            formControlName="max" id="max" [ngClass]="submitted && utils.showCssValidField(questionForm.get('defaults').get(idx.toString()), 'max')" 
                            (blur)="checkMinMaxAge(maxAgeInput, 'max', idx)"/>
                        </div>
                      }
                      @if (selectedType == 'HouseholdIncome') {
                        <div class="col-md-3 col-sm-12">
                          <select class=" form-control" formControlName="min" [required]="true" name="" id="" [ngClass]="submitted && utils.showCssValidField(questionForm.get('defaults').get(idx.toString()), 'min')">
                            <option [ngValue]="0"> {{0 | currency :'USD':'symbol':'1.0-0' }}</option>
                            @for (n of hhiList; track n) {
                              <option [ngValue]="n">{{n | currency :'USD':'symbol':'1.0-0' }}</option>
                            }
                          </select>
                        </div>
                        <div class="col-md-1 mt-2 w-75 text-center betweenMaxMin">to</div>
                        <div  class="col-md-3 col-sm-12">
                          <select class=" form-control"  formControlName="max" [required]="true" name="" id="" [ngClass]="submitted && utils.showCssValidField(questionForm.get('defaults').get(idx.toString()), 'max')">
                            @for (n of hhiList; track n) {
                              @if (this.questionForm.get('defaults').value[idx].min < n-1) {
                                <option  [ngValue]="n-1">{{n-1 | currency :'USD':'symbol':'1.0-0' }}</option>
                              }
                            }
                            <option [ngValue]="hhiMax+1">Over {{hhiMax | currency :'USD':'symbol':'1.0-0' }}</option>
                          </select>
                        </div>
                      }
                      <div class="mt-1 pr-5">
                        <button tabindex="-1" class=" p-0 btn btn-sm removeBtn" (click)="removeOptionDefaultClick(idx)" type="button"><i class="fas fa-thin fa-times"></i></button>
                      </div>
                      <div class="default mr-4">
                        <div class="input-group">
                          <input type="text" [required]="true"  (change)="calculateTotalPercent()" mask="99999" class="text-left form-control" formControlName="percent" id="percent" [ngClass]="submitted && utils.showCssValidField(questionForm.get('defaults').get(idx.toString()), 'percent')">
                          <div class="input-group-append">
                            <span class="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                      <div class="demo">
                        <div class="input-group">
                          <input tabindex="-1" type="text" class="text-left form-control" [readonly]="true">
                        </div>
                      </div>
                    }
                  </div>
                }
              </div>
            }
            <div class="row mb-2">
              <div class="mt-2 col-md-1 arrowBtnDiv"></div>
              <div class="col-md-2 col-sm-12"></div>
              <div class="col-md-1 mt-2 text-center betweenMaxMin"></div>
              <div class="col-md-2 col-sm-12" [ngClass]="{'col-md-3' : selectedType =='HouseholdIncome'}"></div>
              <div class="mt-1 pr-5">
                <div style="width:23.777px"></div>
              </div>
              <div class="default mr-4">
                <div class="input-group">
                  <input tabindex="-1" type="text"  readonly mask="99999" class="text-left form-control" [ngModel]="questionTotal" [ngModelOptions]="{standalone: true}" >
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>
              <div class="demo">
              </div>
            </div>
          }

          <div class="ml-4">
            @if (selectedType=='HouseholdIncome' || selectedType =='Age') {
              <button class="btn btn-sm btn-outline-secondary ml-1" type="button" (click)="addOptionDefaultClick()">+ Add Option</button>
            }
            @if (selectedType=='Gender' || selectedType=='Ethnicity' || selectedType == 'Hispanic') {
              <button class="btn btn-sm btn-outline-secondary ml-1" type="button" (click)="addOptionClick()">+ Add Option</button>
            }

            <!-- PREFER NOT TO ANSWER -->
            @if (selectedType=='HouseholdIncome' || selectedType =='Gender' || selectedType =='Hispanic' || selectedType =='Ethnicity') {
              <div class="row ml-1 mt-4">
                <div class="col-md-8 d-flex align-items-center col-sm-12 pl-0 py-1">
                  <or-toggle-button [id]="'preferNotToAnswer'" class="light" formControlName="preferNotToAnswer" (changed)="preferNotToAnswerChanged($event)" [isChecked]="model.preferNotToAnswer"></or-toggle-button>
                  <small class="ml-2">'Prefer not to answer' is <strong>{{ model.preferNotToAnswer ? 'ENABLED' : 'DISABLED' }}</strong></small>
                </div>
              </div>
            }
          </div>

        </div>
          <div class="card-footer text-right mt-5">
            <div class="btn btn-light mr-2" [routerLink]="['/quota-questions']"  type="button">Cancel</div>
            <button class="btn or-primary-bg text-white" type="submit">Save</button>
          </div>
      </form>
  </div>

  <ng-template #modalPreviewQuestion>
    <div class="modal-header">
      <p class="h5 mb-0">Respondent View: {{selectedType != 'HouseholdIncome' ? selectedType : 'Household Income'}}</p>
      <button type="button" class="btn previewQuestionBtn p-0 pl-1 pr-1" (click)="openModalRef.hide()"><i class="fa fa-lg fa-solid fa-times"></i></button>
    </div>
    <div class="modal-body text-center m-3">
      <div>
        <h5 class="font-weight-normal text-left">{{questionForm.value.question}}</h5>
      </div>
      <div class="previewQuestionAnswers mt-3">
        @if (questionForm.value.inputType ==='RadioButtons') {
          <div>
            <table class="w-100 ml-1 mr-1">
              <tr>
                <th style="width:10%"></th><th></th>
              </tr>
              @for (answer of questionForm.value.options; track answer) {
                <tr>
                  <td><input type="radio" name="previewQuestion" class="" value={{answer.value}}/></td>
                  <td class="text-left">{{answer.value}}</td>
                </tr>
              }
              @if (questionForm.value.preferNotToAnswer) {
                <tr>
                  <td><input type="radio" name="previewQuestion" class="" value="prefer not to answer"/></td>
                  <td class="text-left">Prefer not to answer</td>
                </tr>
              }
            </table>
          </div>
        }
        @if (questionForm.value.inputType ==='UserInput') {
          @if (selectedType == 'Age') {
            <div class="">
              <input type="date" class="form-control input-sm w-50" [max]="todaysDate">
            </div>
          }
          @else {
            <div class="">
              <input type="text" size="10" class="form-control input-sm w-50">
            </div>
          }
        }
        @if (questionForm.value.inputType === 'DropDown') {
          <div class="">
            <select name="previewQuestion" id="" class="form-control w-50">
              <option value="" selected disabled>Please Select</option>
              @for (answer of questionForm.value.options; track answer) {
                <option value={{answer.value}}>{{answer.label}}</option>
              }
              @if (questionForm.value.preferNotToAnswer) {
                <option value="prefer not to answer">Prefer not to answer</option>
              }
            </select>
          </div>
        }
      </div>
    </div>
  </ng-template>



