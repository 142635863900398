import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'or-progress-guage',
  templateUrl: './progress-guage.component.html',
  styleUrls: ['./progress-guage.component.scss']
})
export class ProgressGuageComponent implements OnInit {
  @Input() value:number;
  @Input() expected:number;
  @Input() mode = 'percent';
  @Input() threshold = 30;
  @Input() bgColor = 'nav-bg-success';
  @Input() bgDanger = 'nav-bg-error';
  @Input() inDanger = false;

  showDanger:boolean;
  percentScore ;

  constructor() { }

  ngOnInit() {
    if(this.mode == 'percent' || this.mode == 'quota') {
      this.showDanger = this.value >= this.expected;
    }
    else if (this.mode == "target") {
      this.showDanger = this.expected >= this.value;
    }
    else if (this.mode == 'parent') {
      this.showDanger = this.inDanger;
    }
    else {
      const percent = (this.value >  this.expected) ? 100 * (this.value - this.expected)/ this.expected: 100 * (this.expected - this.value)/this.expected;
      this.percentScore = Math.abs(percent);
      this.showDanger = this.percentScore > this.threshold;
    }
  }

}
