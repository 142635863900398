import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Partner, PartnerRedirects, Webhook } from 'core';
import { VendorQuotaMap } from 'projects/admin/src/app/_models/vendorQuotaMapping';
import { GroupNote } from '../models/group-note';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  baseUrl = environment.apiEndpoint + '/partner/';

  constructor(private http: HttpClient) {}

  GetPartner(Id: string, forceRefresh = false): Observable<Partner> {
    return this.http.get<Partner>(this.baseUrl + Id, {headers : {cache : '1', refresh: forceRefresh ? '1' : '0' }});
  }

  GetPartners(instanceId: string = '', includeGlobal: boolean = false): Observable<Partner[]> {
    return this.http.get<Partner[]>(this.baseUrl + '?instanceId=' + instanceId+'&includeGlobal='+includeGlobal);
  }
  GetRecommendedPartners(mode: string = '', category: string = ''): Observable<any[]> { return this.http.get<any[]>(this.baseUrl + 'recommended/' + mode + '/' + category, { headers : {cache : '1'}}); }
  AddPartner(model: any): Observable<Partner> { return this.http.post<Partner>(this.baseUrl + '', model); }
  UpdatePartnerQuotaMapping(model: any): Observable<VendorQuotaMap> { return this.http.post<VendorQuotaMap>(this.baseUrl + 'quotaMapping', model); }
  GetPartnerQuotaMapping(Id: string): Observable<VendorQuotaMap> { return this.http.get<VendorQuotaMap>(this.baseUrl + 'quotaMapping/' + Id); }
  EditPartner(model: any, updateRedirects: boolean): Observable<any> { return this.http.put(this.baseUrl  + model.id + '/' + updateRedirects, model); }
  EditPartnerLinks(id, model: any): Observable<any> { return this.http.put(this.baseUrl  + id + '/links', model); }
  DeletePartner(id: string) { return this.http.delete(this.baseUrl + id); }
  copyPartner(partnerId: string): Observable<Partner> { return this.http.post<Partner>(this.baseUrl + 'copyPartner/' + partnerId, {}); }
  copyRedirects(vendorId: string, globalVendorId: string): Observable<PartnerRedirects> { return this.http.post<PartnerRedirects>(this.baseUrl + vendorId + '/redirects/copy/' + globalVendorId, {}); }
  copyMappings(vendorId: string, globalVendorId: string): Observable<VendorQuotaMap> { return this.http.post<VendorQuotaMap>(this.baseUrl + vendorId + '/mappings/copy/' + globalVendorId, {}); }

  CreatePartnerNote(id: string, note: GroupNote): Observable<any> {
    return this.http.post<any>(this.baseUrl  + id + '/note', note );
  }
  SavePartnerNote(id: string, note: GroupNote): Observable<any> {
    return this.http.put<any>(this.baseUrl  + id + '/note', note );
  }
  DeletePartnerNote(id: string, note: GroupNote): Observable<any> {
    return this.http.delete<any>(this.baseUrl + id + '/note/' + note.id );
  }

  GetPartnerNotes(id: string): Observable<GroupNote[]> {
    return this.http.get<GroupNote[]>(this.baseUrl + id + '/note', );
  }
  DeletePartnerNoteTag(id: string, tagId: string): Observable<GroupNote[]> {
    return this.http.get<GroupNote[]>(this.baseUrl + id + '/note/tag/' + tagId, );
  }
  GetPartnerNoteTags(id: string): Observable<GroupNote[]> {
    return this.http.get<GroupNote[]>(this.baseUrl + id + '/note/tag', );
  }
  GetPartnerWebhooks(vendorId: string): Observable<Webhook[]> {
    return this.http.get<any[]>(this.baseUrl + vendorId + '/webhooks');
  }
  SavePartnerWebhook(vendorId: string, webhook: Webhook): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + vendorId + '/webhooks', webhook);
  }
  GetPartnerRateCards(vendorId: string): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + vendorId + '/ratecards');
  }
  AddPartnerRateCard(vendorId: string, ratecard: any): Observable<any> {
    return this.http.post<any[]>(this.baseUrl + vendorId + '/ratecards', ratecard);
  }
  EditPartnerRateCard(vendorId: string, ratecard: any): Observable<any> {
    return this.http.put<any[]>(this.baseUrl + vendorId + '/ratecards', ratecard);
  }
}
