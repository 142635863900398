<form [formGroup]="partnerForm" novalidate>
  <div class="row align-items-center mb-4 vendor-upload-image">
    <div class="col-3 d-flex justify-content-center pr-5">
      <img [src]="model?.img || '../../../assets/imgs/user-instagram.png'" height="64px" width="64px" class="rounded-circle" alt="">
    </div>
    <div class="col upload">
      <or-upload
        mainMsg="Select profile photo to upload"
        smallMsg="or drag and drop .JPEG or .PNG here"
        [includeProgressBar]=false
        acceptImages=true
        showFileName=false
        directToS3=true
        showFileIcon=false
        class="upload"
        (uploadCompleted)="uploadCallback($event)">
      </or-upload>
    </div>
  </div>
  <div class="row align-items-center mb-4">
    <div class="col-3 grey4">
      <label class="required">Company name</label>
    </div>
    <div class="col">
      <input type="text" class="form-control form-control-sm form-control-nav" placeholder="Company name" formControlName="name" id="name"
      [ngClass]="submitted && utils.showCssValidField(partnerForm, 'name')" />
    </div>
  </div>
  <div class="row align-items-center mb-4">
    <div class="col-3 grey4">Website</div>
    <div class="col">
      <input type="text" class="form-control form-control-sm form-control-nav" placeholder="http://www.website.com" formControlName="website" id="website" />
    </div>
  </div>

  <div class="row align-items-start mb-4">
    <div class="col-3 grey4">Address</div>
    <div class="col">
      <div class="row align-items-center mb-3">
        <div class="col">
          <input type="text" class="form-control form-control-sm form-control-nav" placeholder="123 Street" formControlName="address1" id="address1" />
        </div>
      </div>
      <div class="row align-items-center mb-3">
        <div class="col grey4">
          <label>Apt, suite, etc.</label>
          <input type="text" class="form-control form-control-sm form-control-nav" placeholder="Optional" formControlName="address2" id="address2" />
        </div>
      </div>
      <div class="row align-items-center mb-3">
        <div class="col grey4">
          <label>City</label>
          <input type="text" class="form-control form-control-sm form-control-nav" placeholder="City" formControlName="city" id="city" />
        </div>
        <div class="col grey4">
          <label>State/province</label>
          <input type="text" class="form-control form-control-sm form-control-nav" placeholder="State/province" formControlName="state" id="state" />
        </div>
      </div>
      <div class="row align-items-center mb-3">
        <div class="col grey4">
          <label>ZIP/postal code</label>
          <input type="text" class="form-control form-control-sm form-control-nav" placeholder="00000" formControlName="zip" id="zip" />
        </div>
        <div class="col grey4">
          <label>Country</label>
          <input type="text" class="form-control form-control-sm form-control-nav" placeholder="Country" formControlName="country" id="country" />
        </div>
      </div>
    </div>
  </div>
  <div class="row audience-type">
    <div class="col-3 grey4 padding-top2">Audience Type</div>
    <div class="col">
      <or-lookup-table (changed)="addAudienceType(model, $event)" type="vendor-audience-type" container="" [selectedValuesString]="model.audienceTypeIds" placeholder="Search audience type or type and press enter to create"></or-lookup-table>
    </div>
  </div>
  <div class="row regions-served">
    <div class="col-3 grey4 padding-top2">Regions Served</div>
    <div class="col">
      <or-lookup-table (changed)="addRegions(model, $event)" type="vendor-regions-served" container="" [selectedValuesString]="model.regionIds" placeholder="Search regions or type and press enter to create"></or-lookup-table>
    </div>
  </div>
  <div class="row tags">
    <div class="col-3 grey4 padding-top2">Tags</div>
    <div class="col">
      <or-lookup-table (changed)="addTags(model, $event)" type="vendor-tags" container="" [selectedValuesString]="model.tagIds" placeholder="Search tags or type and press enter to create"></or-lookup-table>
    </div>
  </div>
  <div class="col text-right pr-0">
    <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14" (click)="savePartner(true)">
      {{newVendor ? 'Add and set up redirects' : 'Save and edit redirects'}}</button>
    <button type="button"
            [disabled]="!partnerForm.valid"
            [ngClass]="partnerForm.valid ? 'nav-btn-accent' : 'nav-btn-disabled'"
            class="btn shadow-none font-weight-bold nav-font14"
            (click)="savePartner()">{{newVendor ? 'Add' : 'Save'}}</button>
  </div>
</form>