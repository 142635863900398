import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LookupTable } from '../models/lookupTable';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  baseUrl = environment.apiEndpoint + '/lookup';

  constructor(private http: HttpClient) {}

  GetLookupTable(type: string): Observable<Array<LookupTable>> {
    return this.http.get<Array<LookupTable>>(this.baseUrl  + '/' + type,);
  }
  InsertLookupValue(type: string, value: LookupTable): Observable<any> {
    return this.http.post<any>(this.baseUrl  + '/' + type, value);
  }
  DeleteLookupValue(type: string, tagId: string): Observable<any> {
    return this.http.delete<any>(this.baseUrl  + '/' + type + '/' + tagId, );
  }
  SaveLookupValue(type: string, value: LookupTable): Observable<any> {
    return this.http.put<any>(this.baseUrl  + '/' + type, value );
  }
}