<div class="stagesAndTasks">
  <div class="card-header d-flex align-items-center bg-white border-bottom-0">
    <span class="h6 nav-secondary font-weight-bold nav-font18">Stages and tasks</span>
    <span class="btn btn-sm shadow-none font-weight-bold ml-auto"
          [tooltip]="stages.length >= 8 ? 'You can only have a maximum of 8 stages' : ''"
          placement="left"
          container="body"
          [ngClass]="{'nav-btn-accent': stages.length <= 8, 'nav-btn-disabled': stages.length >= 8}"
          (click)="stages.length < 8 ? addStages() : null"><i class="far fa-plus"></i>&nbsp;&nbsp; Add Stage {{stages.length}}
    </span>
  </div>
  <div class="">
    <p class="nav-font14 mx-3 px-1">Create a set of stages and tasks to automatically populate your project schedule. You can arrange the order of your stages and tasks by using the drag and drop icon.</p>
    <div class="mx-3 mb-5 px-1 py-2">
      @if (stages.length == 0) {
        <div class="nav-font14 grey5">
          No stages. Add stage to begin.
        </div>
      }

      <!-- draggable list -->
      @if (stages.length > 0) {
        <div
          [dndDropzone]="['stage']"
          (dndDrop)="onDrop($event, stages)">
          <!-- dnd placeholder -->
          <div class="dndPlaceholder border rounded-1 opacity-25" dndPlaceholderRef></div>
          @for (item of stages; track item; let idx = $index) {
            <!-- draggable stage -->
            <div
              [dndDraggable]="item"
              dndType="stage"
              dndEffectAllowed="move"
              class="row d-flex justify-content-center my-3">
              <div class="text-right pr-0 pt-3" dndHandle>
                <i class="fas fa-grip-dots-vertical fa-xl dragIcon"></i>
              </div>
              <div class="col-7">
                <div class="card px-3 pb-3 pt-2">
                  <div class="row">
                    <div class="hex col-1 pt-2 px-2">
                      <div class="d-flex align-items-center">
                        <span
                          ngx-colors-trigger
                          hideColorPicker="true"
                          hideTextInput="true"
                          [palette]="colorPalette"
                          (open)="item.pickerOpen=true"
                          (close)="item.pickerOpen=false"
                          acceptLabel="Select"
                          format="hex"
                          cancelLabel="Cancel"
                          class="dot d-inline-block mr-2 cursor-pointer"
                          [(ngModel)]="item.color" [style]="'background-color:'+ item.color+';'">
                          <span class="dot arrow">
                            <i class="fak fa-left-arrow" [ngClass]="!item?.pickerOpen ? 'fa-rotate-270' : 'fa-rotate-90'"></i>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div class="grey4 font-weight-bold nav-font14 pr-0 pt-1 col-10">
                      Stage Name
                      <div class="py-1 stageName">
                        <input #input (keyup.enter)="input.blur()" type="text"
                          class="form-control form-control-nav form-control-sm d-inline"
                          placeholder="Type stage name"
                          [(ngModel)]="item.name">
                        </div>
                      </div>
                      <div class="col-1 text-right">
                        <i class="fak fa-trash nav-error cursor-pointer" (click)="stages = arrayRemove(stages, item)"></i>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-center mt-3">
                      <div class="col-12">
                        <div class="taskHeader grey4 font-weight-bold nav-font14">
                          <div class="row h-100 d-flex align-items-center">
                            <div class="col-1">
                            </div>
                            <div class="col-10">
                              Task Name
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    @if (item.tasks.length > 0) {
                      <div class="row d-flex justify-content-center mt-4"
                        [dndDropzone]="['task']"
                        (dndDrop)="onDrop($event, item.tasks)">
                        <!-- dnd placeholder -->
                        <div class="dndPlaceholder border rounded-1 opacity-25" dndPlaceholderRef></div>
                        @for (task of item.tasks; track task; let i = $index) {
                          <!-- draggable task -->
                          <div
                            [dndDraggable]="task"
                            dndType="task"
                            dndEffectAllowed="move"
                            class="col-12 mb-4">
                            <div class="row">
                              <div class="col-1 text-right pr-0" dndHandle>
                                <i class="fas fa-grip-dots-vertical dragIcon"></i>
                              </div>
                              <div class="col-10 pr-0 taskName">
                                <div class="input-group">
                                  <div class="input-group-prepend ">
                                    <span class="input-group-text fak fa-trash fa-error cursor-pointer px-2" (click)="removeTask(item, i)"></span>
                                  </div>
                                  <input #input (keyup.enter)="input.blur()" (blur)="updateTaskName(item, i, input.value)" type="text"
                                    class="form-control form-control-sm d-inline pl-1"
                                    placeholder="Type task name" [value]="task">
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      }
                      <div class="row d-flex justify-content-center" [ngClass]="item.tasks.length == 0 ? 'mt-4' : ''">
                        <div class="nav-font14 grey5 col-12">
                          <div class="row mt-2 mb-1">
                            <div class="col-1">
                            </div>
                            <div class="col-8">
                              {{item.tasks.length == 0 ? 'No tasks. Add task to begin.' : ''}}
                            </div>
                            <div class="col-2 cursor-pointer navfont-14 nav-accent-blue font-weight-bold pr-0 text-right" (click)="addTask(item)">
                              <i class="far fa-plus pr-2"></i>
                              Add task
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          }

        </div>
      </div>
    </div>
