import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'or-spinner-button',
  templateUrl: './spinner-button.component.html',
  styleUrls: ['./spinner-button.component.scss']
})
export class SpinnerButtonComponent implements OnInit  {

  showSpinner = false;

  @Input() label = 'Save';
  @Input() icon = '';
  @Input() customClass;
  @Input() showSpinnerOnCallback = false;

  @Output() callback = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {

  }

  actionSelected() {

    if (!this.showSpinnerOnCallback) {
      this.showSpinner = true;
    }

    this.callback.emit(true);
  }
}
