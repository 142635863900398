import { Component, ViewChild, Input, Output, EventEmitter, forwardRef, OnInit, SimpleChanges } from '@angular/core';
import { MessageService } from 'core';

@Component({
  selector: 'or-zoho-desk',
  templateUrl: './zoho-desk.component.html',
  styleUrls: ['./zoho-desk.component.scss']
})

export class ZohoDeskComponent implements OnInit {

  @Input()
  width: number = 400;

  @Input()
  height: number = 570;

  constructor() {}

  ngOnInit() { 
  }

}
