<div class="tagsinput">
  @for (tag of selectedValues; track tag) {
    <span class="tag ng-star-inserted">
      @if (_labelField == '') {
        <span class="tag-text mr-2">{{ tag }}</span>
      }
      @if (_labelField !== '') {
        <span class="tag-text mr-2">{{ tag[_labelField] }}</span>
      }
      <button class="tag-remove" (click)="removeTag(tag)"></button>
    </span>
  }

  @if (selectedValues?.length == 0 || multi) {
    <div class="tag-add" #tagsAdd>
      <input #typeaheadDirective="bs-typeahead"
        [(ngModel)]="asyncSearch"
        [typeahead]="dataSource"
        [adaptivePosition]="true"
        [typeaheadAsync]="true"
        [typeaheadOptionField]="_labelField"
        [typeaheadMinLength]="typeaheadMinLength"
        [typeaheadScrollable]="typeaheadScrollable"
        [typeaheadOptionsLimit]="typeaheadOptionsLimit"
        [typeaheadOptionsInScrollableView]="10"
        (typeaheadLoading)="onTypeaheadLoading($event)"
        (typeaheadOnSelect)="onSelect($event)"
        (typeaheadOnBlur)="callbackBlur($event)"
        [container]="containerBody ? 'body' : ''"
        [isAnimated]="isAnimated"
        [style]="'width:'+width"
        [placeholder]="placeholder"
        class="form-control {{ class }}"
        autocomplete="off" >
      </div>
    }

  </div>
  @if (typeaheadLoading && showLoading) {
    <div>Loading...</div>
  }

