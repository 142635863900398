import { Component, OnInit, forwardRef, ViewChild, ElementRef, Input, Output, EventEmitter, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { LookupTable } from '../../models/lookupTable';
import {AlertifyService, LookupService} from 'core'

@Component({
  selector: 'or-lookup-table',
  templateUrl: './lookup-table.component.html',
  styleUrls: ['./lookup-table.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LookupTableComponent),
      multi: true
    }
  ]
})

export class LookupTableComponent implements AfterViewInit, OnInit, OnDestroy, ControlValueAccessor  {

  @ViewChild('tagsAdd') tagsAdd: ElementRef;
  @ViewChild('dropdown', {static: true}) dropdown;

  @Input() placeholder = '';
  @Input() container = 'body';
  @Input() type = '' // client-note-tag, client-note-subject, etc
  @Input() options: Array<LookupTable> = []
  @Input() allOptions: Array<LookupTable> = [] // optionally insert values manually
  @Input() isDisabled = false;
  // @Input() class:string = '';
  @Input() public set selectedValuesString(data) {

    if(data == this._selectedValuesString) return;

    this._selectedValuesString = data;

    if(this.tagsAdd)
    {
      this.selectedValues = [];
      const elements = this.tagsAdd.nativeElement.parentNode.querySelectorAll('.tag');

      elements.forEach(element => {
        element.remove();
      });
      this.loadTags();
    }

  }

  _selectedValuesString = '';
  filter = '';
  hasDelete = false;
  dropdownShowing = false;
  @Output() selectedValuesStringChange = new EventEmitter<any>();
  @Output() changed = new EventEmitter<any>();

  selectedValues: any[] = [];

  listenerFn: () => void;
  propagateChange: (_: any) => {};

  constructor(
    private renderer: Renderer2,
    private lookupService: LookupService,
    private alertify: AlertifyService,
    ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.loadTags();
  }

  loadTags() {

    if (this.type != null){
      this.hasDelete = true;
      this.lookupService.GetLookupTable(this.type).subscribe((data) => {
        if (data != null) {
          this.allOptions = data
          this.options = [...this.allOptions].filter(x => x.suggest);
        }
      }, error => {
      }, () => {
        if (this._selectedValuesString !== undefined && this._selectedValuesString.length > 0) {

          const tags = this._selectedValuesString.split(',');
          for (const tagId of tags) {
            const tag = this.allOptions.find(x => x.id == tagId) ?? null;
            if (tag != null) {
              this.addTag(tag, true);
            }
          }
        }
      })
    }
    else {
      if (this.allOptions != null) {
        this.options = [...this.allOptions].filter(x => x.suggest);
      }
    }

  }

  addTagFromDropdown(tag) {
    this.filter = '';
    this.dropdown.toggle(true);
    this.dropdownShowing = false;
    this.addTag(tag);
    this.changed.emit(this.selectedValues);
  }

  removeTagFromDropdown(tagId) {

    this.allOptions = this.allOptions.filter(x => x.id != tagId);
    this.options = this.options.filter(x => x.id != tagId).filter(x => x.suggest);
    if (this.options.length  == 0) {
      this.dropdown.toggle(true);
      this.dropdownShowing = false;
    }
    this.lookupService.DeleteLookupValue(this.type, tagId).subscribe((data) => {
    }, error => {

    }, () => {
    })
  }

  onEnter(event: KeyboardEvent): void {
    if (!this.isDisabled) {
      event.preventDefault();
      const input = (event.target as HTMLInputElement);
      var newTag: LookupTable;
      var alreadyExists = this.allOptions.find(x => x.value === input.value)
      if (alreadyExists) {
        newTag = alreadyExists;

        // if user uses tag that exists but isn't being suggested anymore, add back to suggestions
        if (newTag.suggest == false) {
          newTag.suggest = true;
          this.lookupService.SaveLookupValue(this.type, newTag).subscribe((data) => {
          }, error => {

          }, () => {

          })
        }
      }
      else {
        var newTag: LookupTable = {
          id: uuidv4(),
          type: this.type,
          value: input.value,
          suggest: true
        }


        this.lookupService.InsertLookupValue(this.type, newTag).subscribe((data) => {
        }, error => {

        }, () => {

        })
      }
      if (!this.selectedValues.find(x => x.id === newTag.id)) {
        this.addTag(newTag);
      }
      else {
        this.alertify.error(`'${newTag.value}' tag has already been added to this Note`)
      }
      this.filter = '';
      this.updateSearch();
      this.changed.emit(this.selectedValues);
    }
  }
  onKeyup(event: KeyboardEvent): void {
    if (!this.isDisabled) {
      const input = (event.target as HTMLInputElement);
      this._selectedValuesString = this.selectedValues.join(',');

      if(input.value !== '')
      {
        this._selectedValuesString = (this._selectedValuesString === '')? input.value : this._selectedValuesString +','  +input.value;
      }
      this.selectedValuesStringChange.emit(this._selectedValuesString);
      if (this.propagateChange) {
        this.propagateChange(this._selectedValuesString);
      }

    }
  }


  addTag(item: LookupTable, initialValue= false) {

    const divTags = this.tagsAdd.nativeElement.parentNode;
    // Tag exists already
    if (item.value === '' || this.selectedValues.indexOf(item.id) > -1) {

      return;
    }
    this.options = this.options.filter(x => x.id != item.id)

    const btnRemove: HTMLSpanElement = this.renderer.createElement('button');
    btnRemove.className = 'tag-remove';

    // add event listener for remove button (x) inside the new tag added
    this.listenerFn = this.renderer.listen(btnRemove, 'click', (evt: MouseEvent) => {

      const tagToDelete = (evt.target as HTMLElement).parentNode;
      const parent = (evt.target as HTMLElement).parentNode.parentNode;
      const tagValue = (tagToDelete as HTMLElement).getAttribute('data-value');
      const tagId = (tagToDelete as HTMLElement).getAttribute('data-id');
      this.renderer.removeChild(parent, tagToDelete);

      // removes the deleted item from Array
      this.selectedValues = this.selectedValues.filter((value) => value.id !== tagId);
      this._selectedValuesString = this.selectedValues.join(',');
      this.selectedValuesStringChange.emit(this._selectedValuesString);

      // add back into suggestions
      var tagExists = this.allOptions.find(x=> x.id == tagId);
      if (!tagExists) this.removeTagFromDropdown(tagId)


      this.changed.emit(this.selectedValues);

      if (this.propagateChange) {
        this.propagateChange(this._selectedValuesString);
      }

    });

    const tagText: HTMLSpanElement = this.renderer.createElement('span');
    tagText.className = 'tag-text mr-2';
    tagText.innerHTML = item.value;

    const tag: HTMLSpanElement = this.renderer.createElement('span');
    tag.className = 'tag';
    tag.setAttribute('data-value', item.value);
    tag.setAttribute('data-id', item.id);
    tag.appendChild(tagText);
    tag.appendChild(btnRemove);

    this.selectedValues.push(item);
    //this._selectedValuesString = this.selectedValues.join(this.selectedValuesSep);

    const val = this.selectedValues.join(',');

    //We don't emit if this was a value that was passed in on intialization
    if(!initialValue)
      this.selectedValuesStringChange.emit(val);

    if (this.propagateChange) {
      this.propagateChange(val);
    }
    this.renderer.insertBefore(divTags, tag, this.tagsAdd.nativeElement );
  }

  ngOnDestroy() {
    if (this.listenerFn) {
      this.listenerFn();
    }
  }

  writeValue(value: string): void {
    if (value !== undefined && value !== null) {
      this._selectedValuesString = value;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(disabled: boolean): void {
    this.isDisabled = disabled;
  }

  updateSearch() {
    const filter = this.filter.toLowerCase();
    if(filter === ''){
      // this.options = this.allOptions;

      if (this.dropdownShowing) {
        this.dropdown.toggle(true);
        this.dropdownShowing = false;
      }

    }
    else {
      this.options = this.allOptions.filter(e => e.value.toLowerCase().indexOf(filter) >= 0 && !this.selectedValues.find(x => x.id === e.id) && e.suggest);
      // this.options = this.options.filter(x => !tags.includes(x.id))
      if (this.options.length == 0 && this.dropdownShowing) {
        this.dropdown.toggle(true);
        this.dropdownShowing = false;
      }
      else if (this.options.length > 0 && !this.dropdownShowing) {
        this.dropdown.toggle(true);
        this.dropdownShowing = true;
      }

    }
  }
}
