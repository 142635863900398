import { Component, ElementRef, Input, forwardRef, OnInit, OnChanges, SimpleChanges, Output } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { UploaderService } from '../../services/uploader.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { EventEmitter, ViewChild  } from '@angular/core';
import { AlertifyService } from '../../services/alertify.service';

@Component({
  selector: 'or-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadComponent),
      multi: true
    }
  ]
})
export class UploadComponent implements ControlValueAccessor, OnInit, OnChanges  {

  @Input() formControlName = '';
  @Input() smallMsg = '';
  @Input() mainMsg = 'Drop a file here';
  @Input() directToS3 = false;
  @Input() acceptImages = false;
  @Input() includeProgressBar = true;
  @Input() showPreviewImg = false;
  @Input() imageUpload = false;
  @Input() includeFileName = false;
  @Input() acceptTypes = '';
  @Input() isCsv = false;
  @Input() publicBucket = true;
  @Input() showFileName = true;
  @Input() S3Folder = 'image';
  @Input() downloadLink = null;
  @Input() instanceId = '';
  @Input() feature = '';
  @Input() mode = 'full'; // 'full' or compact: 'link' | 'button'
  @Input() compactModeText = 'Click here to upload files';
  @Input() showFileIcon;
  @Input() fileCleared;

  @Input() formControlName2 = '';
  @Input() smallMsg2 = '';
  @Input() mainMsg2 = '';

  @Output() uploadCompleted = new EventEmitter();
  @Output() deletedCompleted = new EventEmitter();
  @ViewChild('fileInput') myFileInput: ElementRef;
  @ViewChild('imagePreview') imagePreview: ElementRef;

  fileUrl = '';
  presignedUrl = '';
  file: File | null = null;
  fileExtension = '';
  fileSize = '';
  uploading = false;
  uploadComplete = false;
  uploadProgress = 0;

  onChange: (url) => void;
  onTouch: (url) => void;

  constructor(public uploader: UploaderService, private alertify: AlertifyService ) {

    if (this.acceptImages) {
      this.acceptTypes = 'image/x-png,image/jpeg';
    } else if (this.isCsv) {
      this.acceptTypes = '.csv';
      this.fileExtension = '.csv';
    }
   }

   ngOnInit(): void {
    if (this.acceptImages) {
      this.acceptTypes = 'image/x-png,image/jpeg';
    } else if (this.isCsv) {
      this.acceptTypes = '.csv';
    }
  }
   ngOnChanges(changes: SimpleChanges): void {
    if (changes?.fileCleared) {
      var previousValue = changes?.fileCleared?.previousValue
      var currentValue = changes?.fileCleared?.currentValue
      if (previousValue != null && previousValue != '' && previousValue != undefined && currentValue == null) {
        this.removeFile();
      }
    }
  }

  deleteFile() {
    this.file = null;
    this.deletedCompleted.emit();
  }

  fileAdded(event: any) {

    this.uploading = true;
    this.uploadComplete = false;
    this.file = event.target.files[0];

    this.getFileExt();
    this.getFileSize();

    if (this.directToS3) {

      var fileName: string = '';

      if (this.includeFileName) {
        fileName = this.file.name;
      }

      this.uploader.generatePresignedUrl(this.fileExtension, this.S3Folder, this.publicBucket, fileName, this.instanceId).subscribe(response => {
        this.presignedUrl = response.uploadUrl;
        this.fileUrl = response.url;

        if (this.onChange)
          this.onChange(this.fileUrl);

      }, error => console.log(error)
      , () => {
        this.uploadToS3();
      });

    }
  }

  uploadToS3() {
      this.uploader.uploadFileToS3(this.file, this.presignedUrl).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round(event.loaded / event.total) * 100;

        } else if (event.type === HttpEventType.Response) {

          this.uploading = false;
          this.uploadComplete = true;
        }
      }, error => {
          this.uploading = false;

      }, () => {
        this.uploadCompleted.emit({status: 'Complete', file: this.fileUrl, origFile: this.file});

        if (this.showPreviewImg) {
          this.imagePreview.nativeElement.src = this.fileUrl;
        }

        if (this.myFileInput)
          this.myFileInput.nativeElement.value = '';
      });
    }

  writeValue(value: string): void {
    this.fileUrl = value ? value : '';
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {

  }

  removeFile() {
    this.file = null;
  }

  getFileSize() {
    const fileSizeInBytes = this.file.size;
    const fileSizeInMegabytes = fileSizeInBytes / (1024 * 1024);
    this.fileSize = fileSizeInMegabytes.toFixed(2);
  }

  getFileExt() {

    if (this.isCsv) {
      this.fileExtension = '.csv';
      return;
    }

    switch (this.file.type) {
      case 'image/png': {
        this.fileExtension = '.png';
        break;
      }
      case 'image/jpeg': {
        this.fileExtension = '.jpeg';
        break;
      }
      case 'image/jpg': {
        this.fileExtension = '.jpeg';
        break;
      }
      case 'application/csv': {
        this.fileExtension = '.csv';
        break;
      }
      case 'application/vnd.ms-excel': {
        this.fileExtension = '.xls';
        break;
      }
      case 'text/plain': {
        this.fileExtension = '.txt';
        break;
      }
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
        this.fileExtension = '.xlsx';
        break;
      }
      case 'application/pdf': {
        this.fileExtension = '.pdf';
        break;
      }
      default: {
         this.fileExtension = '.jpeg';
         break;
      }
   }
  }
}
