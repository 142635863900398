import { Injectable} from '@angular/core';

export const darkTheme = {
  'or-background-color': '#1f2935',
  'or-text-color': '#fff'
};

export const lightTheme = {
  'or-background-color': '#fff',
  'or-text-color': '#212529'
};


@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  themeName = 'light';

  constructor() {   }

   toggleDark() {
    this.setTheme(darkTheme);
  }

  toggleLight() {
    this.setTheme(lightTheme);
  }

  private setTheme(theme: {}) {
    Object.keys(theme).forEach(k =>
      this.cssVar(`--${k}`, theme[k])
    );
  }

  private setThemeByName(theme) {
    // load theme scss dynamically
  }

  cssVar = (name, value) => {

    if (name.substr(0, 2) !== '--') {
        name = '--' + name;
    }
    if (value) {
        document.documentElement.style.setProperty(name, value);
    }
    return getComputedStyle(document.documentElement).getPropertyValue(name);
  }

}
