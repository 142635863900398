import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Client } from 'core';
import { GroupNote } from '../models/group-note';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

baseUrl = environment.apiEndpoint + '/client';

constructor(private http: HttpClient) {}

GetClient(id: string, forceRefresh = false): Observable<Client> {
  return this.http.get<Client>(this.baseUrl  + '/' + id, {headers : {cache : '1', refresh: forceRefresh ? '1' : '0' }});
}
GetClientUsingBidNumber(bidNumber: string): Observable<Client> { return this.http.get<Client>(this.baseUrl  + '/summary/' + bidNumber); }

GetClients(instanceId: string = ''): Observable<Client[]> {
  return this.http.get<Client[]>(this.baseUrl + '?instanceId=' + instanceId);
}

AddClient(model: any): Observable<Client> {
  return this.http.post<Client>(this.baseUrl + '', model);
}

EditClient(model: any): Observable<Client> {
  return this.http.put<Client>(this.baseUrl + '/' + model.id, model);
}

DeleteClient(model: any) {
  return this.http.delete(this.baseUrl + '/' + model.id);
}
UpdateClientPreference(id: string, value: string): Observable<any> {
  return this.http.put<any>(this.baseUrl + '/' + id + '/preference', { fieldId: '', sVal: value} );
}
CreateClientNote(id: string, note: GroupNote): Observable<any> {
  return this.http.post<any>(this.baseUrl + '/' + id + '/note', note );
}
SaveClientNote(id: string, note: GroupNote): Observable<any> {
  return this.http.put<any>(this.baseUrl + '/' + id + '/note', note );
}
DeleteClientNote(id: string, note: GroupNote): Observable<any> {
  return this.http.delete<any>(this.baseUrl + '/' + id + '/note/' + note.id );
}

GetClientNotes(id: string): Observable<GroupNote[]> {
  return this.http.get<GroupNote[]>(this.baseUrl + '/' + id + '/note', );
}
DeleteClientNoteTag(id: string, tagId: string): Observable<GroupNote[]> {
  return this.http.get<GroupNote[]>(this.baseUrl + '/' + id + '/note/tag/' + tagId, );
}
GetClientNoteTags(id: string): Observable<GroupNote[]> {
  return this.http.get<GroupNote[]>(this.baseUrl + '/' + id + '/note/tag', );
}

}
