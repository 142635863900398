@if (mode == 'percent' || mode == 'parent') {
  <div class="progress {{mode}}">
    <div class="progress-bar" role="progressbar"
      [class.nav-bg-error]="showDanger"
      [style.width.%]="value"
      [ngClass]="value >= 100 ? 'complete' : 'in-progress'"
    aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
    <div class="vl" [style.right.%]="100 - expected"></div>
  </div>
}


@if (mode == 'quota') {
  <div class="progress {{mode}}">
    <div class="progress-bar {{ showDanger ? bgDanger : bgColor }} " role="progressbar"
      [style.width.%]="value"
    aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
    <div class="vl" [style.right.%]="100 - expected"></div>
  </div>
}

@if (mode == 'target') {
  <div class="progress">
    <div class="progress-bar {{ showDanger ? bgDanger : bgColor }}" role="progressbar"
      [style.width.%]="value"
    aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
    <div class="vl" [style.right.%]="100 - expected"></div>
  </div>
}

@if (mode == 'schedule') {
  <div class="progress schedule">
    <div class="progress-bar {{ showDanger ? bgDanger : bgColor }}" role="progressbar"
      [style.width.%]="value"
    aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
    <div class="vl" [style.right.%]="100 - expected"></div>
  </div>
}

@if (mode == 'ridProcessing') {
  <div class="progress ridProcessing">
    <div class="progress-bar {{bgColor}}" role="progressbar"
      [style.width.%]="value"
    aria-valuenow="" aria-valuemin="0" aria-valuemax="100"></div>
  </div>
}


@if (mode == 'split') {
  <div class="progress" [class.direction-left]="value < expected">
    <!-- LOI is less than expected-->
    @if (value < expected) {
      <div class="progress-bar" role="progressbar"
        [class.nav-bg-error]="showDanger"
        [style.right.%]="50"
        [style.width.%]="percentScore/2"
        aria-valuenow="" aria-valuemin="0" aria-valuemax="100">
      </div>
    }
    @if (value > expected) {
      <div class="progress-bar" role="progressbar"
        [class.nav-bg-error]="showDanger"
        [style.left.%]="50"
        [style.width.%]="percentScore/2"
        aria-valuenow="" aria-valuemin="0" aria-valuemax="100">
      </div>
    }
    <div class="vl opacity-30" [style.left.%]="50 - threshold/2"></div>
    <div class="vl" [style.left.%]="50"></div>
    <div class="vl opacity-30" [style.left.%]="50 + threshold/2"></div>
  </div>
}
