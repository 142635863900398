import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateString'
})
export class TruncateStringPipe implements PipeTransform {

  transform(value: string, size: number): any {
    if (!value || value === undefined) { return value; }
    if (!size || size === 0) { return value; }
    if (value.length <= size) { return value; }

    return value.slice(0, size) + '...';
  }
}
