<div class="tagsinput">
  <div class="tag-add" #tagsAdd>
    <input
        type="text"
        (keydown.enter)="onEnter($event)"
        (keyup)="onKeyup($event)"
        [placeholder]="placeholder" class="form-control {{ class }}" />
  </div>
</div>



