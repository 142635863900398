import { Injectable } from '@angular/core';
import { Project, ProjectDedupeStatus } from 'core';
import { UtilsService } from './utils.service';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeasibilityService {

  baseUrl = environment.apiEndpoint + '/feasibility/';


  constructor(private http: HttpClient, public utils: UtilsService) {}

  CheckFeasibility(details: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'feasible', details);
  }
  FeasibilityGrouping(details: any, grouping: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'feasible/'+ grouping, details);
  }
  fullReport(filename): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'fullreport/', {filename:filename});
  }

  
  GetVTokens(details: any) {
    return this.http.post<any>(this.baseUrl + 'vtokens', details, {responseType: 'blob' as 'json'});
  }

 
}
