<div class="input-group global-search">
  <div class="input-group-prepend">
    <div class="input-group-text">
      <i class="fas fa-search grey4 cursor-default"></i>
    </div>
  </div>
  <input type="search" #typeaheadDirective="bs-typeahead"
    [(ngModel)]="asyncSearch"
    [typeahead]="dataSource"
    [typeaheadAsync]="true"
    [optionsListTemplate]="customListTemplate"
    [typeaheadOptionField]="labelField"
    typeaheadOptionsLimit="25"
    (typeaheadLoading)="onTypeaheadLoading($event)"
    (typeaheadNoResults)="onTypeaheadNoResults($event)"
    (typeaheadOnSelect)="onSelect($event)"
    (keydown.enter)="$event.preventDefault()"
    (focus)="onFocus($event)"
    [isAnimated]="true"
    container="body"
    placeholder="Search"
    class="form-control form-control-sm border-right-0"
    autocomplete="off">
  </div>

  <ng-template #customListTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
    <div class="global-search-result list-group">
      <div class="d-flex align-items-center global-search-header">
        <span class="col nav-font14 py-2 px-2 grey5 font-weight-bold">Projects</span>
        <span class="col-3 grey5 px-2 nav-font14 font-weight-bold text-right">({{ matches.length <= 20 ? matches.length : '20+' }})</span>
      </div>
      <div class="overflow-y scrollbar" style="max-height: 500px;">
        @for (match of matches; track match) {
          <a href="#" class="list-group-item px-3 py-2 list-group-item-action text-secondary border-0 border-top"
            (mouseenter)="typeaheadTemplateMethods.selectActive(match)"
            (click)="typeaheadTemplateMethods.selectMatch(match, $event)"
            [class.active]="typeaheadTemplateMethods.isActive(match)"
            [ngClass]="{'archived': match.item.isArchived}">
            <span class="d-block nav-font14 grey4 mr-3">{{ match.item[labelField] }}</span>
            <span class="d-block text-nowrap font-weight-bold nav-font12 nav-accent-blue">{{ match.item.projectCode }}</span>
          </a>
        }
      </div>
    </div>
  </ng-template>
