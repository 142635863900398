import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';

import { HomeComponent } from './sections/home/home.component';
import { LoginComponent } from './sections/login/login.component';
import { ResetPasswordComponent } from './sections/reset-password/reset-password.component';
import { ResetLinkComponent } from './sections/reset-link/reset-link.component';
import { ChangePasswordComponent } from './sections/change-password/change-password.component';

import { UserManagerComponent } from './sections/user-manager/user-manager.component';
import { UserEditComponent } from './sections/user-manager/users/user-edit/user-edit.component';
import { UserAddComponent } from './sections/user-manager/users/user-add/user-add.component';

import { RolesComponent } from './sections/user-manager/roles/roles.component';

import { InstanceManagerComponent } from './sections/instance-manager/instance-manager.component';
import { SurveySettingsComponent } from './sections/survey-settings/survey-settings.component';
import { CleanidComponent } from './sections/cleanid/cleanid.component';
import { ApiKeyManagerComponent } from './sections/keys/apikey-manager/apikey-manager.component';
import { PartnersManagerComponent } from './sections/partners-manager/partners-manager.component';
import { InstanceEditComponent } from './sections/instance-edit/instance-edit.component';
import { PartnerAddComponent } from './sections/partner-add/partner-add.component';
import { PartnerEditComponent } from './sections/partner-edit/partner-edit.component';
import { ClientAddComponent } from './sections/client/client-add/client-add.component';
import { ClientEditComponent } from './sections/client/client-edit/client-edit.component';
import { ClientComponent } from './sections/client/client.component';
import { LiveMonitoringComponent } from './sections/live-monitoring/live-monitoring.component';
import { RecruitidComponent } from './sections/recruitid/recruitid.component';
import { QuotaQuestionsComponent } from './sections/quota-questions/quota-questions.component';
import { QuotaEditComponent } from './sections/quota-questions/quota-edit/quota-edit.component';
import { SiteSetupComponent } from './sections/site-setup/site-setup.component';
import { SiteSetupEditComponent } from './sections/site-setup/site-setup-edit/site-setup-edit.component';
import { ProjectManagerComponent } from './sections/project-manager/project-manager.component';
import { ResponseIdComponent } from './sections/responseid/responseid.component';
import { ConfirmationLinkComponent } from './sections/confirmation-link/confirmation-link.component';
import { AppIntegrationComponent } from './sections/app-integration/app-integration.component';
import { DashboardManagerComponent } from './sections/dashboard-manager/dashboard-manager.component';
import { KeyManagerComponent } from './sections/keys/key-manager.component';

const routes: Routes =  [
  {path: '', component: LoginComponent},
  {path: 'resetpassword', component: ResetPasswordComponent},
  {path: 'confirmationlink', component: ConfirmationLinkComponent},
  {path: 'resetlink', component: ResetLinkComponent},
  {
      path: '',
      runGuardsAndResolvers: 'always',
      canActivate: [AuthGuard],
      children: [
          {path: 'home', component: HomeComponent},
          {path: 'changepassword', component: ChangePasswordComponent},

          {path: 'instancemanager', component: InstanceManagerComponent, data: {roles: ['Admin']}},
          {path: 'instance/edit', component: InstanceEditComponent, data: {roles: ['Admin']}},
          {path: 'instance/edit/:id', component: InstanceEditComponent, data: {roles: ['Admin']}},

          {path: 'usermanager', component: UserManagerComponent, data: {roles: ['Admin']}},
          {path: 'user/add', component: UserAddComponent, data: {roles: ['Admin']}},
          {path: 'user/edit', component: UserEditComponent, data: {roles: ['Admin']}},

          {path: 'roles', component: RolesComponent, data: {roles: ['Admin']}},
          {path: 'role/add', component: UserAddComponent, data: {roles: ['Admin']}},
          {path: 'role/edit/:id', component: UserEditComponent, data: {roles: ['Admin']}},

          {path: 'partners', component: PartnersManagerComponent, data: {roles: ['Admin']}},
          {path: 'vendors', component: PartnersManagerComponent, data: {roles: ['Admin']}},
          {path: 'vendors/add', component: PartnerAddComponent, data: {roles: ['Admin']}},
          {path: 'vendors/edit/:id', component: PartnerEditComponent, data: {roles: ['Admin']}},
          {path: 'vendors/clone/:cloneid', component: PartnerEditComponent, data: {roles: ['Admin']}},

          {path: 'clients', component: ClientComponent, data: {roles: ['Admin']}},
          {path: 'client/add', component: ClientAddComponent, data: {roles: ['Admin']}},
          {path: 'client/edit/:id', component: ClientEditComponent, data: {roles: ['Admin']}},
          {path: 'client/clone/:cloneid', component: ClientEditComponent, data: {roles: ['Admin']}},

          {path: 'quota-questions', component: QuotaQuestionsComponent, data: {roles: ['Admin']}},
          {path: 'quota-edit', component: QuotaEditComponent, data: {roles: ['Admin']}},
          {path: 'survey-settings', component: SurveySettingsComponent, data: {roles: ['Admin']}},
          {path: 'app-integration', component: AppIntegrationComponent, data: {roles: ['Admin']}},

          {path: 'cleanid', component: CleanidComponent, data: {roles: ['Admin']}},
          {path: 'responseid', component: ResponseIdComponent, data: {roles: ['Admin']}},
          {path: 'recruitid', component: RecruitidComponent, data: {roles: ['Admin']}},
          {path: 'apikeys', component: ApiKeyManagerComponent, data: {roles: ['Admin']}},
          {path: 'keys', component: KeyManagerComponent, data: {roles: ['Admin']}},
          {path: 'live-monitoring', component: LiveMonitoringComponent, data: {roles: ['Admin']}},
          {path: 'site-setup', component: SiteSetupComponent, data: {roles: ['Admin']}},
          {path: 'site-setup/edit/:id', component: SiteSetupEditComponent, data: {roles: ['Admin']}},
          {path: 'project-manager', component: ProjectManagerComponent, data: {roles: ['Admin']}},
          {path: 'dashboard-manager', component: DashboardManagerComponent, data: {roles: ['Admin']}}
      ]
  },
  {path: '**', redirectTo: '', pathMatch: 'full'},
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
