import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AlertifyService, AuthService, Role, RoleService } from 'core';
import { Router } from '@angular/router';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ComponentsModule, HelpersModule } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RoleEditComponent } from './role-edit/role-edit.component';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
  providers: [RoleEditComponent]
})
export class RolesComponent implements OnInit {

  instanceId = '';
  roles: any[];
 // systemRoles: any[];
  deleteRole: any;
  editRole: Role = {};
  openModalRef: any;
  filterColumns = ['name'];
  filterText: string;
  bsModalRef: BsModalRef;

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;

  //TO DO: THESE SHOULD NOT BE HARDCODED BASED ON TYPE
  //DEV
  /*
  systemRoles = [
    {name: 'Internal', description: 'internal',id:"92D93949-A30D-4245-E26F-08D76AD29361"},
    {name: 'Client', description: 'Client',id:"B2E35CF5-DB9A-40A7-06F0-08D9F09EDCFE"},
    {name: 'Vendor', description: 'Vendor',id:"CAE73B3E-BEBD-4A2E-421A-08DA0BF56D6E"}
];*/
  //LIVE
  systemRoles = [
    {name: 'Internal', description: 'internal',id:"21CB7C40-0D57-4C23-D546-08D80BBB1E2A"},
    {name: 'Client', description: 'Client',id:"E115064A-26B7-4A3A-084D-08D9FB63908B"},
    {name: 'Vendor', description: 'Vendor',id:"839DCAE7-91FB-493F-B363-08DA2E6FD720"}
];

  layout = [
            {label: 'Name', id: 'name', sortable: true},
            {label: 'Type', id: 'type', sortable: true},
            {label: 'Description', id: 'description', sortable: true},
            {label: 'Actions', type: 'actions-inline'}
  ];

  systemRoleactions = [    
    {label: 'Manage Members', type: 'manage-users', icon: 'users'}
  ];

  actions = [
    {label: 'Edit Role', type: 'edit', icon: 'pen-square'},
    {label: 'Delete Role', type: 'delete', icon: 'trash-alt'},
    {label: 'Manage Members', type: 'manage-users', icon: 'users'}
  ];

  constructor(private modalService: BsModalService,
              public auth: AuthService,
              public roleService: RoleService,
              private router: Router,
              private alertify: AlertifyService) { }

  ngOnInit() {
    this.instanceId = this.auth.getInstance();
    this.getRoles();
  }

  handleAction(action, system) {
    if (action.type === 'edit' || action.type === 'manage-users') {
      this.editRole = action.row;
      this.openModal(action.type, system);
    }
    if (action.type === 'delete') {
      this.deleteRole = action.row;
      this.openModalRef = this.modalService.show(this.modalRefConfirm);
    }
  }

  public openModal(mode, system) {
    const initialState = {
      editRole: this.editRole,
      system: system,
      mode: mode
    };

    this.bsModalRef = this.modalService.show(RoleEditComponent, {ignoreBackdropClick: true, keyboard: false, initialState, class: 'modal-md' });
    this.bsModalRef.content.saveevent.subscribe((data) => {
      this.getRoles();
    })
  }

  confirmDelete() {
    this.roleService.DeleteRole(this.deleteRole.id).subscribe( data => {
      this.getRoles();
    }, error => {
       this.alertify.error('Unable to delete role');
    },
    () => {
        this.alertify.success(this.deleteRole.name + ' deleted successfully');
        this.openModalRef.hide();
    });
  }

  getRoles() {
    this.roleService.GetInstanceRoles(this.instanceId).subscribe( data => {
      this.roles = data;
    }, error => {
       this.alertify.error('Unable to get roles');
    });
  }

}
