@if (!typeSelected) {
  <div class="d-flex mb-3 mt-1 border-bottom">
    <div class="text-truncate or-secondary h4 m-2"> Project Manager </div>
    <div class="vl ml-2"></div>
    @if (auth.isSuperAdmin()) {
      <div class="sectionbtn nav-item mt-1">
        <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'type'" (click)="tab = 'type'">Types </a>
      </div>
      <div class="sectionbtn nav-item mt-1">
        <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'list'" (click)="tab = 'list'">List Views </a>
      </div>
    }
  </div>
}

@if (auth.isSuperAdmin() && tab == 'type') {
  <app-project-types (onselect)="selectedProjectType($event)"></app-project-types>
}


@if (tab == 'list') {
  <app-project-listviews></app-project-listviews>
}

