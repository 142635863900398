<div [formGroup]="parentGroup">
  <div class="row">
    <div class="col-6">
      <label for="qcRedirectURL">Link Security</label>
      <select class="form-control" formControlName="linkSecurity" id="linkSecurity">
        <option value="null">None</option>
        <option value="server-to-server">Server to Server</option>
        <option value="hashing">Hashing</option>
      </select>
    </div>
  </div>
  <br/>

  <h5>Redirects <small class="text-muted">(Optional)</small></h5>

  <div class="row">
    <div class="col-6">
      <label for="completeRedirectURL">CompleteRedirectURL</label>
      <input type="text" class="form-control" formControlName="completeRedirectURL" id="completeRedirectURL" placeholder="http://example.com/complete" />
    </div>
    <div class="col-6">
      <label for="terminateRedirectURL">TerminateRedirectURL</label>
      <input type="text" class="form-control" formControlName="terminateRedirectURL" id="terminateRedirectURL" placeholder="http://example.com/term" />
    </div>
    <div class="col-6">
      <label for="overQuotaRedirectURL">OverQuotaRedirectURL</label>
      <input type="text" class="form-control" formControlName="overQuotaRedirectURL" id="overQuotaRedirectURL" placeholder="http://example.com/oq" />
    </div>
    <div class="col-6">
      <label for="qcRedirectURL">QCRedirectURL</label>
      <input type="text" class="form-control" formControlName="qcRedirectURL" id="qcRedirectURL" placeholder="http://example.com/qc" />
    </div>
  </div>

  <div class="row">
    <div class="col-9"></div>
    <div class="col-3">
      @if (globalPartnerId !== '00000000-0000-0000-0000-000000000000') {
        <div class="text-right" style="width: 100%">
          <div class="btn btn-light" type="button" (click)="copyRedirects()">Copy Redirects<div>
          </div>
        </div>
      </div>
    }
  </div>

