import { Social } from './social';
import { Address } from './address';

export interface PartnerContact {
    id: string;
    mode: string;
    firstName: string;
    lastName: string;
    phone: string;
    callingCode: string;
    email: string;
    partnerId: string;
    address?: Address;
    lastLogin?: Date;
    social?: Social;
    billing?: boolean;
    role: string;
}
