import { Address } from './address';
import { Social } from './social';

export interface ClientContact {
    id: string;
    mode: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    clientId: string;
    address?: Address;
    social?: Social;
    billing?: boolean;
}
