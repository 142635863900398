export interface ScheduleTemplate {
    id?: string;
    templateName: string;
    projectTypeId: string;
    createdById?: string;
    createdByName?: string;
    createdOn?: Date;
    tasks: ScheduleTemplateTask[];
    show?: boolean;
    stages?: [];
}

export interface ScheduleTemplateTask {
    id?: string;
    taskName: string;
    description?: string;
    daysFromProjectStart?: number;
    taskLength?: number;
    sortOrder: number;
    projectTypeStageId: number;
    reoccurrenceSettings: ScheduleTemplateReoccurrenceSettings;
}

export interface ScheduleTemplateReoccurrenceSettings {
    id?: string;
    daysFromProjectStart_begin: number;
    daysFromProjectStart_end: number;
    frequency: number;
    frequencyType: string;
    days: string;
}
