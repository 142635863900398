import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'or-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent implements OnInit {
  @Input() label = '';
  @Input() description = '';
  @Input() icon = 'fas';
  @Input() size = 'regular';
  @Input() style = 'light';

  @Output() selected = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  actionSelected($event) {
    this.selected.emit($event);
  }

}
