import { Directive, Input, OnChanges, OnInit } from '@angular/core';
import { TabDirective } from 'ngx-bootstrap/tabs';

@Directive({
  selector: '[tabOrder]'
})
export class TabOrderDirective  implements OnInit, OnChanges  {

  @Input() tabOrder = 0;

  constructor(private tab: TabDirective) { }

  // ngOnInit has to be added even when  is empty, otherwise Angular Directives won't work.
  ngOnInit() {}

  ngOnChanges() {
    (this.tab as any).__tabOrder = +this.tabOrder;
    this.tab.tabset.tabs.sort((a: any, b: any) => a.__tabOrder - b.__tabOrder);
  }

}
