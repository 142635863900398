import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AlertifyService, DropdownService, CountriesService} from 'core';
@Component({
  selector: 'or-phone-with-code',
  templateUrl: './phone-with-code.component.html',
  styleUrls: ['./phone-with-code.component.scss']
})
export class PhoneWithCodeComponent implements OnInit {

  @Input() callingCode;
  @Input() phoneNumber;
  
  @Output() fullNumber = new EventEmitter();

  _callingCode: any;
  _phoneNumber: any;
  _fullNumber: any;
  _countryList: any;
  _countries: any;

  constructor(
    private alertify: AlertifyService,
    private dd: DropdownService,
    private countriesService: CountriesService) {}

  ngOnInit() {
    this.countriesService.getCountries().subscribe(data => {
      this._countries = data;

      let country = data.find(x => x.callingCode === this.callingCode);
      if (country) {
        if(country.callingCode == '001'){
          country.display = 'USA/Canada (+1)';
        }
        this._callingCode = [{ name: country?.display, value: country?.iso3 }];
      }
      else {
        this._callingCode = [];
      }
    }, error => {
       this.alertify.error('Unable to get countries');
    });
  }

  checkValidNumber() {
    if (this._phoneNumber.length > 0) {
      if (this._callingCode === undefined || this._callingCode === null || this._callingCode.length <= 0) {
        this.alertify.error("Please select a country calling code");
        return null;
      } else {
        this._phoneNumber = +this._phoneNumber + "";
      }
    } else {
      this._phoneNumber = null;
    }
    if (this.callingCode.length > 0) {
      if (this._phoneNumber === undefined || this._phoneNumber === null || this._phoneNumber.length <= 0) {
        this.alertify.error("Please add a phone number");
        return null;
      }
    } else {
      this._callingCode = null;
    }

    this._fullNumber = this._callingCode + this._phoneNumber;
    return this._fullNumber;
  }

  getCountryList = (filter) => {
    return this.dd.getCountries();
  }
  getCountryCode = (filter) => {
    return this.dd.getFullCountries();
  }

  setCallingCode(selected) {
    if (selected.length > 0) {
      let country = this._countries.find(x => x.iso3 === selected[0].value);
      this._callingCode = country.callingCode;
    } else {
      this._callingCode = "";
    }
  }
  setPhoneNumber(phoneNumber) {
    if (phoneNumber.length > 0) {

    } else {
      this.phoneNumber = "";
    }
  }
  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    return charCode > 31 && (charCode < 48 || charCode > 57)
      ? false
      : true;
  }

  applyValue() {
    this._fullNumber = this.checkValidNumber();
    if (this._fullNumber === null) return;

    this.fullNumber.emit(this._fullNumber);
  }
}
