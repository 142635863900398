import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

   transform(value: any, searchText: string): any[] {
    if (!value) { return []; }
    if (!searchText) { return value; }

    var simpletext = new RegExp("(" + searchText + ")","gi"); 
    return value.replace(simpletext, "<span class='highlightedText'>$1</span>")
  }
}
