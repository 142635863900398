import { SecurityKey } from 'core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SecurityKeyService {
  baseUrl = environment.apiEndpoint + '/securitykey/';
  file: File;

  requestId = '';

  constructor(private http: HttpClient) { }

  getSecurityKeys(instanceId: string): Observable<SecurityKey[]> { return this.http.get<SecurityKey[]>(this.baseUrl + '?instanceId=' + instanceId); }
  getSecurityKey(id: any): Observable<SecurityKey> { return this.http.get<SecurityKey>(this.baseUrl + id); }
  addSecurityKey(model: any): Observable<SecurityKey> { return this.http.post<SecurityKey>(this.baseUrl, model); }
  editSecurityKey(model: any): Observable<SecurityKey> { return this.http.put<SecurityKey>(this.baseUrl + model.id, model); }
  deleteSecurityKey(id: any): Observable<SecurityKey> { return this.http.delete<SecurityKey>(this.baseUrl + id); }
}
