@if (!toggleOpen) {
  <div class="tagsinput">
    @if (currentValues.length == 0) {
      <div>
        <button type="button" class="picker-button btn btn-outline-info d-flex" (click)="togglePicker()">
          <div class="flex-fill">{{ placeholder }}</div>
          <div><i class="fas fa-chevron-down"></i></div>
        </button>
      </div>
    }
    @if (exclude) {
      <div class="text-danger m-2"><i class="fa-lg fas fa-not-equal"></i></div>
    }
    @for (item of currentValues; track item) {
      <span class="tag">
        <span class="tag-text or-primary">{{ item.name}}</span><button class="tag-remove" (click)="removeValue(item)"></button>
      </span>
    }
    @if (currentValues.length > 0) {
      <div>
        <button type="button" class="btn text-primary" (click)="togglePicker()"><i class="far fa-edit"></i></button>
      </div>
    }
  </div>
}

@if (toggleOpen) {
  <div class="border option-dropdown">
    <div class="option-list-row option-dropdown-header p-2">
      <div><input [(ngModel)]="filterText" class="form-control text-filter" placeholder="Search"></div>
      @if (allowExclude) {
        <div>
          <select class="form-group clear-input" [(ngModel)]="_exclude">
            <option [ngValue]="false">Include</option>
            <option [ngValue]="true">Exclude</option>
          </select>
          <div></div>
        </div>
      }
    </div>
    <div class="option-dropdown-list scrollbar overflow-y">
      @for (option of options; track option; let i = $index) {
        @if (matchFilter(option.name)) {
          <div class="option-list-row p-1">
            <div>
              <div class="or-checkbox d-block">
                <input type="checkbox" [id]="'option'+i" [(ngModel)]="selectedValues[option.value]"  >
                <label [for]="'option'+i">
                  <span class="text-secondary">
                    {{ option.name }}
                  </span>
                </label>
              </div>
            </div>
            <div> {{ option.count }}</div>
          </div>
        }
      }
    </div>
    <div class="d-flex justify-content-center m-1">
      <button type="button" class="btn btn-outline-info" (click)="cancelChanges()"><i class="far fa-window-close"></i> Cancel</button>
      <button type="button" class="btn btn-outline-success" (click)="saveChanges()"><i class="far fa-save"></i> Save</button>
    </div>
  </div>
}