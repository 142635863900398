import { Component, Input, OnInit } from '@angular/core';
import { AlertifyService, AuthService, ProjectService, UtilsService } from 'core';
import { DndDropEvent } from 'ngx-drag-drop';

@Component({
  selector: 'app-project-types-stages',
  templateUrl: './project-types-stages.component.html',
  styleUrls: ['./project-types-stages.component.scss']
})
export class ProjectTypesStagesComponent implements OnInit {

  @Input('stages') stages = null;

  colorPalette: Array<any> = [
    "#B5B8BD",
    "#C19C9C",
    "#EB8198",
    "#F59149",
    "#4CCD96",
    "#5FB5FF",
    "#CB9EF8",
    "#E0E0E0",
    "#DAC4C3",
    "#F6B4C2",
    "#FDDA89",
    "#B3E3C7",
    "#C6E5F7",
    "#EBD4F9"
  ];


  constructor(
    public auth: AuthService,
    public utils: UtilsService,
    public projectService: ProjectService,
    public alertify: AlertifyService) { }

  ngOnInit(): void {
    this.stages = this.stages.sort((x, y) => x.sortOrder - y.sortOrder);
  }

  addStages() {
    // TODO: OTHER DEFAULTS
    if (this.stages === undefined) this.stages = [];
    const newStage = {
      sortOrder: this.stages.length + 1,
      color: '',
      name: '',
      tasks: [],
      isDeleted: false
    };
    this.stages.push(newStage);
    this.stages = this.stages.sort((x, y) => x.sortOrder - y.sortOrder);
  }

  addTask(stage) {
    if (stage.tasks === undefined || stage.tasks == null) stage.tasks = [];
    stage.tasks.push('');
    // this.sortedStages = this.stages.sort((x, y) => x.sortOrder - y.sortOrder);
  }

  arrayRemove(arr, value) {
    value.isDeleted = true;
    this.stages = this.stages.filter(ele => ele !== value);
    return arr.filter(ele => ele !== value);
  }

  removeTask(stage, index) {
    stage.tasks.splice(index, 1);
  }

  updateTaskName(stage, index, value) {
    if (stage.tasks[index] === value) return
    stage.tasks[index] = value;
  }

  onDrop(event: DndDropEvent, list?: any[]) {

    if (list && event.dropEffect === 'move') {
      let index = event.index;

      if (typeof index === 'undefined') {
        index = list.length;
      }

      let oldIndex = -1;

      if (event?.type === 'stage')
        oldIndex = list.findIndex(item => item.id?.toLowerCase() === event.data.id?.toLowerCase());
      else
        oldIndex = list.findIndex(item => item.toLowerCase() === event.data.toLowerCase());

      if (oldIndex > -1) {
        list.splice(oldIndex, 1);
        list.splice(index, 0, event.data);

        //change sortOrder
        if (event?.type === 'stage') {
          list = list.map((item, index) => {
            item.sortOrder = index + 1;
            return item;
          });
        }
      }
    }
  }

}
