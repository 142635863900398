<div class="card mt-5" style="max-width: 450px; margin:0 auto">
  <div class="card-header or-secondary-bg text-white">
    <h5>Create Password</h5>
  </div>
  <div class="card-body">
    <form [formGroup]="confirmationForm" (ngSubmit)="confirmEmailToken()" class="form-signin text-center">
      <label for="email" class="sr-only">Email address</label>
      <input type="email" class="form-control" [ngClass]="{'is-invalid': confirmationForm.get('email').errors && confirmationForm.get('email').touched}" id="email"  formControlName="email" aria-describedby="emailHelp" placeholder="Email">
      <div class="invalid-feedback">Please enter a valid email!</div>

      <label for="password" class="sr-only">Email address</label>
      <input type="password" class="form-control mt-3" [ngClass]="{'is-invalid': confirmationForm.get('password').errors && confirmationForm.get('password').touched}" id="password" value="password"  formControlName="password" placeholder="Password">
      @if (confirmationForm.get('password').hasError('required') && confirmationForm.get('password').touched) {
        <div class="invalid-feedback">Password is required!</div>
      }
      @if (confirmationForm.get('password').hasError('minlength') && confirmationForm.get('password').touched) {
        <div class="invalid-feedback">Password must be at least 4 charcters!</div>
      }

      <label for="confirmPassword" class="sr-only">Password</label>
      <input
        type="password"
        class="form-control is-invalid"
        [ngClass]="{'is-invalid': confirmationForm.get('confirmPassword').errors
          && confirmationForm.get('confirmPassword').touched
          || confirmationForm.get('confirmPassword').touched
          && confirmationForm.hasError('mismatch')}"
        id="confirmPassword"
        value="password"
        formControlName="confirmPassword"
        placeholder="Confirm Password">
        @if (confirmationForm.get('confirmPassword').hasError('required') && confirmationForm.get('confirmPassword').touched) {
          <div class="invalid-feedback">Confirm password is required!</div>
        }
        @if (confirmationForm.hasError('mismatch') && confirmationForm.get('confirmPassword').touched) {
          <div class="invalid-feedback">Passwords must match!</div>
        }

        <button class="btn btn-lg btn-primary btn-block" [disabled]="!confirmationForm.valid" type="submit">Create</button>
      </form>
    </div>
    <div class="card-footer text-muted text-center  or-secondary-bg text-white">
      <small>© 2024 OpinionRoute, LLC. | All Rights Reserved.</small>
    </div>
  </div>
