import { v4 as uuidv4 } from 'uuid';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AuthService, AlertifyService, ProjectService, UtilsService, RoleService } from 'core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-project-listviews',
  templateUrl: './project-listviews.component.html',
  styleUrls: ['./project-listviews.component.scss']
})
export class ProjectListviewsComponent implements OnInit {

  instanceId = '';
  mode = 'new';
  firstLoading = false;
  projectListViews = [];
  submitted = false;
  editListView = null;
  deleteView = null;
  openModalRef: any;
  columnsConfirmDeletes = {};
  types = {};
  statusFilter: any = ['Soft Launch', 'Full Launch', 'Paused', 'Closed', 'Invoiced', 'Test'];
  viewModes = ['list', 'card', 'calendar'];
  roles: any[] = [];
  cloneid: string;

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;

  @ViewChild('listViewForm')
  private listViewForm: NgForm;

  layout = [
    {label: 'Name', id: 'name', sortable: true},
    {label: 'Description', id: 'description'},
    {label: 'Card Style', id: 'cardStyle'},
    {label: 'Location Type', id: 'locationType'},
    {label: 'Icon', id: 'icon', type: 'icon'},
    {label: 'Project Type', id: 'projectType', filterable: true},
    {label: 'SortOrder', id: 'sortOrder', sortable: true},
    {label: 'Actions', type: 'actions-dropdown'}
  ];
  actions = [
    {label: 'Edit', type: 'edit'},
    {label: 'Delete', type: 'delete'},
    {label: 'Clone', type: 'clone'}
  ];

  cardStyles = [
    {id: 'survey', label: 'Survey'},
    {id: 'idsuite', label: 'IDSuite'}
  ];

  locationTypes = [
    {id: 'default', label: 'Default'},
    {id: 'client', label: 'Client'},
    {id: 'vendor', label: 'Vendor'}
  ];

  projectTypes = [];

  fieldTypes = [
    {label: 'Project Code', field: 'projectCode', sortable: true, filterable: true, textFilterable: true, editable: false, autoSave: false, availableTypes: ['text']},
    {label: 'Project Name', field: 'projectName', sortable: true, filterable: false, textFilterable: true, editable: false, autoSave: false, availableTypes: ['text']},
    {label: 'Project Status', field: 'projectStatus', sortable: true, filterable: true, textFilterable: false, editable: true, autoSave: true, availableTypes: ['status']},
    {label: 'Project Start Date', field: 'projectStartDate', sortable: true, filterable: false, textFilterable: false, editable: true, autoSave: true, availableTypes: ['date', 'countdown']},
    {label: 'Project End Date', field: 'projectEndDate', sortable: true, filterable: false, textFilterable: false, editable: true, autoSave: true, availableTypes: ['date', 'countdown']},
    {label: 'Field Start Date', field: 'fieldStartDate', sortable: true, filterable: false, textFilterable: false, editable: true, autoSave: true, availableTypes: ['date', 'countdown']},
    {label: 'Field End Date', field: 'fieldEndDate', sortable: true, filterable: false, textFilterable: false, editable: true, autoSave: true, availableTypes: ['date', 'countdown']},
    {label: 'Last Complete', field: 'lastComplete', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['date']},
    {label: 'Current Stage', field: 'currentStage', sortable: true, filterable: true, textFilterable: true, editable: false, autoSave: false, availableTypes: ['stage']},
    {label: 'Category', field: 'category', sortable: true, filterable: true, textFilterable: true, editable: false, autoSave: false, availableTypes: ['text']},
    {label: 'Tags', field: 'projectTags', sortable: false, filterable: true, textFilterable: false, editable: false, autoSave: false, availableTypes: ['tags']},
    {label: 'Client', field: 'clientName', sortable: true, filterable: true, textFilterable: true, editable: false, autoSave: false, availableTypes: ['text']},
    {label: 'Project Manager', field: 'pm', sortable: true, filterable: true, textFilterable: false, editable: false, autoSave: false, availableTypes: ['users']},
    {label: 'Account Owner', field: 'accountOwner', sortable: true, filterable: true, textFilterable: false, editable: false, autoSave: false, availableTypes: ['users']},
    {label: 'Programmer', field: 'programmer', sortable: true, filterable: true, textFilterable: false, editable: false, autoSave: false, availableTypes: ['users']},
    {label: 'SoftLaunch Quota', field: 'softLaunchQuota', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['number']},
    {label: 'FullLaunch Quota', field: 'fullLaunchQuota', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['number']},
    //{label: 'CleanID', field: 'cleanID', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['number']},
    {label: 'OverQuota', field: 'overQuota', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['number']},
    {label: 'Starts', field: 'starts', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['number']},
    {label: 'Completes', field: 'complete', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['number']},
    {label: 'LOI', field: 'loi', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['number']},
    {label: 'Client OverQuota', field: 'clientOverQuota', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['number']},
    {label: 'Client Terminate', field: 'clientTerminate', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['number']},
    {label: 'DAR', field: 'dataAcceptanceRate', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['percent']},
    {label: 'Auto QC', field: 'autoQC', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['number']},
    {label: 'Auto QC Rate', field: 'autoQCRate', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['percent']},
    {label: 'Manual QC', field: 'clientQC', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['number']},
    {label: 'Manual QC Rate', field: 'actualQCRate', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['percent']},
    {label: 'Expected IR', field: 'ir', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['number']},
    {label: 'Actual IR', field: 'actualIR', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['number']},
    {label: 'Actual Dropout Rate', field: 'actualDropoutRate', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['percent']},
    {label: 'Survey Health', field: 'health', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['alert']},
    {label: 'Expected Value', field: 'estimatedValue', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['currency']},
    {label: 'Current Value', field: 'clientTotal', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['currency']},
    {label: 'Gross Margin', field: 'margin', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['percent']},
    {label: 'ProjectRevenue', field: 'projectRevenue', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['currency']},
    {label: 'Actions', field: 'actions', sortable: false, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['actions-dropdown']},
    {label: 'Project Type', field: 'projectTypeType', sortable: true, filterable: true, textFilterable: false, editable: false, autoSave: false, availableTypes: ['text']},
    {label: 'Vendor Project Number', field: 'vendorProjectNumber', sortable: true, filterable: true, textFilterable: true, editable: false, autoSave: false, availableTypes: ['text']},
    {label: 'Vendor Status', field: 'vendorStatus', sortable: true, filterable: true, textFilterable: false, editable: false, autoSave: false, availableTypes: ['status']},
    {label: 'Vendor Project Manager', field: 'vendorPms', sortable: true, filterable: true, textFilterable: true, editable: false, autoSave: false, availableTypes: ['users']},
    {label: 'Vendor Allocation', field: 'vendorAllocation', sortable: true, filterable: true, textFilterable: false, editable: false, autoSave: false, availableTypes: ['number']},
    {label: 'Vendor Completes', field: 'vendorCompletes', sortable: true, filterable: true, textFilterable: false, editable: false, autoSave: false, availableTypes: ['number']},
    {label: 'CID-Fraud', field: 'cleanID', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['number']},
    {label: 'CID-Fraud Rate', field: 'fraudPercent', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['percent']},
    {label: 'CID-Dupes', field: 'dupes', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['number']},
    {label: 'CID-Dupes Rate', field: 'dupPercent', sortable: true, filterable: false, textFilterable: false, editable: false, autoSave: false, availableTypes: ['percent']}
  ];

    constructor(
    public auth: AuthService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    public utils: UtilsService,
    private projectService: ProjectService,
    public roleService: RoleService) { }

  ngOnInit(): void {
    this.instanceId = this.auth.getInstance();
    if (!this.instanceId) {
      this.alertify.warning('Please select an Instance');
      return;
    }
    //order fieldTypes by label
    this.fieldTypes.sort((a, b) => a.label.localeCompare(b.label));

    forkJoin([this.projectService.GetProjectListViews(this.instanceId),
      this.projectService.GetProjectTypes(this.instanceId, false, true)]).subscribe((responseList) => {
  
      // ListView
      this.projectListViews = responseList[0];
      this.editListView = null;

      // ProjectTypes
      this.projectTypes = responseList[1];

      // Add ProjectType name to ListView
      this.projectListViews.forEach(item => {
        item.projectType = this.projectTypes.find(i => i.id === item.projectTypeId)?.name;
      });

      this.getInstanceRoles();
    }, error => {
      this.alertify.error('There was an error getting project details');
    });
  }

  getProjectTypes() {
    this.projectService.GetProjectTypes(this.instanceId).subscribe((data) => {
      this.projectTypes = data;
    }, error => {
      this.alertify.error('There was an error getting project types');
    });
  }

  getListViews() {
    this.projectService.GetProjectListViews(this.instanceId).subscribe((data) => {
      this.projectListViews = data;
      this.editListView = null;
    }, error => {
      this.alertify.error('There was an error getting project list views');
    });
  }

  handleAction(action) {
    if (action.type === 'edit') {
      this.mode = 'edit';
      this.cloneid = null;
      this.firstLoading = true;
      this.editListView = action.row;
      if(this.editListView.roles == null) this.editListView.roles = [];
      this.loadListView();
    }
    if (action.type === 'clone') {
      this.cloneid = 'clone';
      this.firstLoading = true;
      this.editListView = action.row;
      if(this.editListView.roles == null) this.editListView.roles = [];
      this.loadListView();
    }
    if (action.type === 'delete') {
      this.cloneid = null;
      this.deleteView = action.row;
      this.openModalRef = this.modalService.show(this.modalRefConfirm);
    }
  }

  loadListView() {
    if (this.editListView != null) {
      // this.statusFilter = this.editListView.statusFilter.join(',');
      // this.viewModes = this.editListView.viewModes.join(',');

      if(this.editListView.locationType == null)
        this.editListView.locationType = 'default';

      this.editListView.tableColumns.forEach(item => {
        item.selectable = {isDisabled: false, value: item.selectable};
        item.sortable = {isDisabled: false, value: item.sortable};
        item.filterable = {isDisabled: false, value: item.filterable};
        item.textFilterable = {isDisabled: false, value: item.textFilterable};
        item.editable = {isDisabled: false, value: item.editable};
        item.autoSave = {isDisabled: false, value: item.autoSave};
        this.handleFieldChange(item.field, item);
      });
    }
  }

  confirmDelete() {
    this.projectService.DeleteProjectListView(this.deleteView).subscribe(data => {
      if (data == true) {
        this.alertify.success(this.deleteView.name + ' deleted successfully');
        this.getListViews();
      } else {
        this.alertify.error('Unable to delete project list view');
      }
    }, error => {
       this.alertify.error('Unable to delete project list view');
    }, () => {
      this.openModalRef.hide();
    });
  }

  showCssValidField(field) {
    if (this.submitted) {
      return (this.submitted && this.utils.showCssValidField(this.listViewForm.form, field));
    }
  }

  saveListView() {
    this.submitted = true;

    if (this.cloneid) {
      this.editListView.actionType="clone";
    }

    if (!this.listViewForm.valid) {
      this.alertify.error('Please fill in all required fields');
      return;
    }
    if (this.editListView.tableColumns.length === 0) {
      this.alertify.error('Please add at least one column');
      return;
    }
    if (this.editListView.viewModes.length === 0) {
      this.alertify.error('Please add at least one View Mode');
      return;
    }
    if (this.editListView.showArchivedProjectsOnlyButton) {
      this.editListView.statusFilter = ['Invoiced']
    }


    if(this.editListView.locationType != 'default')
    {
      //clear out any settings that are not visible for client and vendor lists
      this.editListView.statusFilter = [];
      this.editListView.showArchivedProjectsOnlyButton = false;
      this.editListView.viewModes = ['list'];
      this.editListView.roles = [];
      this.editListView.showFavouriteButton = false;
      this.editListView.showMyProjects = false;
      this.editListView.showCopyUrls = false;
      this.editListView.showDownload = false;
      this.editListView.showGrouping = false;
      this.editListView.showFilterText = false;
    }

    this.editListView.tableColumns.forEach(col => {
      col.selectable = col.selectable.value;
      col.sortable = col.sortable.value;
      col.filterable = col.filterable.value;
      col.textFilterable = col.textFilterable.value;
      col.editable = col.editable.value;
      col.autoSave = col.autoSave.value;
    });

    this.projectService.SaveProjectListView(this.instanceId, this.editListView).subscribe((data) => {
        this.getListViews();
    }, error => {
      this.alertify.error('There was an error saving this project list view.');
    }, () => {
      this.alertify.success('Project list view created successfully.');
    });
  }

  addDropdownRole() {
    this.editListView.tableColumns.push(this.createBlankColumn());
  }

  addColumnClick() {
    this.editListView.tableColumns.push(this.createBlankColumn());
  }
  removeColumnClick(index) {
    this.editListView.tableColumns.splice(index, 1);
  }

  changeOrder(direction, item, index) {
    if (direction === 'down') {
      this.editListView.tableColumns.splice(index, 1);
      this.editListView.tableColumns.splice((index+1), 0, item);
    } else {
      this.editListView.tableColumns.splice(index, 1);
      this.editListView.tableColumns.splice((index-1), 0, item);
    }
    this.editListView.tableColumns.forEach((elem, idx) => {
      elem.order = idx;
    });
  }

  onIconPickerSelect(icon: string): void {
    this.editListView.icon = icon;
  }

  createBlankColumn() {
    return {
      id: uuidv4(),
      label: null,
      field: null,
      type: null,
      selectable: {isDisabled: false, value: false},
      sortable: {isDisabled: false, value: false},
      filterable: {isDisabled: false, value: false},
      textFilterable: {isDisabled: false, value: false},
      editable: {isDisabled: false, value: false},
      autoSave: {isDisabled: false, value: false},
      screenSize: null,
      order: this.editListView.tableColumns.length
    };
  }

  addListView() {
    this.editListView = {
      name: null,
      projectTypeId: null,
      description: null,
      icon: null,
      cardStyle: 'survey',
      locationType:'default',
      roles: [],
      statusFilter: [],
      viewModes: ['list', 'card', 'calendar'],
      showArchivedProjectsOnlyButton: false,
      showFavouriteButton: false,
      showMyProjects: false,
      showFilterText: false,
      showCopyUrls: false,
      showDownload: false,
      showGrouping: false,
      filterTextLabel: null,
      tableColumns: []
    };

    this.mode = 'new';
  }

  getStatusList = (filter) => {
    return of(this.statusFilter);
  }

  updateStatus(newValue) {
    this.editListView.statusFilter = newValue;
  }

  getViewModes = (filter) => {
    return of(this.viewModes);
  }

  updateViewMode(newValue) {
    this.editListView.viewModes = newValue;
  }

  handleFieldChange(fieldType, item) {

    if (item === null || fieldType === null) return;

    this.types[item.id] = [];
    let field = this.fieldTypes.filter(x => x.field == fieldType);

    const type = item.type;

    if (field !== null && field.length == 1) {
      field.map(x => x.availableTypes.forEach(e => {
        this.types[item.id].push(e)
      }));

      if (this.types[item.id]?.length == 1) {
        item.type = this.types[item.id][0];
      } else {
        if (this.mode == 'edit' && this.firstLoading) {
          item.type = type;
        } else {
          item.type = null;
        }
      };
      item.selectable.isDisabled = field[0].editable === true;
      item.sortable.isDisabled = field[0].sortable !== true;
      item.filterable.isDisabled = field[0].filterable !== true;
      item.textFilterable.isDisabled = field[0].textFilterable !== true;
      item.editable.isDisabled = field[0].editable !== true;
      item.autoSave.isDisabled = field[0].autoSave !== true;
    }
  }
  getInstanceRoles() {
    this.roleService.GetInstanceRoles(this.instanceId, true).subscribe((data) => {
      this.roles = data;
    },
    error => {
      this.alertify.error('There was an error getting instance roles');
    });
  }
  getRoleList = (filter) => {
    return of(this.roles);
  }

  updateRole(newValue) {
    this.editListView.roles = newValue;
  }
}
