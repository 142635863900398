import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabOrderDirective } from './taborder.directive';
import { AutoFocusDirective } from './auto-focus.directive';

@NgModule({
  imports: [
    CommonModule,
    
  ],
  declarations: [TabOrderDirective, AutoFocusDirective],
  exports: [TabOrderDirective, AutoFocusDirective]
})
export class DirectivesModule { }
