import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'or-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss']
})
export class SocialLinksComponent implements OnInit {
  @Input() social: any;
  constructor() { }

  ngOnInit() {
  }
  callSkype()
  {
    const url = 'skype:'+ this.social.skype+'?chat';
    window.open(url);
  }

}
