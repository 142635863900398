
<div class="d-flex mb-3 mt-1 border-bottom">
  <div class="text-truncate or-secondary h4 m-2"> Recruit ID </div>
  <div class="vl ml-2 mr-auto"></div>
</div>

<div class="card">
  <div class="card-header or-primary-bg text-white">
    <p class="h4 inline float-left"><em class="fa fa-user fa-sm"></em> Reports</p>
  </div>
  <div class="card-body pt-0 mt-0">
    @if (loading) {
      <div class="col-6 d-flex align-items-center justify-content-end mt-4">
        <div class="loading loading-lg" ></div>
      </div>
    }
    <div>
      <iframe #myIframe frameborder=0 width="100%" height="1000" src=""></iframe>
    </div>
  </div>
</div>
