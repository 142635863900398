export interface Dropdown {
   name: string;
   value: string;
}

export interface Dropdown {
   name: string;
   client: string;
   closeDate: string;
   code: string;
   value: string;
}

export interface JobsDropdown {
   name: string;
   value: string;
   bucketId: string;
}
