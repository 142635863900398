import { Injectable } from '@angular/core';
import { __values } from 'tslib';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/core/src/environments/environment';
import { AlertSettingsModel } from 'projects/admin/src/app/_models/alertSettingsModel';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  //TEMP DASHBAORD
  setDashboardSetting(dashboards) {
    localStorage.setItem('dashboardSettings', JSON.stringify(dashboards));
  }
  getDashboardSettings() {
    const dashboards = localStorage.getItem('dashboardSettings');
    return (dashboards != null) ? JSON.parse(dashboards) : [];
  }

  setProjectsViewSetting(settings, type) {
    localStorage.setItem('projectsViewSetting' + type, JSON.stringify(settings));
  }
  setUsersViewSetting(settings, type) { 
    localStorage.setItem('usersViewSetting' + type, JSON.stringify(settings));
  }
  getUsersViewSettings(role) { 
    const settings = localStorage.getItem('usersViewSetting' + role);  
    return (settings != null) ? JSON.parse(settings) : { sortBy: null, sortDir: 'ASC', appliedFilters: [] };
  }
  getProjectsViewSettings(type, views) {
    let settings = { view: 0, filterText: '', views: {} };
    const temp = localStorage.getItem('projectsViewSetting' + type);
    if (temp != null) {
      settings = {
        ...settings,
        ...JSON.parse(temp)
      };
    }
    views.forEach(v => {
      if (settings.views[v.id] == null) {
        settings.views[v.id] = { myProjects: false, mode: '', status: '', groupBy: '' };
      }
    });
    return settings;
  }
 
  setProjectsListSetting(layout, settings) {   
    localStorage.setItem('projectsListSettings' + layout, JSON.stringify(settings));
  }
  getProjectsListSettings(layout) {
    const settings = localStorage.getItem('projectsListSettings' + layout); 
    return (settings != null) ? JSON.parse(settings) : { sortBy: null, sortDir: 'ASC', appliedFilters: [] };
  }
  setProjectPageSettings(projectType, status, settings) {
    localStorage.setItem('projectPageSettings_' + projectType + '_' + status, JSON.stringify(settings));
  }
  getProjectPageSettings(projectType, status) {
    const settings = localStorage.getItem('projectPageSettings_' + projectType + '_' + status);
    return (settings != null) ? JSON.parse(settings) : { cardSettings: {} };
  }

  getSurveyLinkUrls() {
    return this.http.get<any>(environment.apiEndpoint + '/instance/surveyurls', { headers: { cache: '1' } });
  }
  getSurveySettings(instanceId: string) {
    return this.http.get<AlertSettingsModel>(environment.apiEndpoint + '/instance/globalsurveysettings?instanceId=' + instanceId, { headers: { cache: '1' } });
  }
  saveSurveySettings(settingsModel) {
    return this.http.put<AlertSettingsModel>(environment.apiEndpoint + '/instance/globalsurveysettings', settingsModel);
  }
}
