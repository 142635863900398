import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {AuthService, AlertifyService} from 'core';

@Component({
  selector: 'or-rule-manager',
  templateUrl: './rule-manager.component.html',
  styleUrls: ['./rule-manager.component.scss']
})
export class RuleManagerComponent implements OnInit {
  @Input() public set project(data) { this._project = data; }
  @Input() public set tasks(data) { this.listTasks = data; }
  @Input() public set rule(data) {
    this._rule = data;
    this.loadData();
  }

  @Output() conditionChanged = new EventEmitter();
  @ViewChild('ddScope', {static: false}) ddScope;

  _project;
  _rule;
  integerPattern = { 9: { pattern: new RegExp('^[0-9]*$'), optional: true } };

  scopeTypes = [{label: 'Project', type: 'Project', value: ''}, {label: 'Vendor', type: 'Vendor', value: '', vendors: [{id: 'A', label: 'CINT'}, {id: 'B', label: 'Prodege'}]}]
  metricTypes = ['Completes', 'Starts', 'Survey Oqs', 'NAV Oqs', 'IR', 'DOR', 'CID Blocks', 'QCs', 'Dupes'];
  alertMethods = ['Email', 'Slack','SMS'];
  operators = [{label: 'Below', value: '<'}, {label: 'Above', value: '>'}];
  alertRecipients:any = [{label:'Me'}, {label:'Project Manager'}, {label:'Secondary PM'}];
  taskAlertRecipients = ['Me', 'PM', 'Assigned To'];

  selectedScope = 'Project';
  selectedTaskType = 'Select Type';
  selectedMetric = 'Metric';
  selectedMetricNumber: any = {label: '0', value: 0, suffix: ''};
  selectedOperator = 'Below';
  selectedAlertMethod = 'Email';
  selectedAlertRecipient = 'Select individual(s)';
  selectedTaskAlertRecipient = 'Someone';
  selectedTask = 'Select Task';

  selectedScheduledDate: any = {label: 'Date', value: new Date()};
  selectedScheduledTime: any = {label: 'Time', value: new Date()};

  totalChecked = 0;

  addingEmail = false;
  hideEmailBox = false;
  dropdownBottom = 0;
  newRecipient = '';

  listTasks: any[] = [];

  emailValidator = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private auth: AuthService,
    private alertify: AlertifyService) { }

  ngOnInit(): void { }

  loadData() {

    this.calculateDropdownBottom();

    // Get all Vendors from Project
    this.scopeTypes.find(x => x.type == 'Vendor').vendors = this._project?.projectSegments[0].projectSurveyPartners.map(vendor => ({ id: vendor.partnerId, label: vendor.partnerName }));

    if (this._rule.id || this._rule.copy) {
      this.selectedScope = this._rule.scopeType;
      if (this.selectedScope === 'Vendor') {
        let label = this.scopeTypes.find(x => x.type == 'Vendor').vendors.find(x => x.id.toLowerCase() === this._rule.scopeId)?.label;
        this.selectedScope = label;
      }

      this.selectedMetric = this._rule.rules[0].metricType;
      this.selectedMetricNumber = {label: this._rule.rules[0].value, value: this._rule.rules[0].value, suffix: ''};

      switch (this.selectedMetric) {
        case 'IR':
        case 'DOR':
        case 'CID Blocks':
        case 'QCs':
        case 'Dupes':
          this.selectedMetricNumber.suffix = '%';
          break;
        default:
          break;
      }

      this.selectedAlertMethod = this._rule.ruleActions[0].alertMethod;

      // Merge arrays and remove duplicates
      const recipients = this._rule.ruleActions[0].alertRecipientType?.split(',').map(item => ({ label: item, type: item.includes('@') ? 'email' : null, checked: true }));
      const mergedRecipients = [...recipients, ...this.alertRecipients].filter((item, index, self) => self.findIndex(i => i.label === item.label) === index);
      this.alertRecipients = mergedRecipients;

      this.applyAlertRecipient(false);

      if (this._rule.ruleType == 'scheduled') {
        this.selectedOperator = this.operators.find(x => x.value ===  this._rule.rules[0].operator).label;

        let scheduledDate = new Date(this._rule.ruleActions[0].scheduledTime);
        scheduledDate.setHours(scheduledDate.getHours() - scheduledDate.getTimezoneOffset() / 60);
        let scheduledDateLabel = scheduledDate.toLocaleDateString(['en-US'], {year: 'numeric', month: 'numeric', day: 'numeric'});
        this.selectedScheduledDate = {label: scheduledDateLabel, value: scheduledDate};

        let scheduledTimeLabel = scheduledDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true});
        this.selectedScheduledTime = {label: scheduledTimeLabel, value: scheduledDate};
      }

      if (this._rule.ruleType == 'task') {
        this.selectedTaskType = this._rule.scopeType;
        let task = 'Select Task';
        let item = this.listTasks.find(x => x.id === this._rule.scopeId);
        if (item === undefined || item === null) return;
        task = item.stage + ' | ' + item.name;
        this.selectedTask = task;
        this.selectedTaskAlertRecipient = this._rule.ruleActions[0].alertRecipientType;

        let scheduledDate = new Date(this._rule.ruleActions[0].scheduledTime);
        scheduledDate.setHours(scheduledDate.getHours() - scheduledDate.getTimezoneOffset() / 60);
        let scheduledDateLabel = scheduledDate.toLocaleDateString(['en-US'], {year: 'numeric', month: 'numeric', day: 'numeric'});
        this.selectedScheduledDate = {label: scheduledDateLabel, value: scheduledDate};

        let scheduledTimeLabel = scheduledDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true});
        this.selectedScheduledTime = {label: scheduledTimeLabel, value: scheduledDate};
      }
    }
  }

  applyScope(type, newValue, vendor = null) {
    this.selectedScope = (vendor === null ? newValue.label : vendor.label);

    if (type == 'Project') {
      newValue.value = this._project.id;

      const index = this.alertRecipients.findIndex(x => x.label === 'Vendor'); // Remove Vendor from alert recipients
      if (index !== -1) this.alertRecipients.splice(index, 1);

      if (this.selectedAlertRecipient === 'Vendor') this.selectedAlertRecipient = 'Someone';
    } else {
      newValue.value = vendor.id;
      this.alertRecipients = [{label:'Me'}, {label:'Project Manager'}, {label:'Secondary PM'}, {label:'Vendor'}];
    }

    this.ddScope.hide();
    this.handleSelectedRecipients();
    this.conditionChanged.emit({condition: 'scope', newValue});
  }
  applyTaskType(type) {
    this.selectedTaskType = type;
    this.selectedTask = 'Select Task';
    this.taskAlertRecipients = ['Me', 'PM', 'Assigned To'];
    this.ddScope.hide();
  }
  applySelectedTask(task) {
    this.selectedTask = task.stage === undefined
      ? task.name
      : task.stage + ' | ' + task.name;

    let newValue = {value: task.id, label: this.selectedTaskType}
    this.ddScope.hide();
    this.conditionChanged.emit({condition: 'scope', newValue});
  }

  applyMetric(newValue) {
    this.selectedMetric = newValue;

    switch (this.selectedMetric) {
      case 'IR':
      case 'DOR':
      case 'CID Blocks':
      case 'QCs':
      case 'Dupes':
        this.selectedMetricNumber.suffix = '%';
        break;
      default:
        this.selectedMetricNumber.suffix = '';
        break;
    }

    this.conditionChanged.emit({condition: 'metric', newValue});
  }

  applyMetricNumber() {
    let newValue = this.selectedMetricNumber;
    newValue.value = newValue.label;
    this.selectedMetricNumber.editMode = null;
    this.conditionChanged.emit({condition: 'metric-number', newValue});
  }

  applyScheduledDate() {
    this.selectedScheduledDate.label = new Date(this.selectedScheduledDate.value).toLocaleDateString(['en-US'], {year: 'numeric', month: 'numeric', day: 'numeric'});
    let dateString = this.selectedScheduledDate.label;

    this.selectedScheduledTime.label = new Date(this.selectedScheduledTime.value).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true});
    let timeString = this.selectedScheduledTime.label;

    let newValue = { value: new Date(dateString + ' ' + timeString) };
    this.conditionChanged.emit({condition: 'scheduled-date', newValue});
  }

  applyOperator(newValue) {
    this.selectedOperator = newValue.label;
    this.conditionChanged.emit({condition: 'operator', newValue});
  }

  applyAlertMethod(newValue) {
    this.selectedAlertMethod = newValue;

    let phone = this.auth.getUser().phone;
    let slackUsername = this.auth.getUser().social.slackUsername;

    if (newValue == 'SMS' || newValue == 'Slack') {

      // disable add email box
      this.hideEmailBox = true;

      if (newValue == 'SMS') {
        if (!phone) {
          this.selectedAlertMethod = 'Email';
          this.conditionChanged.emit({condition: 'alertMethod', newValue: "Email"});
          this.alertify.error("There is no Mobile in your profile");
        }
      }
      else {
        if (!slackUsername) {
          this.selectedAlertMethod = 'Email';
          this.conditionChanged.emit({condition: 'alertMethod', newValue: "Email"});
          this.alertify.error("There is no Slack Username in your profile");
        }
        else {
          const index = this.alertRecipients.findIndex(x => x.label === 'Vendor');  //Remove Vendor from alert recipients
          if (index !== -1) this.alertRecipients.splice(index, 1);

          if (this.selectedAlertRecipient === 'Vendor')
            this.selectedAlertRecipient = 'Someone';
        }
      }

    } else {
      this.hideEmailBox = false;

      if (this.selectedScope === 'Project')
        this.alertRecipients = [{label:'Me'}, {label:'Project Manager'}, {label:'Secondary PM'}];
      else
        this.alertRecipients = [{label:'Me'}, {label:'Project Manager'}, {label:'Secondary PM'}, {label:'Vendor'}];
    }

    this.handleSelectedRecipients();
    this.conditionChanged.emit({condition: 'alertMethod', newValue});
  }

  applyAlertRecipient(emit = true) {

    const vendorSelected = this.alertRecipients.some(x => x?.checked && x?.label === 'Vendor');
    const anyPMSelected = this.alertRecipients.some(x => x?.checked && (x?.label === 'Project Manager' || x?.label === 'Secondary PM'));
    const anyEmailSelected = this.alertRecipients.some(x => x?.checked && x?.label.includes('@'));

    if (vendorSelected) {
      this.alertMethods.splice(1); // Remove Slack and SMS options
      this.selectedAlertMethod = 'Email';
      this.conditionChanged.emit({condition: 'alertMethod', newValue: "Email"});
    }
    else if (!vendorSelected && anyPMSelected) {
      //this.alertMethods.splice(1); // Remove Slack and SMS options
      this.conditionChanged.emit({condition: 'alertMethod', newValue: "Email"});
      this.alertMethods = ['Email', 'Slack'];
      if (!this.alertMethods.includes(this.selectedAlertMethod))
        this.selectedAlertMethod = 'Email';
    }
    else {
      //this.selectedAlertMethod = 'Email';
      this.alertMethods = ['Email', 'Slack','SMS'];
    }

    if (anyEmailSelected) {
      this.selectedAlertMethod = 'Email';
      this.alertMethods = ['Email'];
    }

    this.handleSelectedRecipients(emit);
  }

  handleSelectedRecipients(emit = true) {
    this.totalChecked = this.alertRecipients.filter(x => x?.checked).length;
    const newValue = this.alertRecipients.filter(x => x?.checked).map(x => x?.label).join(',');
    if (emit) this.conditionChanged.emit({condition: 'alertRecipient', newValue});
  }

  applyTaskAlertRecipient(newValue) {
    this.selectedTaskAlertRecipient = newValue;

    if (newValue == 'Assigned To') {
      this.selectedAlertMethod = 'Email';
      this.conditionChanged.emit({condition: 'alertMethod', newValue: "Email"});
      this.alertMethods = ['Email', 'Slack'];
    } else if (newValue == 'PM') {
      this.selectedAlertMethod = 'Email';
      this.conditionChanged.emit({condition: 'alertMethod', newValue: "Email"});
      this.alertMethods = ['Email', 'Slack'];
    } else {
      this.alertMethods = ['Email', 'Slack','SMS'];
    }

    this.conditionChanged.emit({condition: 'alertRecipient', newValue});
  }

  onKeydown(event, type) {
    if (event.key === "Enter") {
      if (type === 'number') {
        this.applyMetricNumber();
      }
      if (type === 'time' || type === 'date') {
        this.applyScheduledDate();
      }
      if (type === 'email') {
        this.handleEnterNewRecipient();
      }
    }
  }

  handleEnterNewRecipient() {
    this.emailValidator.setValue(this.newRecipient.trim());
    if (!this.emailValidator.valid) {
      this.alertify.error("Please type in a valid e-mail.");
      this.newRecipient = '';
      return;
    }

    if (this.alertRecipients.some(item => item?.label.trim() === this.newRecipient.trim())) {
      this.alertify.error("E-mail already registered.");
      this.newRecipient = '';
      return;
    }

    const newEmail = {
      label: this.newRecipient.trim(),
      type: 'email',
      checked: true
    }

    this.alertMethods = ['Email'];
    this.selectedAlertMethod = 'Email';

    this.alertRecipients.push(newEmail);
    this.handleSelectedRecipients();
    this.newRecipient = '';
  }

  calculateDropdownBottom() {
    this.dropdownBottom = ((this.alertRecipients.length - 3) * 27) - 1;
  }

  addRecipientEmail() {
    this.addingEmail = true;
  }

  deleteRecipientEmail() {
    this.addingEmail = false;
    this.newRecipient = '';

  }

}
